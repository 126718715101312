import { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { TypePaymentLink, TypePaymentLinkCollection } from "../../features/paymentlinks/paymentLinksSlice";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { selectAccessToken } from "../../features/auth/authSlice";
import useApiRequest from "../../helpers/api_request";
import { BASE_URL, COLLECTION_STATUS } from "../../helpers/constants";
import { AxiosError } from "axios";
import { toast } from "react-toastify";
import { showToastMessage } from "../../features/generalSlice";
import AppSpinner from "../elements/AppSpinner";
import { formatDate } from "../../helpers/commons";

const LinkCollectionsPage = () => {
    const [paymentLink, setPaymentLink] = useState<TypePaymentLink | null>(null)
    const [linkCollections, setLinkCollections] = useState<TypePaymentLinkCollection[]>([])

    const [isLoadingLink, setIsLoadingLink] = useState(false);
    const [isLoadingCollections, setIsLoadingCollection] = useState(false);

    const accessToken = useAppSelector(selectAccessToken)
    const { ref } = useParams();
    const apiRequest = useApiRequest();
    const dispatch = useAppDispatch();

    useEffect(() => {
        getPaymentLink()
    }, [])

    useEffect(() => {
        const interval = setInterval(() => {
            getPaymentLinkCollections();
        }, 120000); // 30 seconds in milliseconds
        return () => clearInterval(interval);
    }, []);




    const getPaymentLink = async () => {
        setIsLoadingLink(true)
        await apiRequest({
            method: 'get',
            url: BASE_URL + "/api/payment-link/details/" + ref,
            headers: {
                Authorization: 'Bearer ' + accessToken,
            }
        }).then((response) => {
            setPaymentLink(response.data.details)
            getPaymentLinkCollections()
            setIsLoadingLink(false)
        }).catch((error) => {
            const errorResponse = error as AxiosError;
            if (errorResponse.response) {
                const data = errorResponse.response.data as { message: string, status: string };
                dispatch(showToastMessage({
                    message: data.message,
                    type: "error",
                    position: toast.POSITION.TOP_RIGHT
                }));

            } else {
                dispatch(showToastMessage({
                    message: "Something went wrong",
                    type: "error",
                    position: toast.POSITION.TOP_RIGHT
                }));
            }
            setIsLoadingLink(false)
        });
    }


    const getPaymentLinkCollections = async () => {
        setIsLoadingCollection(true)
        await apiRequest({
            method: 'get',
            url: BASE_URL + "/api/payment-link/collections/" + ref,
            headers: {
                Authorization: 'Bearer ' + accessToken,
            }
        }).then((response) => {
            setLinkCollections(response.data)
            setIsLoadingCollection(false)
        }).catch((error) => {
            const errorResponse = error as AxiosError;
            if (errorResponse.response) {
                const data = errorResponse.response.data as { message: string, status: string };
                dispatch(showToastMessage({
                    message: data.message,
                    type: "error",
                    position: toast.POSITION.TOP_RIGHT
                }));

            } else {
                dispatch(showToastMessage({
                    message: "Something went wrong",
                    type: "error",
                    position: toast.POSITION.TOP_RIGHT
                }));
            }
            setIsLoadingCollection(false)
        });
    }

    const sumCollections = (data: TypePaymentLinkCollection[]): string => {
        let totalAmount = 0;
        for (const item of data) {
            if (item.collection) {
                if (item.collection.status === "successful") {
                    totalAmount += item.collection.amount;
                }
            }
        }
        return totalAmount.toLocaleString();
    }

    return (
        <div className="h-scree sm:min-w-desktop sm:max-w-desktop md:min-w-desktop md:max-w-desktop lg:min-w-desktop lg:max-w-full xl:min-w-full xl:max-w-full  overflow-auto">
            <div className="px-edge-space  py-4 border-b grid grid-cols-2 ">
                <div className="flex flex-col justify-center">
                    <div>
                        {
                            isLoadingLink === false && paymentLink != null ?
                                <div className="flex">
                                    <Link to="/payment-links">
                                        <svg xmlns="http://www.w3.org/2000/svg" className="  opacity-80 cursor-pointer" width="25" height="25" viewBox="0 0 15 15"><path fill="currentColor" fillRule="evenodd" d="M11.782 4.032a.575.575 0 1 0-.813-.814L7.5 6.687L4.032 3.218a.575.575 0 0 0-.814.814L6.687 7.5l-3.469 3.468a.575.575 0 0 0 .814.814L7.5 8.313l3.469 3.469a.575.575 0 0 0 .813-.814L8.313 7.5l3.469-3.468Z" clipRule="evenodd" /></svg>
                                    </Link>
                                    <span className="pl-4 text-lg">{paymentLink?.title}</span>
                                </div> :
                                <div>
                                    <AppSpinner TopClass="w-full flex" />
                                </div>
                        }
                    </div>

                </div>
                <div className="flex-grow text-end">
                    <p className="text-2xl font-bold">UGX {sumCollections(linkCollections)}</p>
                </div>
                <div>

                </div>
            </div>

            <div className="px-edge-space  mt-4">

                <table className="w-full text-sm text-left text-gray-500 dark:text-gray-400 mt-2">
                    <thead>
                        <tr>
                            <th scope="col" >
                                Date
                            </th>
                            <th scope="col" >
                                Phone
                            </th>
                            <th scope="col" >
                                Names
                            </th>
                            <th scope="col" >
                                Amount
                            </th>
                            <th scope="col" >
                                Channel
                            </th>
                            <th scope="col" >
                                Status
                            </th>
                            {
                                paymentLink?.extra_fields?.map((item, key) =>
                                    <th key={key} scope="col" >
                                        {item.label}
                                    </th>
                                )
                            }
                        </tr>
                    </thead>
                    <tbody>
                        {
                            linkCollections.map((item, key) =>

                                item.collection &&
                                <tr key={key}>
                                    <td>
                                        {formatDate(item.created_at, "MMM DD, YYYY H:mm")}
                                    </td>
                                    <td> {item.collection.msisdn}</td>
                                    <td>
                                        {(item.collection.customer) ? item.collection.customer.names ? item.collection.customer.names : "n/a" : "n/a"}

                                    </td>
                                    <td>{item.currency} {item.amount.toLocaleString()}</td>
                                    <td>{item.collection.collection_channel}  {item.collection.msisdn === null && "Card"}</td>
                                    <td>
                                        {item.collection.status === COLLECTION_STATUS.SUCCESSFUL &&
                                            <span className="badge-success">{item.collection.status}</span>}
                                        {item.collection.status === COLLECTION_STATUS.FAILED &&
                                            <span className="badge-failed">{item.collection.status}</span>}
                                        {item.collection.status === COLLECTION_STATUS.PENDING &&
                                            <span className="badge-pending">{item.collection.status}</span>}
                                    </td>
                                    {
                                        (Array.isArray(item.extra_data)) &&
                                        item.extra_data.map((item, key) =>
                                            <td key={key} >
                                                {item.value}
                                            </td>
                                        )
                                    }
                                </tr>
                            )
                        }
                    </tbody>
                </table>
            </div>
        </div>
    )
}
export default LinkCollectionsPage;

