import { EntityState, createAsyncThunk, createEntityAdapter, createSlice } from "@reduxjs/toolkit";
import { APP_STATUS, BASE_URL, TypeAppStatus } from "../../helpers/constants";
import { selectAccessToken } from "../auth/authSlice";
import { RootState } from "../../app/store";
import { handleSessionExpiration } from "../../helpers/commons";
import axios, { AxiosError } from "axios";

export type TypeCustomer ={
    ntuma_account_id:number
    email?:string,
    names?:string
    phone?:string,
    created_at:string
}

interface CustomerState extends EntityState<TypeCustomer>{
    loadingCustomersStatus:TypeAppStatus,
    lastCursor:string|null,
    nextCursor:string|null,
    totalItems:number|null
    totalPages:number,
    cursorReset:boolean
}

const customersAdapter = createEntityAdapter<TypeCustomer>()
const initialState:CustomerState = customersAdapter.getInitialState({
    loadingCustomersStatus:"idle",
    savingLinkStatus:"idle",
    lastCursor:null,
    nextCursor:null,
    totalItems:null,
    totalPages:0,
    cursorReset:true
})

export const fetchCustomers = createAsyncThunk("auth/fetchCustomers", async(_,{getState,rejectWithValue,dispatch})=>{
    try {
     const auth_token = selectAccessToken(getState() as RootState);
     const nextCursor = selectNextCursor(getState() as RootState)
     let dataUrl = "";
     if (nextCursor ==null) {
         dataUrl = "/api/customers"
     }else{
         dataUrl="/api/customers?cursor="+nextCursor
     }
     const response = await axios.get(BASE_URL+dataUrl,{
         headers: {
             Authorization: 'Bearer '+auth_token,
         }
     })
     return response.data.data
    } catch (error) {
         const errorResponse = error as AxiosError;
         handleSessionExpiration(errorResponse,dispatch);
         if (errorResponse.response) {
             const data = errorResponse.response.data as {message:string, status:string};
             return rejectWithValue(data.message);
         }else{
             return rejectWithValue("Failed. Something went wrong");
         }
    }
 })



const customersSlice = createSlice({
    initialState,
    name:"customers",
    reducers:{
        resetCursorCustomers(state){
            state.nextCursor = null
            state.lastCursor =null
            state.cursorReset = true
        },
    },
    extraReducers:(builder)=>{
        builder
        .addCase(fetchCustomers.pending,(state,action)=>{
            state.loadingCustomersStatus = APP_STATUS.PENDING
        })
        .addCase(fetchCustomers.fulfilled,(state,action)=>{
            state.loadingCustomersStatus = APP_STATUS.SUCCESS
            if (state.cursorReset) {
                customersAdapter.removeAll(state)
            }
            customersAdapter.addMany(state,action.payload.items)
            state.lastCursor = action.payload.lastCursor
            state.nextCursor = action.payload.nextCursor
            state.totalItems = action.payload.totalItems
            state.totalPages = action.payload.totalPages
            state.cursorReset = false
        })
        .addCase(fetchCustomers.rejected,(state,action)=>{
            state.loadingCustomersStatus = APP_STATUS.ERROR
        })
    }
})

export const {
    resetCursorCustomers
} = customersSlice.actions

export const {
    selectAll: selectCustomers,
    selectById: selectCustomerById,
  } = customersAdapter.getSelectors((state:RootState) => state.customers)

export default customersSlice.reducer
export const selectNextCursor = (state: RootState) => state.customers.nextCursor
