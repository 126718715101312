import NoData from "../../assets/img/nodata_green.svg"
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { useEffect, useState } from "react";
import { formatDate, notEmpty } from "../../helpers/commons";
import { TypeUser, getUserProfile, selectAccessToken, selectNtumaAccount } from "../../features/auth/authSlice";
import AppButton from "../elements/AppButton";
import useApiRequest from "../../helpers/api_request";
import { BASE_URL } from "../../helpers/constants";
import { showToastMessage } from "../../features/generalSlice";
import { toast } from "react-toastify";
import { AxiosError } from "axios";

const ApiKeysPage = ()=>{
    const dispatch = useAppDispatch()
    const api_keys = useAppSelector(state=>state.auth.ApiKey);
    const [generatingKeys, setGeneratingKeys] = useState(false);
    const apiRequest = useApiRequest();
    const accessToken = useAppSelector(selectAccessToken);

    const copyKey =(value:string|undefined)=>{
        if (value !==undefined) {
            dispatch(showToastMessage({
                message:"Copied",
                type:"success",
                position: toast.POSITION.TOP_RIGHT
              }));
              navigator.clipboard.writeText(value);
        }
      }

    const generateKeys =async ()=>{
        setGeneratingKeys(true)
        await apiRequest({
            method: 'post',
            url: BASE_URL+"/api/keys/generate",
            headers: {
                Authorization: `Bearer ${accessToken}`,
            },
        }).then((response)=>{
            setGeneratingKeys(false)
            dispatch(showToastMessage({
                message:"Keys Generated",
                type:"success",
                position: toast.POSITION.TOP_RIGHT
            }));
            dispatch(getUserProfile())
        }).catch((error)=>{
            const errorResponse = error as AxiosError;
            // console.log(errorResponse.response)
            if (errorResponse.response) {
                const data = errorResponse.response.data as {message:string, status:string};
                dispatch(showToastMessage({
                    message:data.message,
                    type:"error",
                    position: toast.POSITION.TOP_RIGHT
                }));
              
            }else{
                dispatch(showToastMessage({
                    message:"Something went wrong",
                    type:"error",
                    position: toast.POSITION.TOP_RIGHT
                }));
            }
            setGeneratingKeys(false)
        }); 
    }
    return (
        <div>
              <div className="card mb-12">
                
                <div className="card-body flex flex-col px-4 py-4 min-h-half-screen pb-16">
                    <div className="flex">
                        <nav className="flex justify-between " aria-label="Breadcrumb">
                        <ol className="inline-flex items-center mb-3 sm:mb-0">
                            <li>
                            <div className="flex items-center">
                                <h5 className=" card-title ">Api Keys</h5>
                            </div>
                            </li>
                        
                        </ol>
                        </nav>
                  
                    </div>
                  
                    <div className=" w-2/4 mt-2 ">
                        <div className="">
                            <label htmlFor="phone" className="block text-sm font-medium text-gray-700 ">Test Key</label>
                            <div className="relative">
                                <input value={api_keys?.test_key} disabled type="search" id="search" className="block form-input-2 "  />
                                <a onClick={()=>copyKey(api_keys?.test_key)} href="#!" type="submit" className=" mt-[9px]  text-prim-green top-0 absolute right-2.5 text-sm  ">Copy</a>
                            </div>
                        </div>
                    </div>
                    <div className=" w-2/4 mt-4 ">
                        <div className="">
                            <label htmlFor="phone" className="block text-sm font-medium text-gray-700 ">Live Key</label>
                            <div className="relative">
                                <input value={api_keys?.production_key} disabled type="search" id="search" className="block form-input-2 "  />
                                <a onClick={()=>copyKey(api_keys?.production_key)} href="#!" type="submit" className=" mt-[9px]  text-prim-green top-0 absolute right-2.5 text-sm  ">Copy</a>
                            </div>
                        </div>
                    </div>
                    <div className=" w-2/4 mt-4 ">
                        <div className="">
                            <label htmlFor="phone" className="block text-sm font-medium text-gray-700 ">Encryption Key</label>
                            <div className="relative">
                                <input value={api_keys?.encryption_key} disabled type="search" id="search" className="block form-input-2 "  />
                                <a onClick={()=>copyKey(api_keys?.encryption_key)} href="#!" type="submit" className=" mt-[9px]  text-prim-green top-0 absolute right-2.5 text-sm  ">Copy</a>
                            </div>
                        </div>
                    </div>
                    <div className="mt-5">
                        <AppButton callBackFun={()=>generateKeys()} showLoader={generatingKeys}  spinnerClass="inline w-3 h-3 mr-2 text-prim-yellow animate-spin fill-black" className="prim-btn-1  " text="Generate New Keys" />
                    </div>
                 
                </div>
            </div>
              
        </div>
    )
}
export default ApiKeysPage;

