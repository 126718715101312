import { useState } from "react";
import { BASE_URL } from "../../helpers/constants";
import { selectAccessToken } from "../../features/auth/authSlice";
import { useAppSelector } from "../../app/hooks";
import useApiRequest from "../../helpers/api_request";

const useFilesRequest = () => {
  const [isSaving, setIsSaving] = useState(false);
  const apiRequest = useApiRequest()
  const accessToken = useAppSelector(selectAccessToken);

  const saveFile = async (file: File) => {
    const formData = new FormData();
    formData.append('file', file);
    formData.append("folder", "uploads");
    setIsSaving(true);
    try {
      const response = await apiRequest({
        method: 'post',
        url: BASE_URL + "/api/files/upload",
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
        data: formData
      });
      setIsSaving(false);
      return response;
    } catch (error) {
      // errorToast(error);
      setIsSaving(false);
      throw error;
    }
  };

  return {
    isSaving,
    saveFile
  }
}

export default useFilesRequest;
