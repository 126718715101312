import React, { useState, ChangeEvent, useEffect, Dispatch, SetStateAction } from 'react';
import { FiPlus, FiTrash2 } from 'react-icons/fi';
import DropZone from '../elements/DropZone';


export type FileInputData= {
  fileName: string | undefined; file: File | null;
}
type FilesInputSectionProps = {
  setFilesData: Dispatch<SetStateAction<FileInputData[]>>,
  fileNames: string[]
}
export type FileInput = {
  fileName: string,
  file: File | null,
  specify?: string,
}

const FilesInputSection = ({ setFilesData, fileNames }: FilesInputSectionProps) => {
  const [fileInputs, setFileInputs] = useState<FileInput[]>([{ fileName: '', file: null, specify: '' }]);
  
  useEffect(() => {
    const newData: FileInputData[] = fileInputs.map((item, key) => {
      const { fileName, file, specify } = item;

      return {
        fileName: fileName === "Other" ? specify : fileName,
        file: file
      };
    });
    setFilesData(newData)
  }, [fileInputs]);

  const addFileInput = () => {
    setFileInputs([...fileInputs, { fileName: '', file: null, specify: '' }]);
  };

  const removeLastFileInput = () => {
    if (fileInputs.length > 1) {
      const updatedFileInputs = [...fileInputs];
      updatedFileInputs.pop(); // Remove the last item
      setFileInputs(updatedFileInputs);
    }
  };

  const handleFileChange = (
    file: File | undefined,
    index: number
  ) => {
    const updatedFileInputs = [...fileInputs];
    updatedFileInputs[index] = {
      ...updatedFileInputs[index],
      ["file"]: file ?? null,
    };
    setFileInputs(updatedFileInputs);
  };

  const handleNameChange = (
    e: ChangeEvent<HTMLInputElement | HTMLSelectElement>,
    index: number
  ) => {
    const { name, value } = e.target;
    const updatedFileInputs = [...fileInputs];
    if (name === 'file') {
      const files = (e as ChangeEvent<HTMLInputElement>).target.files;
      updatedFileInputs[index] = {
        ...updatedFileInputs[index],
        [name]: files?.[0] || null,
      };
    } else {
      updatedFileInputs[index] = {
        ...updatedFileInputs[index],
        [name]: value,
      };
    }
    setFileInputs(updatedFileInputs);
  };

  return (
    <div>
      {fileInputs.map((input, index) => (
        <div key={index} className="mt-4">
          <div className="">
            <label htmlFor={`fileName-${index}`} className="form-label">
              File Name
            </label>
            <div className="flex gap-1">
              <select
                id={`fileName-${index}`}
                name="fileName"
                className="form-input-2"
                value={input.fileName}
                onChange={(e) => handleNameChange(e, index)}
              >
                <option value="">Select File Name</option>
                {
                  fileNames.map((item, key) =>
                    <option key={key} value={item}>{item}</option>
                  )
                }
              </select>
              {input.fileName === 'Other' && (
                <input
                  placeholder="Specify"
                  name="specify"
                  type="text"
                  className="form-input-2"
                  value={input.specify}
                  onChange={(e) => handleNameChange(e, index)}
                />
              )}
            </div>
          </div>
          <div className="mt-1">
            <DropZone componentId={`file-${index}`}
              handleFileChange={(file) => handleFileChange(file, index)} >
              <>
                <p className=" text-sm text-center"><span className=" text-amber-500 text-sm">Upload a File</span> <span className=" text-opacity-80">or drag and drop</span></p>
                <p className=" text-sm text-gray-600">PNG, JPG, PDF up to 10MB</p>
              </>
            </DropZone>
          </div>
        </div>
      ))}
      <div className=' flex gap-2'>
        <button type="button" className="prim-btn-2 rounded-full px-2 py-2 text-lg mt-2" onClick={addFileInput}>
          <FiPlus />
        </button>
        {fileInputs.length > 1 && (
          <button type="button" className="btn-light rounded-full px-2 py-2 text-lg mt-2" onClick={removeLastFileInput}>
            <FiTrash2 />
          </button>
        )}
      </div>
    </div>
  );
};

export default FilesInputSection;
