import { Link, useNavigate } from "react-router-dom";
import NoData from "../../assets/img/nodata_green.svg"
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { TypeDonationLinkCollection, fetchDonationLinks, resetCursor, selectDonationLinks } from "../../features/donationlinks/donationLinksSlice";
import { useEffect, useState } from "react";
import { formatDate } from "../../helpers/commons";
import { selectNtumaAccount } from "../../features/auth/authSlice";
import AppSpinner from "../elements/AppSpinner";
import { APP_STATUS } from "../../helpers/constants";
import { showToastMessage } from "../../features/generalSlice";
import { toast } from "react-toastify";
const DonationLinksPage = ()=>{
    const navigate = useNavigate()
    const dispatch = useAppDispatch()
    const donationLinks = useAppSelector(selectDonationLinks)
    const ntumaAccount = useAppSelector(selectNtumaAccount);
    const loadingLinksStatus = useAppSelector(state=>state.donationLinks.loadingLinksStatus)
    const [currentPage, setCurrentPage] = useState(1);
    const totalPages = useAppSelector(state=>state.donationLinks.totalPages)
    const landingUrl = process.env.REACT_APP_LANDING_URL;

    useEffect(()=>{
      dispatch(resetCursor())
      dispatch(fetchDonationLinks())
    },[ntumaAccount])

    const sumCollections =(data:TypeDonationLinkCollection[]):string =>  {
        let totalAmount = 0;
        for (const item of data) {
          if (item.collection) {
            if (item.collection.status === "successful") {
              totalAmount += item.collection.amount;
            }
          }
        }
        return totalAmount.toLocaleString();
    }

    const copyLink =(value:string)=>{
      dispatch(showToastMessage({
        message:"Copied",
        type:"success",
        position: toast.POSITION.TOP_RIGHT
      }));
      navigator.clipboard.writeText(value);
    }

    return (
        <div>
              <div className="card mb-12">
                
                <div className="card-body flex flex-col px-4 py-4 min-h-half-screen pb-16">
                  <div className="flex">
                    <nav className="flex justify-between " aria-label="Breadcrumb">
                      <ol className="inline-flex items-center mb-3 sm:mb-0">
                        <li>
                          <div className="flex items-center">
                            <h5 className=" card-title ">Donation Links</h5>
                          </div>
                        </li>
                       
                      </ol>
                    </nav>
                    <div className="flex-grow flex justify-end">
                      <button onClick={()=> navigate("/new-donation-link")} type="button" className="prim-btn-1 mb-2">New Donation Link</button>
                    </div>
                  </div>
                  {
                    (donationLinks.length===0 && loadingLinksStatus != APP_STATUS.PENDING)&&
                    <div  className=" h-fit flex-grow flex flex-col justify-center ">
                      <div className="flex justify-center">
                          <div>
                              <img className=" w-32" src={NoData} alt=""  />
                              <p>No Donation Links Yet!</p>
                          </div>
                      </div>
                    </div>
                  }
                  {
                    (donationLinks.length>0)&&
                    <table className="w-full text-sm text-left text-gray-500 dark:text-gray-400">
                      <thead>
                          <tr className="">
                              <th scope="col" >
                                  Title
                              </th>
                              <th scope="col" >
                                  Type
                              </th>
                              <th scope="col" >
                                  Target
                              </th>
                              <th scope="col" >
                                  Created
                              </th>
                              <th scope="col" >
                                  Donations
                              </th>
                              <th></th>
                          </tr>
                      </thead>
                      <tbody>
                      {
                            donationLinks.map((item,key)=>
                              <tr key={key}>
                                  <td className=" max-w-[200px]">
                                      {item.title}
                                  </td>
                                  <td >
                                      {item.type}
                                  </td>
                                  <td>
                                      {item.target.toLocaleString()}
                                  </td>
                                  <td>
                                      { formatDate(item.created_at,"MMM DD, YYYY H:mm")}
                                  </td>
                                  <td>
                                    {/* <Link to={"/live/"+item.ref }>
                                      {sumCollections(item.collections)}
                                    </Link> */}
                                    {sumCollections(item.collections)}
                                  </td>
                                  <td>
                                      <div className=" ">
                                        <a onClick={()=>copyLink(landingUrl+"/fund/"+item.ref)} href="#!" className="font-medium text-prim-green  hover:underline">copy</a>
                                      {/* <Link to={"/donation-links/edit/"+item.ref} className=" fa fa-eye text-gray-500 ml-3 mt-1 text-sm"></Link> */}
                                      <Link to={"/donation-links/edit/"+item.ref} className=" fa fa-edit text-gray-500 ml-2 text-xs"></Link>


                                      {/* <DropdownButton
                                        options={
                                          [ {text:"Collections", onClick:()=>{}}, {text:"Edit", onClick:()=>{}} ]
                                          
                                        }
                                      buttonClass="btn btn-light px-2 py-[5px] ml-4 text-xs text-gray-600" /> */}
                                      </div>
                                  </td>
                              </tr>
                              
                            )
                          }
                        
                          
                      </tbody>
                    </table>
                  }
                 
                  {(loadingLinksStatus === APP_STATUS.PENDING)&& <AppSpinner/>}
                  {
                    (currentPage < totalPages )&&
                    <div  className=" flex justify-center mt-16">
                      <button onClick={()=> {
                        setCurrentPage(currentPage+1)
                        dispatch(fetchDonationLinks())
                      }} type="button" className="btn-light text-gray-600 ">
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" className=" text-gray-600" viewBox="0 0 24 24"><path fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M14 16h5v5M10 8H5V3m14.418 6.003A8 8 0 0 0 5.086 7.976m-.504 7.021a8 8 0 0 0 14.331 1.027"/></svg>
                        <span className="pl-1">Load More</span>
                      </button>
                    </div>
                  }
                </div>
              </div>
              
        </div>
    )
}
export default DonationLinksPage;