import { EntityState, createAsyncThunk, createEntityAdapter, createSlice } from "@reduxjs/toolkit";
import { APP_STATUS, BASE_URL, TypeActiveEnviroment, TypeAppStatus, TypeCollectionStatus } from "../../helpers/constants";
import axios, { AxiosError } from "axios";
import { selectAccessToken } from "../auth/authSlice";
import { RootState } from "../../app/store";
import { handleSessionExpiration } from "../../helpers/commons";
import { TypeCustomer } from "../customers/customersSlice";
import { TypeExtraInput } from "../../pages/payment_links/NewPaymentLink";

interface DonationLinkState extends EntityState<TypeDonationLink>{
    loadingLinksStatus:TypeAppStatus,
    lastCursor:string|null,
    nextCursor:string|null,
    totalItems:number|null
    totalPages:number,
    cursorReset:boolean
}

export type TypeCollection ={
    amount:number,
    charge?:number,
    status:TypeCollectionStatus,
    msisdn:string
    msisdn_carrier:string,
    transaction_id:string,
    created_at:string,
    customer?:TypeCustomer
}

export interface TypeExtraValues {
    field: string,
    value: string | string[]
}


export type TypeDonationLinkCollection ={
    amount:number,
    payment_method:string,
    phone_number?:string,
    email?:string,
    created_at:string
    extra_data:TypeExtraValues[],
    collection:TypeCollection
}
export type TypeDonationLink ={
    id:number,
    ntuma_account_id:number,
    ref:string,
    title: string
    type: string,
    description?:string
    target:number,
    beneficiary:string | null,
    image:string | null,
    active_environment:TypeActiveEnviroment
    extra_fields?:TypeExtraInput[],
    created_at:string,
    collections:[]
}

const donationLinksAdapter = createEntityAdapter<TypeDonationLink>()
const initialState:DonationLinkState = donationLinksAdapter.getInitialState({
    loadingLinksStatus:"idle",
    savingLinkStatus:"idle",
    lastCursor:null,
    nextCursor:null,
    totalItems:null,
    totalPages:0,
    cursorReset:true
})

export type TypeSaveLinkData ={
    title:string,
    amount:number,
    description?:string
    fixed_amount:boolean,
    extra_fields: TypeExtraInput[]
    redirect_url:string | null
}

export const fetchDonationLinks = createAsyncThunk("auth/fetchDonationLinks", async(_,{getState,rejectWithValue,dispatch})=>{
   try {
    const auth_token = selectAccessToken(getState() as RootState);
    const nextCursor = selectNextCursor(getState() as RootState)
    let dataUrl = "";
    if (nextCursor ==null) {
        dataUrl = "/api/donation-link"
    }else{
        dataUrl="/api/donation-link?cursor="+nextCursor
    }
    const response = await axios.get(BASE_URL+dataUrl,{
        headers: {
            Authorization: 'Bearer '+auth_token,
        }
    })
    return response.data.data
   } catch (error) {
        const errorResponse = error as AxiosError;
        handleSessionExpiration(errorResponse,dispatch);
        if (errorResponse.response) {
            const data = errorResponse.response.data as {message:string, status:string};
            return rejectWithValue(data.message);
        }else{
            return rejectWithValue("Failed. Something went wrong");
        }
   }
})



const donationLinksSlice = createSlice({
    initialState,
    name:"donationLinks",
    reducers:{
        resetCursor(state){
            state.nextCursor = null
            state.lastCursor =null
            state.cursorReset = true
        },
        
    },
    extraReducers:(builder)=>{
        builder
        .addCase(fetchDonationLinks.pending,(state,action)=>{
            state.loadingLinksStatus = APP_STATUS.PENDING
        })
        .addCase(fetchDonationLinks.fulfilled,(state,action)=>{
            state.loadingLinksStatus = APP_STATUS.SUCCESS
            if (state.cursorReset) {
                donationLinksAdapter.removeAll(state)
            }
            donationLinksAdapter.addMany(state,action.payload.items)
            state.lastCursor = action.payload.lastCursor
            state.nextCursor = action.payload.nextCursor
            state.totalItems = action.payload.totalItems
            state.totalPages = action.payload.totalPages
            state.cursorReset = false
        })
        .addCase(fetchDonationLinks.rejected,(state,action)=>{
            state.loadingLinksStatus = APP_STATUS.ERROR
        })
    }
})

export const {
    resetCursor
} = donationLinksSlice.actions

export default donationLinksSlice.reducer;
export const {
    selectAll: selectDonationLinks,
    selectById: selectDonationLinkById,
  } = donationLinksAdapter.getSelectors((state:RootState) => state.donationLinks)

  export const selectNextCursor = (state: RootState) => state.donationLinks.nextCursor;
