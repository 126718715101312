import { EntityState, createAsyncThunk, createEntityAdapter, createSlice } from "@reduxjs/toolkit";
import { APP_STATUS, BASE_URL, TypeActiveEnviroment, TypeAppStatus, TypeCollectionStatus } from "../../helpers/constants";
import { TypeExtraInput } from "../../pages/payment_links/NewPaymentLink";
import axios, { AxiosError } from "axios";
import { selectAccessToken } from "../auth/authSlice";
import { RootState } from "../../app/store";
import { handleSessionExpiration } from "../../helpers/commons";
import { TypeCustomer } from "../customers/customersSlice";

interface PaymentLinkState extends EntityState<TypePaymentLink>{
    loadingLinksStatus:TypeAppStatus,
    lastCursor:string|null,
    nextCursor:string|null,
    totalItems:number|null
    totalPages:number,
    cursorReset:boolean
}

export type TypeCollection ={
    amount:number,
    currency:string,
    charge?:number,
    status:TypeCollectionStatus,
    msisdn:string
    msisdn_carrier:string,
    transaction_id:string,
    created_at:string,
    customer?:TypeCustomer,
    collection_channel: string,
    ussd_client_code:number
}

export interface TypeExtraValues {
    field: string,
    value: string | string[]
}


export type TypePaymentLinkCollection ={
    amount:number,
    currency:string,
    payment_method:string,
    phone_number?:string,
    email?:string,
    created_at:string
    extra_data:TypeExtraValues[],
    collection:TypeCollection,
    ussd_client_code:number
}
export type TypePaymentLink ={
    id:number,
    ntuma_account_id:number,
    ref:string,
    title: string
    fixed_amount: boolean
    description?:string
    amount:number,
    active_environment:TypeActiveEnviroment
    extra_fields?:TypeExtraInput[],
    redirect_url?:string,
    created_at:string,
    clientCode:number,
    collections:[]
    
}

const paymentLinksAdapter = createEntityAdapter<TypePaymentLink>()
const initialState:PaymentLinkState = paymentLinksAdapter.getInitialState({
    loadingLinksStatus:"idle",
    savingLinkStatus:"idle",
    lastCursor:null,
    nextCursor:null,
    totalItems:null,
    totalPages:0,
    cursorReset:true
})

export type TypeSaveLinkData ={
    title:string,
    amount:number,
    description?:string
    fixed_amount:boolean,
    extra_fields: TypeExtraInput[]
    redirect_url:string | null
}

export const fetchPaymentLinks = createAsyncThunk("auth/fetchPaymentLinks", async(_,{getState,rejectWithValue,dispatch})=>{
   try {
    const auth_token = selectAccessToken(getState() as RootState);
    const nextCursor = selectNextCursor(getState() as RootState)
    let dataUrl = "";
    if (nextCursor ==null) {
        dataUrl = "/api/payment-link"
    }else{
        dataUrl="/api/payment-link?cursor="+nextCursor
    }
    const response = await axios.get(BASE_URL+dataUrl,{
        headers: {
            Authorization: 'Bearer '+auth_token,
        }
    })
    return response.data.data
   } catch (error) {
        const errorResponse = error as AxiosError;
        handleSessionExpiration(errorResponse,dispatch);
        if (errorResponse.response) {
            const data = errorResponse.response.data as {message:string, status:string};
            return rejectWithValue(data.message);
        }else{
            return rejectWithValue("Failed. Something went wrong");
        }
   }
})



const paymentLinksSlice = createSlice({
    initialState,
    name:"paymentLinks",
    reducers:{
        resetCursor(state){
            state.nextCursor = null
            state.lastCursor =null
            state.cursorReset = true
        },
        
    },
    extraReducers:(builder)=>{
        builder
        .addCase(fetchPaymentLinks.pending,(state,action)=>{
            state.loadingLinksStatus = APP_STATUS.PENDING
        })
        .addCase(fetchPaymentLinks.fulfilled,(state,action)=>{
            state.loadingLinksStatus = APP_STATUS.SUCCESS
            if (state.cursorReset) {
                paymentLinksAdapter.removeAll(state)
            }
            paymentLinksAdapter.addMany(state,action.payload.items)
            state.lastCursor = action.payload.lastCursor
            state.nextCursor = action.payload.nextCursor
            state.totalItems = action.payload.totalItems
            state.totalPages = action.payload.totalPages
            state.cursorReset = false
        })
        .addCase(fetchPaymentLinks.rejected,(state,action)=>{
            state.loadingLinksStatus = APP_STATUS.ERROR
        })
    }
})

export const {
    resetCursor
} = paymentLinksSlice.actions

export default paymentLinksSlice.reducer;
export const {
    selectAll: selectPaymentLinks,
    selectById: selectPaymentLinkById,
  } = paymentLinksAdapter.getSelectors((state:RootState) => state.paymentLinks)

  export const selectNextCursor = (state: RootState) => state.paymentLinks.nextCursor;
