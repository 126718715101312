import { configureStore, ThunkAction, Action } from '@reduxjs/toolkit';
import counterReducer from '../features/counter/counterSlice';
import authReducer from '../features/auth/authSlice'
import generalReducer from '../features/generalSlice'
import paymentLinksReducer from '../features/paymentlinks/paymentLinksSlice'
import donationLinksReducer from '../features/donationlinks/donationLinksSlice'

import collectionsReducer from '../features/transactions/collectionsSlice';
import customerReducer from '../features/customers/customersSlice';
import settlementReducer from '../features/transactions/settlementsSlice';
import staffReducer from '../features/staff/staffSlice';

export const store = configureStore({
  reducer: {
    counter: counterReducer,
    auth: authReducer,
    general: generalReducer,
    paymentLinks: paymentLinksReducer,
    donationLinks: donationLinksReducer,
    collections: collectionsReducer,
    customers: customerReducer,
    settlements: settlementReducer,
    staff: staffReducer
  },
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;
