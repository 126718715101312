import { useEffect, useReducer, useRef, useState } from "react";
import AppButton from "../elements/AppButton";
import MoneyInput from "../elements/MoneyInput";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { showToastMessage } from "../../features/generalSlice";
import { toast } from "react-toastify";
import DropFile, { DropFileFunctions } from "../elements/DropFile";
import { selectAccessToken, selectNtumaAccount } from "../../features/auth/authSlice";
import useApiRequest from "../../helpers/api_request";
import { APP_STATUS, BASE_URL } from "../../helpers/constants";
import { AxiosError } from "axios";
import { Link, useNavigate, useParams } from "react-router-dom";
import { TypeDonationLink, TypeSaveLinkData, selectDonationLinkById } from "../../features/donationlinks/donationLinksSlice";
import AppSpinner from "../elements/AppSpinner";
import { AddtionalInputsModal, LogoModal, TypeExtraInput } from "../payment_links/NewPaymentLink";
import { getFileNameFromUlr, notEmpty } from "../../helpers/commons";

type TypeExtraInputState = TypeExtraInput[]
type TypeExtraInputAction = {
    type: string,
    payload: {
        data?: TypeExtraInput,
        index?: number
    }
}
const extraInputsReducer = (state: TypeExtraInputState, action: TypeExtraInputAction): TypeExtraInputState => {
    switch (action.type) {
        case "add":
            return (action.payload.data) ? [...state, action.payload.data] : state;
        case "remove":
            return state.filter((input, index) => index !== action.payload.index);
        default:
            return state
    }
}


const NewDonationLink = () => {
    const [extraInputs, extraInputDispatch] = useReducer(extraInputsReducer, [])
    const [showExtraInputsModal, setShowExtraInputsModal] = useState(false)
    const [showLogoModal, setShowLogoModal] = useState(false)
    const [title, setTitle] = useState("")
    const [description, setDescription] = useState("")
    const [linkType, setLinkType] = useState("public")
    const [target, setTarget] = useState(0)
    const [savingLinkStatus, setSavingLinkStatus] = useState(false);
    const dispatch = useAppDispatch()
    const navigate = useNavigate()
    const apiRequest = useApiRequest();
    const accessToken = useAppSelector(selectAccessToken)
    const { ref } = useParams();
    const [selectedDonationLink, setSelectedDonationLink] = useState<TypeDonationLink | null>(null);
    const [isLoadingLink, setIsLoadingLink] = useState(false);
    const [imageUrl, setImageUrl] = useState<string | null>(null);
    const storageUrl = process.env.REACT_APP_STORAGE_URL;
    const dropFileRef = useRef<DropFileFunctions>(null);
    const [beneficiary,setBeneficiary] = useState<string | null>("");

    const extraInputAdded = (extraInput: TypeExtraInput) => {
        extraInputDispatch({
            type: "add", payload: {
                data: extraInput
            }
        })
        setShowExtraInputsModal(false);
    }

    useEffect(() => {
        if (ref != undefined && selectedDonationLink === null) {
            getDonationLinkDetails()
        }
    }, [])

    const onSaveDonationLink = async () => {

        let message = "Donation Link Saved";
        let saveUrl = BASE_URL + "/api/donation-link/create";
        if (selectedDonationLink !== null) {
            saveUrl = BASE_URL + "/api/donation-link/update/" + selectedDonationLink.id
            message = "Donation Link Updated"
        }
        setSavingLinkStatus(true)
        apiRequest({
            method: 'post',
            url: saveUrl,
            headers: {
                Authorization: 'Bearer ' + accessToken,
            },
            data: {
                title: title,
                target: target,
                description: description,
                type: linkType,
                extra_fields: extraInputs,
                beneficiary: beneficiary,
                image:imageUrl
            }
        }).then((response) => {
            dispatch(showToastMessage({
                message: message,
                type: "success",
                position: toast.POSITION.TOP_RIGHT
            }));
            setSavingLinkStatus(false)
            navigate("/donation-links")
        }).catch((error) => {
            const errorResponse = error as AxiosError;
            if (errorResponse.response) {
                const data = errorResponse.response.data as { message: string, status: string };
                toast.error(data.message, {
                    position: toast.POSITION.TOP_RIGHT
                });
            } else {
                toast.error("Something went wrong", {
                    position: toast.POSITION.TOP_RIGHT
                });
            }
            setSavingLinkStatus(false)
        })

    }

    // IF EDITING
    const getDonationLinkDetails = async () => {
        setIsLoadingLink(true)
        await apiRequest({
            method: 'get',
            url: BASE_URL + "/api/donation-link/details/" + ref,
            headers: {
                Authorization: 'Bearer ' + accessToken,
            }
        }).then((response) => {
            setSelectedDonationLink(response.data.details)
            const donationLink: TypeDonationLink = response.data.details;
            if (donationLink != null) {
                setTitle(donationLink.title)
                setDescription((donationLink.description) ? donationLink.description : "")
                setLinkType(donationLink.type)
                setTarget(donationLink.target)
                setBeneficiary(donationLink.beneficiary)
                setImageUrl(donationLink.image)
                if (donationLink.extra_fields) {
                    for (const extra_field of donationLink.extra_fields) {
                        extraInputDispatch({
                            type: "add", payload: {
                                data: extra_field
                            }
                        })
                    }
                }
            }
            setIsLoadingLink(false)
        }).catch((error) => {
            const errorResponse = error as AxiosError;
            if (errorResponse.response) {
                const data = errorResponse.response.data as { message: string, status: string };
                toast.error(data.message, {
                    position: toast.POSITION.TOP_RIGHT
                });
            } else {
                toast.error("Something went wrong", {
                    position: toast.POSITION.TOP_RIGHT
                });
            }
            setIsLoadingLink(false)
        });
    }

    return (
        <div>
            <div className="card mb-5">
                <div className="card-body flex flex-col px-4 py-4 min-h-half-screen">

                    <div className="flex">
                        <nav className="flex justify-between " aria-label="Breadcrumb">
                            <ol className="inline-flex items-center mb-3 sm:mb-0">
                                <li>
                                    <div className="flex items-center">
                                        <Link to="/donation-links" className=" card-title ">Donation Links</Link>
                                    </div>
                                </li>
                                <span className="mx-2 text-gray-400">/</span>
                                <li aria-current="page">
                                    <div className="flex items-center">
                                        <h5 className=" card-title ">Create</h5>
                                    </div>
                                </li>
                            </ol>
                        </nav>
                    </div>
                    {
                        (isLoadingLink) ?
                            <div>
                                <AppSpinner />
                            </div>
                            :
                            <div className=" h-fit flex-grow  ">
                                <div className=" w-2/3 mt-3">
                                    <label htmlFor="email" className="block text-sm font-medium text-gray-700 ">Title</label>
                                    <input value={title} onChange={(e) => setTitle(e.target.value)} type="text" id="name" className="form-input-2" placeholder="" />
                                </div>
                                <div className=" w-2/3 mt-4">
                                    <label htmlFor="" className="block text-sm font-medium text-gray-700 ">Description (optional)</label>
                                    <textarea value={description} onChange={(e) => setDescription(e.target.value)} className="form-input-2" name="" id="" cols={30} rows={7}></textarea>
                                </div>
                                <div className=" w-2/3 mt-3">
                                    <label htmlFor="email" className="block text-sm font-medium text-gray-700 ">Beneficiary</label>
                                    <input value={(beneficiary)?beneficiary:""} onChange={(e) => setBeneficiary(e.target.value)} type="text" id="name" className="form-input-2" placeholder="" />
                                </div>
                                
                                <div className=" w-2/3 mt-4">
                                    <label htmlFor="" className="block text-sm font-medium text-gray-700 ">Link type</label>
                                    <select value={linkType} onChange={(e) => setLinkType(e.target.value)} name="" className="form-input-2" id="">
                                        <option value="public">Public</option>
                                        <option value="private">Private</option>
                                    </select>
                                </div>
                                <span className=" text-red-600 text-xs">Public means the link will be advertised on our landing page</span>

                                <div className=" w-2/3 mt-4">
                                    <label htmlFor="email" className="block text-sm font-medium text-gray-700 ">Target Amount</label>

                                    <MoneyInput value={target} onChange={(value) => setTarget(value)} className="form-input-2" />
                                </div>

                                <div className="w-1/3 mt-4">
                                    <label htmlFor="image" className="block text-sm font-medium text-gray-700 ">Image</label>

                                    {
                                        notEmpty(imageUrl) ?
                                            <p className="mt-2">
                                                <a target="_blank" className=" text-green-600" href={storageUrl + "/" + imageUrl}> {getFileNameFromUlr(imageUrl)} </a> <small
                                                    onClick={() => {
                                                        setImageUrl(null)
                                                    }
                                                    }

                                                    className="text-red-600  cursor-pointer">(change)</small>

                                            </p> :
                                            <DropFile ref={dropFileRef} getFileUrl={(fileUrl) => {
                                                setImageUrl(fileUrl)
                                            }} StorageFolder="certificates" SubText="Image" />
                                    }
                                </div>

                                <div className=" flex gap-2  mt-4">
                                    <button onClick={() => setShowExtraInputsModal(true)} type="button" className="btn-light ">+ Extra Inputs</button>
                                    <button onClick={() => setShowLogoModal(true)} type="button" className="btn-light ">Update Logo</button>
                                </div>
                                {
                                    extraInputs.map((item: TypeExtraInput, key) =>
                                        <div className="w-2/3 mt-4" key={key}>
                                            <label htmlFor="email" className="0 text-sm font-medium text-gray-700 flex ">{item.label}
                                                <svg onClick={() => {
                                                    extraInputDispatch({
                                                        type: "remove", payload: {
                                                            index: key
                                                        }
                                                    })
                                                }} xmlns="http://www.w3.org/2000/svg" className="ml-1 text-red-600 cursor-pointer" width="18" height="18" viewBox="0 0 24 24"><path fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M4 7h16m-10 4v6m4-6v6M5 7l1 12a2 2 0 0 0 2 2h8a2 2 0 0 0 2-2l1-12M9 7V4a1 1 0 0 1 1-1h4a1 1 0 0 1 1 1v3" /></svg>
                                            </label>

                                            {
                                                (item.type === 'text') &&
                                                <input type="text" className="form-input-2" placeholder="" />
                                            }
                                            {
                                                (item.type === 'multi-text') &&
                                                <textarea className="form-input-2" name="" id="" cols={30} rows={5}></textarea>
                                            }
                                            {
                                                (item.type === 'select') &&
                                                <select name="" className="form-input-2" id="">
                                                    {
                                                        item.options.map((option, key2) => <option key={key2} value={option}>{option}</option>)
                                                    }
                                                </select>
                                            }
                                            {
                                                (item.type === 'checkbox') &&
                                                item.options.map((item, key3) =>
                                                    <div key={key3} className="custom-check2 mt-1">
                                                        <input type="checkbox" id={"checkbox1" + key3} />
                                                        <label htmlFor={"checkbox1" + key3} className="text-sm font-medium text-gray-700 ">
                                                            {item}
                                                        </label>
                                                    </div>
                                                )

                                            }
                                            {
                                                (item.type === 'radio') &&
                                                item.options.map((item, key4) =>
                                                    <div key={key4} className="custom-radio mt-1">
                                                        <input name="radio" type="radio" id={"radio" + key4} />
                                                        <label htmlFor={"radio" + key4} className="text-sm font-medium text-gray-700 ">
                                                            {item}
                                                        </label>
                                                    </div>
                                                )

                                            }

                                        </div>
                                    )
                                }
                                <LogoModal isOpen={showLogoModal} onClose={() => setShowLogoModal(false)} />

                                <AddtionalInputsModal onClose={() => setShowExtraInputsModal(false)} isOpen={showExtraInputsModal} extraInputAdded={(extraInput) => extraInputAdded(extraInput)} />
                                <AppButton callBackFun={() => onSaveDonationLink()} showLoader={savingLinkStatus} spinnerClass="inline w-3 h-3 mr-2 text-prim-yellow animate-spin fill-black" className="prim-btn-1 mt-6  " text="Save Donation Link" />
                            </div>
                    }

                </div>
            </div>
        </div>
    )
}
export default NewDonationLink;

