import { useEffect, useReducer, useState } from "react";
import AppButton from "../elements/AppButton";
import MoneyInput from "../elements/MoneyInput";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { showToastMessage } from "../../features/generalSlice";
import { toast } from "react-toastify";
import DropFile from "../elements/DropFile";
import { selectAccessToken, selectNtumaAccount } from "../../features/auth/authSlice";
import useApiRequest from "../../helpers/api_request";
import { APP_STATUS, BASE_URL } from "../../helpers/constants";
import { AxiosError } from "axios";
import { Link, useNavigate, useParams } from "react-router-dom";
import { TypePaymentLink, TypeSaveLinkData, selectPaymentLinkById } from "../../features/paymentlinks/paymentLinksSlice";
import AppSpinner from "../elements/AppSpinner";

type TypeExtraInputState = TypeExtraInput[]
type TypeExtraInputAction = {
    type: string,
    payload: {
        data?: TypeExtraInput,
        index?: number
    }
}
const extraInputsReducer = (state: TypeExtraInputState, action: TypeExtraInputAction): TypeExtraInputState => {
    switch (action.type) {
        case "add":
            return (action.payload.data) ? [...state, action.payload.data] : state;
        case "remove":
            return state.filter((input, index) => index !== action.payload.index);
        default:
            return state
    }
}


const NewPaymentLink = () => {
    const [extraInputs, extraInputDispatch] = useReducer(extraInputsReducer, [])
    const [showExtraInputsModal, setShowExtraInputsModal] = useState(false)
    const [showLogoModal, setShowLogoModal] = useState(false)
    const [showRedirectionModal, setShowRedirectionModal] = useState(false)
    const [redirectUrl, setRedirectUrl] = useState<string | null>(null)
    const [title, setTitle] = useState("")
    const [description, setDescription] = useState("")
    const [linkType, setLinkType] = useState("Fixed Charge")
    const [amount, setAmount] = useState(0)
    const [savingLinkStatus, setSavingLinkStatus] = useState(false);
    const dispatch = useAppDispatch()
    const navigate = useNavigate()
    const apiRequest = useApiRequest();
    const accessToken = useAppSelector(selectAccessToken)
    const { ref } = useParams();
    const [selectedPaymentLink, setSelectedPaymentLink] = useState<TypePaymentLink | null>(null);
    const [isLoadingLink, setIsLoadingLink] = useState(false);

    const extraInputAdded = (extraInput: TypeExtraInput) => {
        extraInputDispatch({
            type: "add", payload: {
                data: extraInput
            }
        })
        setShowExtraInputsModal(false);
    }

    useEffect(() => {
        if (ref != undefined && selectedPaymentLink === null) {
            getPaymentLinkDetails()
        }
    }, [])

    const onSavePaymentLink = async () => {

        let message = "Payment Link Saved";
        let saveUrl = BASE_URL + "/api/payment-link/create";
        if (selectedPaymentLink !== null) {
            saveUrl = BASE_URL + "/api/payment-link/update/" + selectedPaymentLink.id
            message = "Payment Link Updated"
        }
        setSavingLinkStatus(true)
        apiRequest({
            method: 'post',
            url: saveUrl,
            headers: {
                Authorization: 'Bearer ' + accessToken,
            },
            data: {
                title: title,
                amount: amount,
                description: description,
                fixed_amount: (linkType === "Fixed Charge") ? true : false,
                extra_fields: extraInputs,
                redirect_url: redirectUrl
            }
        }).then((response) => {
            dispatch(showToastMessage({
                message: message,
                type: "success",
                position: toast.POSITION.TOP_RIGHT
            }));
            setSavingLinkStatus(false)
            navigate("/payment-links")
        }).catch((error) => {
            const errorResponse = error as AxiosError;
            if (errorResponse.response) {
                const data = errorResponse.response.data as { message: string, status: string };
                toast.error(data.message, {
                    position: toast.POSITION.TOP_RIGHT
                });
            } else {
                toast.error("Something went wrong", {
                    position: toast.POSITION.TOP_RIGHT
                });
            }
            setSavingLinkStatus(false)
        })

    }

    // IF EDITING
    const getPaymentLinkDetails = async () => {
        setIsLoadingLink(true)
        await apiRequest({
            method: 'get',
            url: BASE_URL + "/api/payment-link/details/" + ref,
            headers: {
                Authorization: 'Bearer ' + accessToken,
            }
        }).then((response) => {
            setSelectedPaymentLink(response.data.details)
            const paymentLink: TypePaymentLink = response.data.details;
            if (paymentLink != null) {
                setTitle(paymentLink.title)
                setDescription((paymentLink.description) ? paymentLink.description : "")
                setLinkType((paymentLink.fixed_amount) ? "Fixed Charge" : "Dynamic Charge")
                setAmount(paymentLink.amount)
                setRedirectUrl((paymentLink.redirect_url) ? paymentLink.redirect_url : "")
                if (paymentLink.extra_fields) {
                    for (const extra_field of paymentLink.extra_fields) {
                        extraInputDispatch({
                            type: "add", payload: {
                                data: extra_field
                            }
                        })
                    }
                }
            }
            setIsLoadingLink(false)
        }).catch((error) => {
            const errorResponse = error as AxiosError;
            if (errorResponse.response) {
                const data = errorResponse.response.data as { message: string, status: string };
                toast.error(data.message, {
                    position: toast.POSITION.TOP_RIGHT
                });
            } else {
                toast.error("Something went wrong", {
                    position: toast.POSITION.TOP_RIGHT
                });
            }
            setIsLoadingLink(false)
        });
    }

    return (
        <div>
            <div className="card mb-5">
                <div className="card-body flex flex-col px-4 py-4 min-h-half-screen">

                    <div className="flex">
                        <nav className="flex justify-between " aria-label="Breadcrumb">
                            <ol className="inline-flex items-center mb-3 sm:mb-0">
                                <li>
                                    <div className="flex items-center">
                                        <Link to="/payment-links" className=" card-title ">Payment Links</Link>
                                    </div>
                                </li>
                                <span className="mx-2 text-gray-400">/</span>
                                <li aria-current="page">
                                    <div className="flex items-center">
                                        <h5 className=" card-title ">Create</h5>
                                    </div>
                                </li>
                            </ol>
                        </nav>
                    </div>
                    {
                        (isLoadingLink) ?
                            <div>
                                <AppSpinner />
                            </div>
                            :
                            <div className=" h-fit flex-grow  ">
                                <div className=" w-2/3 mt-3">
                                    <label htmlFor="email" className="block text-sm font-medium text-gray-700 ">Title</label>
                                    <input value={title} onChange={(e) => setTitle(e.target.value)} type="text" id="name" className="form-input-2" placeholder="" />
                                </div>
                                <div className=" w-2/3 mt-4">
                                    <label htmlFor="" className="block text-sm font-medium text-gray-700 ">Description (optional)</label>
                                    <textarea value={description} onChange={(e) => setDescription(e.target.value)} className="form-input-2" name="" id="" cols={30} rows={7}></textarea>
                                </div>
                                <div className=" w-2/3 mt-4">
                                    <label htmlFor="" className="block text-sm font-medium text-gray-700 ">Link type</label>
                                    <select value={linkType} onChange={(e) => setLinkType(e.target.value)} name="" className="form-input-2" id="">
                                        <option value="Fixed Charge">Fixed Charge</option>
                                        <option value="Dynamic Charge">Dynamic Charge</option>
                                    </select>
                                </div>

                                {
                                    (linkType === "Fixed Charge") &&
                                    <div className=" w-2/3 mt-4">
                                        <label htmlFor="email" className="block text-sm font-medium text-gray-700 ">Price</label>

                                        <MoneyInput value={amount} onChange={(value) => setAmount(value)} className="form-input-2" />
                                    </div>
                                }

                                {
                                    (redirectUrl) &&
                                    <div className=" w-2/3 mt-4">
                                        <label htmlFor="email" className="text-sm font-medium text-gray-700 flex ">Redirection
                                            <svg onClick={() => setRedirectUrl(null)} xmlns="http://www.w3.org/2000/svg" className="ml-1 text-red-600 cursor-pointer" width="18" height="18" viewBox="0 0 24 24"><path fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M4 7h16m-10 4v6m4-6v6M5 7l1 12a2 2 0 0 0 2 2h8a2 2 0 0 0 2-2l1-12M9 7V4a1 1 0 0 1 1-1h4a1 1 0 0 1 1 1v3" /></svg>
                                        </label>
                                        <input value={redirectUrl} readOnly type="text" id="name" className="form-input-2" placeholder="" />
                                    </div>
                                }

                                <div className=" flex gap-2  mt-4">
                                    <button onClick={() => setShowExtraInputsModal(true)} type="button" className="btn-light ">+ Extra Inputs</button>
                                    <button onClick={() => setShowLogoModal(true)} type="button" className="btn-light ">Update Logo</button>
                                    <button onClick={() => setShowRedirectionModal(true)} type="button" className="btn-light ">Redirection </button>
                                </div>

                                {
                                    extraInputs.map((item: TypeExtraInput, key) =>
                                        <div className="w-2/3 mt-4" key={key}>
                                            <label htmlFor="email" className="0 text-sm font-medium text-gray-700 flex ">{item.label}
                                                <svg onClick={() => {
                                                    extraInputDispatch({
                                                        type: "remove", payload: {
                                                            index: key
                                                        }
                                                    })
                                                }} xmlns="http://www.w3.org/2000/svg" className="ml-1 text-red-600 cursor-pointer" width="18" height="18" viewBox="0 0 24 24"><path fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M4 7h16m-10 4v6m4-6v6M5 7l1 12a2 2 0 0 0 2 2h8a2 2 0 0 0 2-2l1-12M9 7V4a1 1 0 0 1 1-1h4a1 1 0 0 1 1 1v3" /></svg>
                                            </label>

                                            {
                                                (item.type === 'text') &&
                                                <input type="text" className="form-input-2" placeholder="" />
                                            }
                                            {
                                                (item.type === 'multi-text') &&
                                                <textarea className="form-input-2" name="" id="" cols={30} rows={5}></textarea>
                                            }
                                            {
                                                (item.type === 'select') &&
                                                <select name="" className="form-input-2" id="">
                                                    {
                                                        item.options.map((option, key2) => <option key={key2} value={option}>{option}</option>)
                                                    }
                                                </select>
                                            }
                                            {
                                                (item.type === 'checkbox') &&
                                                item.options.map((item, key3) =>
                                                    <div key={key3} className="custom-check2 mt-1">
                                                        <input type="checkbox" id={"checkbox1" + key3} />
                                                        <label htmlFor={"checkbox1" + key3} className="text-sm font-medium text-gray-700 ">
                                                            {item}
                                                        </label>
                                                    </div>
                                                )

                                            }
                                            {
                                                (item.type === 'radio') &&
                                                item.options.map((item, key4) =>
                                                    <div key={key4} className="custom-radio mt-1">
                                                        <input name="radio" type="radio" id={"radio" + key4} />
                                                        <label htmlFor={"radio" + key4} className="text-sm font-medium text-gray-700 ">
                                                            {item}
                                                        </label>
                                                    </div>
                                                )

                                            }

                                        </div>
                                    )
                                }
                                <LogoModal isOpen={showLogoModal} onClose={() => setShowLogoModal(false)} />
                                <RedirectionModal
                                    redirectUrlAdded={(redirectUrl) => {
                                        setRedirectUrl(redirectUrl)
                                        setShowRedirectionModal(false)
                                    }}
                                    isOpen={showRedirectionModal} onClose={() => setShowRedirectionModal(false)} />

                                <AddtionalInputsModal onClose={() => setShowExtraInputsModal(false)} isOpen={showExtraInputsModal} extraInputAdded={(extraInput) => extraInputAdded(extraInput)} />
                                <AppButton callBackFun={() => onSavePaymentLink()} showLoader={savingLinkStatus} spinnerClass="inline w-3 h-3 mr-2 text-prim-yellow animate-spin fill-black" className="prim-btn-1 mt-6  " text="Save Payment Link" />
                            </div>
                    }

                </div>
            </div>
        </div>
    )
}
export default NewPaymentLink;

type TypeRedirectionModalProps = {
    redirectUrlAdded: (redirectUrl: string | null) => void
    isOpen: boolean,
    onClose: () => void
}
const RedirectionModal = (props: TypeRedirectionModalProps) => {
    const [redirectUrl, setRedirectUrl] = useState<string | null>(null)
    const addRedirectUrl = () => {
        if (redirectUrl) {
            props.redirectUrlAdded(redirectUrl)
            props.onClose()
        }
    }
    return (
        <>
            {
                (props.isOpen) &&
                <div className="modal ">
                    <div className=" modal-content modal-md py-4 ">
                        <div className=" flex justify-center mb-4">
                            <span className=" text-lg">Redirection</span>
                        </div>
                        <div className="flex p-4 text-sm text-gray-800 rounded-lg bg-gray-50 " role="alert">
                            <svg aria-hidden="true" className="flex-shrink-0 inline w-5 h-5 mr-3" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7-4a1 1 0 11-2 0 1 1 0 012 0zM9 9a1 1 0 000 2v3a1 1 0 001 1h1a1 1 0 100-2v-3a1 1 0 00-1-1H9z" clipRule="evenodd"></path></svg>
                            <span className="sr-only">Info</span>
                            <div>

                                Add a Redirect URL incase you want your customers to be redirected to your site after payment
                            </div>
                        </div>
                        <div className=" mt-4">
                            <label htmlFor="redirecturl" className="block text-sm font-medium text-gray-700 ">URL <small> (e.g https://yourdomain/confirm-payment)</small> </label>
                            <input value={redirectUrl ? redirectUrl : ''} onChange={(e) => setRedirectUrl(e.target.value)} type="text" id="redirecturl" className="form-input-2" placeholder="" />
                        </div>

                        <div className="flex flex-row-reverse gap-2 mt-12">
                            <div>
                                <AppButton callBackFun={() => addRedirectUrl()} showLoader={false} spinnerClass="inline w-3 h-3 mr-2 text-prim-yellow animate-spin fill-black" className="prim-btn-2  " text="Add" />
                            </div>
                            <div>
                                <button onClick={() => props.onClose()} type="button" className="btn-secondary-1 ">Close</button>
                            </div>
                        </div>

                    </div>
                </div>
            }

        </>
    )
}

type TypeLogoModalProps = {
    isOpen: boolean,
    onClose: () => void
}
export const LogoModal = (props: TypeLogoModalProps) => {
    const ntumaAccount = useAppSelector(selectNtumaAccount);
    const [logoUrl, setLogoUrl] = useState<string | undefined>(undefined);
    const [savingLogo, setSavingLogo] = useState(false);
    const apiRequest = useApiRequest()
    const dispatch = useAppDispatch()
    const accessToken = useAppSelector(selectAccessToken)
    const [currentLogo, setCurrentLogo] = useState<string | undefined>(undefined)
    useEffect(() => {
        if (logoUrl) {
            setCurrentLogo(logoUrl)
        } else {
            setCurrentLogo(ntumaAccount?.companyBranding?.company_logo)
        }
    }, [logoUrl, ntumaAccount])

    const saveLogo = async () => {
        if (logoUrl) {
            setSavingLogo(true)
            await apiRequest({
                method: 'post',
                url: BASE_URL + "/api/ntuma-account/update-logo",
                headers: {
                    Authorization: `Bearer ${accessToken}`,
                },
                data: {
                    company_logo: logoUrl
                }
            }).then((response) => {
                setSavingLogo(false)
                dispatch(showToastMessage({
                    message: "Logo saved",
                    type: "success",
                    position: toast.POSITION.TOP_RIGHT
                }));
                setCurrentLogo(logoUrl)
                props.onClose()
            }).catch((error) => {
                const errorResponse = error as AxiosError;
                // console.log(errorResponse.response)
                if (errorResponse.response) {
                    const data = errorResponse.response.data as { message: string, status: string };
                    dispatch(showToastMessage({
                        message: data.message,
                        type: "error",
                        position: toast.POSITION.TOP_RIGHT
                    }));
                } else {
                    dispatch(showToastMessage({
                        message: "Something went wrong",
                        type: "error",
                        position: toast.POSITION.TOP_RIGHT
                    }));
                }
                setSavingLogo(false)
            });

        }
    }
    return (
        <>
            {
                (props.isOpen) &&
                <div className="modal ">
                    <div className=" modal-content modal-md py-4 ">
                        <div className=" flex justify-center mb-4">
                            <span className=" text-lg">Company Logo</span>
                        </div>
                        <div className="flex p-4 text-sm text-gray-800 rounded-lg bg-gray-50 " role="alert">
                            <svg aria-hidden="true" className="flex-shrink-0 inline w-5 h-5 mr-3" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7-4a1 1 0 11-2 0 1 1 0 012 0zM9 9a1 1 0 000 2v3a1 1 0 001 1h1a1 1 0 100-2v-3a1 1 0 00-1-1H9z" clipRule="evenodd"></path></svg>
                            <span className="sr-only">Info</span>
                            <div>
                                It is important to note that the new logo will be utilized for all payment links you create, including those already in use and any that may be created in the future
                            </div>
                        </div>


                        <div className=" flex justify-center mt-4">
                            <DropFile getFileUrl={(fileUrl) => setLogoUrl(fileUrl)} currentUrl={currentLogo} StorageFolder="companylogos" SubText="Image of your company logo" />
                        </div>
                        <div className="flex flex-row-reverse gap-2 mt-10 pt-4">
                            <div>
                                <AppButton callBackFun={() => saveLogo()} showLoader={savingLogo} spinnerClass="inline w-3 h-3 mr-2 text-prim-yellow animate-spin fill-black" className="prim-btn-2  " text="Update Logo" />
                            </div>
                            <div>
                                <button onClick={() => props.onClose()} type="button" className="btn-secondary-1 ">Close</button>
                            </div>
                        </div>

                    </div>

                </div>
            }

        </>
    );
}

type TypeAction = {
    type: string,
    payload: {
        value: string,
        index: number
    }
}
type TypeState = string[]

const optionsReducer = (state: TypeState, action: TypeAction): TypeState => {
    switch (action.type) {
        case "add":
            return [
                ...state.slice(0, action.payload.index),
                action.payload.value,
                ...state.slice(action.payload.index + 1)
            ];
        case "remove":
            return [
                ...state.slice(0, action.payload.index),
                ...state.slice(action.payload.index + 1)
            ];
        default:
            return state
    }
}

export type TypeExtraInput = {
    type: string,
    label: string,
    options: string[]
}
type TypeAddtionalInputsModalProps = {
    extraInputAdded: (extraInput: TypeExtraInput) => void,
    isOpen: boolean,
    onClose: () => void
}
export const AddtionalInputsModal = (props: TypeAddtionalInputsModalProps) => {
    const [inputType, setInputType] = useState("text");
    const [inputLabel, setInputLabel] = useState("");
    // const [inputOptions, setInputOptions] = useState<string[]>([]);
    const [optionsCount, setOptionsCount] = useState(1);
    const [inputOptions, dispatch] = useReducer(optionsReducer, []);
    const appDispatch = useAppDispatch()

    const addOptions = () => {
        setOptionsCount(optionsCount + 1);
    };

    const removeOption = () => {
        if (optionsCount > 1) {
            setOptionsCount(optionsCount - 1);
        }
    };
    // useEffect(()=>{
    //     console.log(inputOptions)
    // },[inputOptions])

    const addExtraInput = () => {
        const type = inputType
        const label = inputLabel
        const options = []
        for (const option of inputOptions) {
            if (option !== "" && option !== null && option != undefined) {
                options.push(option)
            }
        }
        if (label === "") {
            appDispatch(showToastMessage({
                message: "Label is missing",
                type: "error",
                position: toast.POSITION.TOP_RIGHT
            }));
        }
        props.extraInputAdded({
            type: type,
            label: label,
            options: options
        });
    }

    return (
        <>
            {
                (props.isOpen) &&
                <div className="modal ">
                    <div className=" modal-content modal-md pt-4 ">
                        <div className=" flex justify-center">
                            <span className=" text-center text-lg">Extra Inputs</span>
                        </div>
                        <div className=" mt-4">
                            <label htmlFor="" className="block text-sm font-medium text-gray-700 ">Input type</label>
                            <select value={inputType} onChange={(e) => setInputType(e.target.value)} name="" className="form-input-2" id="">
                                <option value="text">Text Input</option>
                                <option value="multi-text">Text box (multline)</option>
                                <option value="select">Dropdown/Select</option>
                                <option value="checkbox">Checkbox(multiple select)</option>
                                <option value="radio">Radio button(single select)</option>
                            </select>
                        </div>

                        <div className="mt-3">
                            <label htmlFor="" className="block text-sm font-medium text-gray-700 ">Label</label>
                            <input onChange={(e) => setInputLabel(e.target.value)} type="text" id="name" className="form-input-2" placeholder="" />
                        </div>
                        {
                            (inputType !== "text" && inputType !== "multi-text") &&
                            <div>
                                <div className="mt-3">
                                    <label htmlFor="" className="block text-sm font-medium text-gray-700 ">Options</label>
                                    {Array.from(Array(optionsCount).keys()).map((i) => (
                                        <input onChange={
                                            (e) => dispatch({ type: "add", payload: { value: e.target.value, index: i } })
                                        } placeholder={"option " + (i + 1)} key={i} className="form-input-2 mb-2" type="text" />
                                    ))}
                                </div>

                                <div className="flex flex-row">

                                    <div className=" ">
                                        <button onClick={() => addOptions()} type="button" className="btn-light px-2 py-1"> <span className=" text-xl">+</span> </button>
                                    </div>
                                    {
                                        (optionsCount > 1) &&
                                        <div className="h-auto flex flex-col justify-center  ml-2">
                                            <svg onClick={() => {
                                                dispatch({ type: "add", payload: { value: "", index: optionsCount - 1 } })
                                                removeOption()
                                            }} xmlns="http://www.w3.org/2000/svg" className=" text-gray-600 cursor-pointer" width="24" height="24" viewBox="0 0 24 24"><path fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M4 7h16m-10 4v6m4-6v6M5 7l1 12a2 2 0 0 0 2 2h8a2 2 0 0 0 2-2l1-12M9 7V4a1 1 0 0 1 1-1h4a1 1 0 0 1 1 1v3" /></svg>
                                        </div>
                                    }

                                </div>
                            </div>
                        }

                        <div className="">
                            <AppButton callBackFun={() => addExtraInput()} showLoader={false} spinnerClass="inline w-3 h-3 mr-2 text-prim-yellow animate-spin fill-black" className="prim-btn-2 mt-4 mb-3 w-full" text="Add Input" />
                        </div>
                        <div className=" flex justify-end mt-4">
                            <button onClick={() => props.onClose()} type="button" className="py-2.5 px-5 mr-2  text-sm font-medium text-gray-900 focus:outline-none bg-white rounded-lg border border-gray-200 hover:bg-gray-100 hover:text-prim-green focus:z-10 focus:ring-4 focus:ring-gray-200 ">Close</button>
                        </div>
                    </div>

                </div>
            }

        </>
    )
}