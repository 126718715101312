import NoData from "../../assets/img/nodata_green.svg"
import walletIcon from '../../assets/img/Wallaet_icon.svg'
import addToWalletIcon from '../../assets/img/add_to_wallet.svg'
import ProfileProgress from "../elements/ProfileProgress";
import LineChart, { ChartSerie } from "../elements/LineChart";
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import { selectAccessToken, selectActiveEnvironment, selectNtumaAccount, sessionExpired } from '../../features/auth/authSlice';
import { ACTIVE_ENV, BASE_URL, TypeCollection, TypeWallet } from '../../helpers/constants';
import { useEffect, useState } from 'react';
import useApiRequest from '../../helpers/api_request';
import AppSpinner from '../elements/AppSpinner';
import { formatDate } from '../../helpers/commons';
import { useNavigate } from "react-router-dom";

const Dashboard = () => {
    const ntumaAccount = useAppSelector(selectNtumaAccount);
    const activeEnvironment = useAppSelector(selectActiveEnvironment)
    const accessToken = useAppSelector(selectAccessToken)
    const [accountProgress, setAccountProgress] = useState(0.6);
    const [chartSeries, setChartSeries] = useState<ChartSerie[]>([]);
    const [chartCategories, setChartCategories] = useState<string[]>([]);
    const [loadingStats, setLoadingStats] = useState(false);
    const [recentCollections, setRecentCollections] = useState<TypeCollection[]>([]);
    const [loadingRecentCollections, setLoadingRecentCollections] = useState(false);
    const [usdWallet, setUsdWallet] = useState<TypeWallet | null>(null);
    const [statsData, setStatsData] = useState<any>(null);
    const apiRequest = useApiRequest();
    const navigate = useNavigate();

    useEffect(() => {
        if (ntumaAccount) {
            fetDashboardStats();
            fetchRecentCollections();
            getUsdWallet();
        }

        if (ntumaAccount?.is_approved) {
            setAccountProgress(1);
        } else {
            if (ntumaAccount?.business_details) {
                setAccountProgress(0.9);
            } else {
                setAccountProgress(0.6);
            }
        }
    }, [ntumaAccount])

    useEffect(() => {
        if (statsData != null && usdWallet != null) {
            if (usdWallet.current_balance > 0) {
                setChartSeries([{ name: 'Received UGX', data: statsData.data }, { name: 'Received USD', data: statsData.data_usd }])
            }
        }

    }, [usdWallet, statsData])


    const getUsdWallet = async () => {
        setLoadingStats(true);
        const response = await apiRequest({
            method: 'get',
            url: BASE_URL + "/api/wallet/usd",
            headers: {
                Authorization: `Bearer ${accessToken}`,
            },
        }).then((response) => {
            setUsdWallet(response.data);
            // setLoadingStats(false);
        }).catch((error) => {
            // setLoadingStats(false);
        });
    }


    const fetDashboardStats = async () => {
        setLoadingStats(true);
        const response = await apiRequest({
            method: 'get',
            url: BASE_URL + "/api/reports/dashboard-stats",
            headers: {
                Authorization: `Bearer ${accessToken}`,
            },
        }).then((response) => {
            setStatsData(response.data);
            setChartSeries([{ name: 'Received UGX', data: response.data.data }])
            setChartCategories(response.data.keys)
            setLoadingStats(false);
        }).catch((error) => {
            setLoadingStats(false);
        });
    }

    const fetchRecentCollections = async () => {
        setLoadingRecentCollections(true);
        await apiRequest({
            method: 'get',
            url: BASE_URL + "/api/collections",
            headers: {
                Authorization: `Bearer ${accessToken}`,
            },
        }).then((response) => {
            setRecentCollections(response.data.items)
            setLoadingRecentCollections(false);
        }).catch((error) => {
            setLoadingRecentCollections(false);
        });
    }

    return (
        <div>
            <div className="grid grid-cols-3 gap-3">
                <div className="card ">
                    <div className="card-body px-2 h-full  flex flex-col">
                        <div className="flex">
                            <img width={30} src={walletIcon} alt="" />
                            <span className=" text-sm flex flex-col justify-end pl-3 opacity-90">Wallet Balance</span>
                        </div>
                        <div className="flex flex-col justify-end flex-grow mt-5 ">
                            <div className="flex ">
                                <span className="text-sm font-semibold flex flex-col justify-end pb-[1px] pr-1 opacity-80">UGX</span>
                                <span className="font-bold text-xl opacity-80">

                                    {
                                        (ntumaAccount?.userWallet) ?
                                            (activeEnvironment == ACTIVE_ENV.test) ?
                                                ntumaAccount?.userWallet?.current_balance_sand_box.toLocaleString() :
                                                ntumaAccount?.userWallet?.current_balance.toLocaleString()
                                            : "0.0"
                                    }
                                </span>
                            </div>
                        </div>
                    </div>
                </div>

                <div onClick={() => navigate("/settlements")} className="card hover:bg-prim-green cursor-pointer ">
                    <div className="card-body px-2 h-full flex flex-col">
                        <div className="flex">
                            <img width={30} src={addToWalletIcon} alt="" />
                            <span className=" text-sm flex flex-col justify-end pl-3 opacity-90">Withdraws</span>
                        </div>
                        <div className="flex flex-col justify-end mt-5  flex-grow ">
                            <span className="text-sm opacity-80 ml-1">View all settlements</span>
                        </div>
                    </div>
                </div>
                <div onClick={() => navigate("/business-details")} className="card hover:bg-prim-green cursor-pointer">
                    <div className="card-body flex px-2 h-full">
                        <div className="flex-grow h-full flex flex-col">
                            <span className=" opacity-90">Profile</span>
                            <div className="flex flex-col justify-end mt-5  flex-grow ">
                                <span className="text-sm font-semibold opacity-80 ml-1">Profile completion status</span>
                            </div>
                        </div>
                        <div className=" w-1/4">
                            <ProfileProgress progress={accountProgress} />
                        </div>
                    </div>
                </div>

            </div>
            <div className="grid grid-cols-3 mt-4 gap-4">

                <div className="card col-span-2">
                    <div className="card-body">
                        <h5 className="text-gray-700 card-title">Transactions</h5>
                        <div className="mt-4">
                            {(loadingStats) && <AppSpinner />}
                            <LineChart categories={chartCategories} series={chartSeries} />
                        </div>
                    </div>
                </div>
                <div className="flex flex-col gap-3">
                    {
                        usdWallet &&
                        usdWallet.current_balance > 0 &&
                        <div className="card ">
                            <div className="card-body px-2 h-full  flex flex-col">
                                <div className="flex">
                                    <img width={30} src={walletIcon} alt="" />
                                    <span className=" text-sm flex flex-col justify-end pl-3 opacity-90">USD Wallet</span>
                                </div>
                                <div className="flex flex-col justify-end flex-grow mt-5 ">
                                    <div className="flex ">
                                        <span className="text-sm font-semibold flex flex-col justify-end pb-[1px] pr-1 opacity-80">USD</span>
                                        <span className="font-bold text-xl opacity-80">

                                            {

                                                (activeEnvironment == ACTIVE_ENV.test) ?
                                                    usdWallet.current_balance_sand_box.toLocaleString() :
                                                    usdWallet.current_balance.toLocaleString()
                                            }
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    }

                    <div className="card flex-grow ">
                        <div className="card-body min-h-half-screen flex flex-col">
                            <h5 className="text-gray-700 card-title mb-4">Recent Collections</h5>

                            {(loadingRecentCollections) && <AppSpinner />}
                            {
                                recentCollections.map((item, key) =>
                                    (key < 7) &&
                                    <div key={key} className="flex flex-row h-14">
                                        <div className=" w-1/3 h-full">
                                            <span className="text-sm text-gray-600 ">{(item.created_at) && formatDate(item.created_at, "MMM DD, YYYY")}</span>
                                        </div>
                                        <div className={item.status + " before:content-[''] border-l ml-1 before:ml-[-8.5px] before:mt-[4px] timeline-item h-full "}>
                                            <span className="pl-4 text-sm text-gray-600">{item.amount.toLocaleString()}</span>
                                        </div>
                                    </div>
                                )
                            }
                            {(!loadingRecentCollections && recentCollections.length == 0) &&
                                <div className=" h-fit flex-grow flex flex-col justify-center">
                                    <div className="flex justify-center ">
                                        <div>
                                            <img className=" w-20 ml-2" src={NoData} alt="" />
                                            <p className=" text-sm">No Collections  Yet!</p>
                                        </div>
                                    </div>
                                </div>
                            }



                        </div>
                    </div>
                </div>

            </div>
        </div>
    );
}

export default Dashboard;