import Logo from "../../assets/img/ntuma_logo.svg"
import Group_37 from "../../assets/img/Group_37.svg"
import PasswordInput from "../elements/PasswordInput";
import { Link, Navigate, useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import AppButton from "../elements/AppButton";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { APP_STATUS } from "../../helpers/constants";
import { registerUser } from "../../features/auth/authSlice";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { validatePassword } from "../../helpers/commons";
import moment from "moment";
const SignUpPage =  ()=>{
    const [firstname, setFirstName] =  useState("");
    const [lastname, setLastName] = useState("");
    const [email, setEmail] = useState("");
    const [password, setPassWord] = useState("");
    const [termsAccepted, setTermsAccepted] = useState(false)
    const isSigningUpStatus = useAppSelector(state=>state.auth.isSigningUpStatus);
    const errorMessages = useAppSelector(state => state.auth.signUpErrors);
    const dispatch = useAppDispatch();
    const navigate = useNavigate();

    const signUpUser= ()=>{
        if (firstname ==='' || lastname ==='' || email==='' || password ==='') {
            toast.error("All Fields are required", {
                position: toast.POSITION.BOTTOM_LEFT
            });
            return;
        }
        if (termsAccepted ===false) {
            toast.error("Please accept the terms and conditions by clicking the checkbox", {
                position: toast.POSITION.BOTTOM_LEFT
            });
            return;
        }
        if (!validatePassword(password)) {
            toast.error("Password must have a number, a special character (e.g @,#, etc), a capital letter, and at least 8 characters long", {
                position: toast.POSITION.BOTTOM_LEFT
            });
            return;
        }
        dispatch(registerUser({
            firstname:firstname,
            lastname:lastname,
            emailOrPhone:email,
            password:password
        }))
    }

    useEffect(()=>{
        console.log("4a")
        if (isSigningUpStatus === APP_STATUS.ERROR) {
            for (const errorMessage of errorMessages) {
                toast.error(errorMessage, {
                    position: toast.POSITION.BOTTOM_LEFT
                });
            }
        }else if(isSigningUpStatus === APP_STATUS.SUCCESS){
            localStorage.setItem('lastOtpTime', moment().toISOString()); 
            navigate("/verify-account?email="+email)
        }
    },[isSigningUpStatus,errorMessages])

   

    return (
        <div className=" flex items-center justify-center min-h-screen">
            <ToastContainer />
            <div className=" w-full flex justify-center px-2 py-2">
                <div className="card w-4/6 sm:w-full md:w-full lg:w-full xl:w-4/6 2xl:w-4/6 3xl:w-4/6 shadow-lg rounded-none">
                    <div className=" card-body grid  sm:grid-cols-1 md:grid-cols-1 lg:grid-cols-1 xl:grid-cols-2 2xl:grid-cols-2 3xl:grid-cols-2 p-0">
                        <div>
                            <div className=" sm:h-14 md:h-14 lg:h-14 xl:h-28 2xl:h-28 3xl:h-28  flex justify-center">
                            </div>
                            <div className="flex justify-center pb-10">
                                <div className="w-4/5 flex justify-center ">
                                    <div>
                                        <div className=" h-8 flex flex-col justify-center">
                                            <span className="font-bold text-lg">Tell us about your self</span>
                                        </div>
                                        <div className="mt-4">
                                            <img src={Group_37} alt="" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className=" bg-prim-color pb-10">
                            <div className=" h-28 flex justify-center">
                                <img width={80} src={Logo} alt="" />
                            </div>
                            <div className=" h-8 flex flex-col justify-center">
                                <div className="flex items-center px-16 ">
                                    <div className=" flex-grow border-t-[1px] border-gray-400 border-opacity-60"></div>
                                    <div className="mx-4 text-white opacity-80 text-xs">Sign up</div>
                                    <div className="flex-grow  border-t-[1px] border-gray-400 border-opacity-60"></div>
                                </div>
                            </div>
                            <div className=" w-full mt-5">
                                <div className=" grid grid-cols-2 gap-4 px-5">
                                    <div className="">
                                        <label htmlFor="email" className="form-label-1">First name</label>
                                        <input  value={firstname} onChange={(e)=>setFirstName(e.target.value)} type="text" id="email" className="form-input-1" placeholder="" required/>
                                    </div>
                                    <div className="">
                                        <label htmlFor="email" className="form-label-1">Last name</label>
                                        <input value={lastname} onChange={(e)=>setLastName(e.target.value)}  type="text" id="email" className="form-input-1" placeholder="" required/>
                                    </div>
                                </div>
                            </div>
                            <div className=" w-full mt-4 px-5">
                                <label htmlFor="email" className="form-label-1">Email</label>
                                <div className="relative">
                                    <div className="absolute inset-y-0 left-0 flex items-center  pl-[8px] pointer-events-none">
                                        <svg aria-hidden="true" className="form-input-1-icon " fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path d="M2.003 5.884L10 9.882l7.997-3.998A2 2 0 0016 4H4a2 2 0 00-1.997 1.884z"></path><path d="M18 8.118l-8 4-8-4V14a2 2 0 002 2h12a2 2 0 002-2V8.118z"></path></svg>
                                    </div>
                                    <input value={email} onChange={(e)=>setEmail(e.target.value)}  type="email"  className="form-input-1 pl-10" />
                                </div>
                            </div>
                            <div className=" w-full mt-4 px-5">
                                <label htmlFor="email" className="form-label-1">Password</label>
                                <PasswordInput getPasswordValue={(password)=>setPassWord(password)}/>
                            </div>
                            <div className="w-full mt-4 px-5  custom-check">
                                <input onChange={(e)=> setTermsAccepted(e.target.checked)} type="checkbox" id="checkbox1"/>
                                <label htmlFor="checkbox1" className="form-label-1 text-xs ">
                                By signing up, you agree to the Terms of Service and Privacy Policy, including Cookie use
                                </label>
                           </div>

                            <div className="w-full mt-4 px-5">
                                <AppButton callBackFun={()=>signUpUser()} showLoader={isSigningUpStatus===APP_STATUS.PENDING}  spinnerClass="inline w-3 h-3 mr-2 text-prim-yellow animate-spin fill-black" className="  prim-btn-login" text="Continue" />
                            </div>
                          
                            <div className="w-full mt-4 px-5 text-center">
                               <span className=" text-xs"> <span className="text-white opacity-80">Already have an account? </span> 
                               <Link className=" text-prim-yellow hover:text-prim-green" to="/">Sign In</Link>
                               </span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
} 

export default SignUpPage;