import NoData from "../../assets/img/nodata_green.svg"
import walletIcon from '../../assets/img/Wallaet_icon.svg'
import addToWalletIcon from '../../assets/img/add_to_wallet.svg'
import ProfileProgress from "../elements/ProfileProgress";
import LineChart, { ChartSerie } from "../elements/LineChart";
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import { selectAccessToken, selectActiveEnvironment, selectNtumaAccount, sessionExpired } from '../../features/auth/authSlice';
import { ACTIVE_ENV, BASE_URL, TypeCollection } from '../../helpers/constants';
import { useEffect, useState } from 'react';
import useApiRequest from '../../helpers/api_request';
import AppSpinner from '../elements/AppSpinner';
import { formatDate } from '../../helpers/commons';
import { useNavigate } from "react-router-dom";
import Doughnut, { TypeDoughnutData } from "../elements/Doughnut";

const ReportsPage =()=>{
    const ntumaAccount = useAppSelector(selectNtumaAccount);
    const accessToken = useAppSelector(selectAccessToken)
    const [chartSeries, setChartSeries] = useState<ChartSerie[]>([]);
    const [chartCategories, setChartCategories] = useState<string[]>([]);
    const [loadingStats, setLoadingStats] = useState(false);
    const [doughnutData, setDoughnutData] = useState<TypeDoughnutData[]>([])
    const [doughnutTitle, setdoughnutTitle] = useState<string>("")

    const apiRequest = useApiRequest();
    const navigate = useNavigate();

    useEffect(()=>{
        console.log("5a")
        if (ntumaAccount) {
            fetDashboardStats();
        }
    },[ntumaAccount])

    const fetDashboardStats = async()=>{
        setLoadingStats(true);
        const response = await apiRequest({
            method: 'get',
            url: BASE_URL+"/api/reports/dashboard-stats",
            headers: {
              Authorization: `Bearer ${accessToken}`,
            },
          }).then((response)=>{
            setChartSeries([{name: 'Recieved', data: response.data.data}])
            setChartCategories(response.data.keys)
            setLoadingStats(false);

            const mtnSum:number = response.data.groupSumByChannel.MTN_UG
            const airtelSum:number =response.data.groupSumByChannel.AIRTEL
            const total = (mtnSum)?mtnSum:0+(airtelSum)?airtelSum:0;
            const data = [
                {"name": "MTN", "y":response.data.groupSumByChannel.MTN_UG, "color":"#4EB96999" },
                {"name": "AIRTEL", "y":response.data.groupSumByChannel.AIRTEL, "color":"#7C66FF"}]
            setDoughnutData(data)
            setdoughnutTitle(" Collections <br> "+total.toLocaleString())
          }).catch((error)=>{
            setLoadingStats(false);
          });   
    }


    return (
        <div>
           
            <div className="">
                <div className="card ">
                    <div className="card-body ">
                        <h5 className="text-gray-700 card-title">Collections</h5>
                        <div className="grid grid-cols-3 gap-4">
                            <div className="col-span-2">
                                <div className="mt-4">
                                    {(loadingStats)&& <AppSpinner/>}
                                    <LineChart categories={chartCategories} series={chartSeries} /> 
                                </div>
                            </div>
                            <div>
                                <Doughnut data={doughnutData} title={doughnutTitle}/>
                            </div>
                        </div>
                       
                       
                    </div>
                </div>
           
            </div>
        </div>
    );
}

export default ReportsPage;