import { EntityState, createAsyncThunk, createEntityAdapter, createSlice } from "@reduxjs/toolkit";
import { APP_STATUS, BASE_URL, TypeAppStatus, TypeSettlemetStatus } from "../../helpers/constants";
import { selectAccessToken } from "../auth/authSlice";
import { RootState } from "../../app/store";
import { handleSessionExpiration } from "../../helpers/commons";
import axios, { AxiosError } from "axios";



type TypeStaff ={
    ntuma_account_id:number,
    user_id:number,
    creator_id:number,
    email:string,
    staff_role:string,
    created_at:string ,
    user?:{
        firstname:string,
        lastname:string,
        email:string
    }
}




interface StaffState extends EntityState<TypeStaff>{
    loadingStaffStatus:TypeAppStatus,
    lastCursor:string|null,
    nextCursor:string|null,
    totalItems:number|null
    totalPages:number,
    cursorReset:boolean
}

const staffAdapter = createEntityAdapter<TypeStaff>()
const initialState:StaffState = staffAdapter.getInitialState({
    loadingStaffStatus:"idle",
    lastCursor:null,
    nextCursor:null,
    totalItems:null,
    totalPages:0,
    cursorReset:true
})

export const fetchStaff = createAsyncThunk("auth/fetchStaff", async(_,{getState,rejectWithValue,dispatch})=>{
    try {
     const auth_token = selectAccessToken(getState() as RootState);
     const nextCursor = selectNextCursor(getState() as RootState)
     let dataUrl = "";
     if (nextCursor ==null) {
         dataUrl = "/api/staff/list"
     }else{
         dataUrl="/api/staff/list?cursor="+nextCursor
     }
     const response = await axios.get(BASE_URL+dataUrl,{
         headers: {
             Authorization: 'Bearer '+auth_token,
         }
     })
     return response.data.data
    } catch (error) {
         const errorResponse = error as AxiosError;
         handleSessionExpiration(errorResponse,dispatch);
         if (errorResponse.response) {
             const data = errorResponse.response.data as {message:string, status:string};
             return rejectWithValue(data.message);
         }else{
             return rejectWithValue("Failed. Something went wrong");
         }
    }
 })


const staffSlice = createSlice({
    initialState,
    name:"staff",
    reducers:{
        resetCursorStaff(state){
            state.nextCursor = null
            state.lastCursor =null
            state.cursorReset = true
        },
    },
    extraReducers:(builder)=>{
        builder
        .addCase(fetchStaff.pending,(state,action)=>{
            state.loadingStaffStatus = APP_STATUS.PENDING
        })
        .addCase(fetchStaff.fulfilled,(state,action)=>{
            state.loadingStaffStatus = APP_STATUS.SUCCESS
            if (state.cursorReset) {
                staffAdapter.removeAll(state)
            }
            staffAdapter.addMany(state,action.payload.items)
            state.lastCursor = action.payload.lastCursor
            state.nextCursor = action.payload.nextCursor
            state.totalItems = action.payload.totalItems
            state.totalPages = action.payload.totalPages
            state.cursorReset = false
        })
        .addCase(fetchStaff.rejected,(state,action)=>{
            state.loadingStaffStatus = APP_STATUS.ERROR
        })
    }
})
export default staffSlice.reducer

export const {
    resetCursorStaff
} = staffSlice.actions

export const {
    selectAll: selectStaff,
    selectById: selectStaffById,
  } = staffAdapter.getSelectors((state:RootState) => state.staff)

export const selectNextCursor = (state: RootState) => state.staff.nextCursor
