import NoData from "../../assets/img/nodata_green.svg"
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { useEffect, useState } from "react";
import { formatDate } from "../../helpers/commons";
import { selectNtumaAccount } from "../../features/auth/authSlice";
import AppSpinner from "../elements/AppSpinner";
import { APP_STATUS } from "../../helpers/constants";
import { fetchCustomers, resetCursorCustomers, selectCustomers } from "../../features/customers/customersSlice";
const CustomersPage = ()=>{
    const dispatch = useAppDispatch()
    const customers = useAppSelector(selectCustomers)
    const ntumaAccount = useAppSelector(selectNtumaAccount);
    const loadingCustomersStatus = useAppSelector(state=>state.customers.loadingCustomersStatus)
    const [currentPage, setCurrentPage] = useState(1);
    const totalPages = useAppSelector(state=>state.customers.totalPages)

    useEffect(()=>{
      dispatch(resetCursorCustomers())
      dispatch(fetchCustomers())
    },[ntumaAccount])

    return (
        <div>
              <div className="card mb-12">
                
                <div className="card-body flex flex-col px-4 py-4 min-h-half-screen pb-16">
                  <div className="flex">
                    <nav className="flex justify-between " aria-label="Breadcrumb">
                      <ol className="inline-flex items-center mb-3 sm:mb-0">
                        <li>
                          <div className="flex items-center">
                            <h5 className=" card-title ">Customers</h5>
                          </div>
                        </li>
                       
                      </ol>
                    </nav>
                  
                  </div>
                  {
                    (customers.length===0 && loadingCustomersStatus != APP_STATUS.PENDING)&&
                    <div  className=" h-fit flex-grow flex flex-col justify-center ">
                      <div className="flex justify-center">
                          <div>
                              <img className=" w-32" src={NoData} alt=""  />
                              <p>No Customers Yet!</p>
                          </div>
                      </div>
                    </div>
                  }
                  {
                    (customers.length>0)&&
                    <table className="w-full text-sm text-left text-gray-500 dark:text-gray-400">
                      <thead>
                          <tr>
                              <th scope="col" >
                                  Name
                              </th>
                              <th scope="col" >
                                  Email
                              </th>
                              <th scope="col" >
                                  Phone
                              </th>
                              <th scope="col" >
                                  Created
                              </th>
                          </tr>
                      </thead>
                      <tbody>
                        {
                            customers.map((item,key)=>
                                <tr key={key}>
                                    <td>{item.names?item.names:"n/a"}</td>
                                    <td>{item.email?item.email:"n/a"}</td>
                                    <td>{item.phone?item.phone:"n/a"}</td>
                                    <td>  { formatDate(item.created_at,"MMM DD, YYYY H:mm")}</td>
                                </tr>
                            )
                        }
                      </tbody>
                    </table>
                  }
                 
                  {(loadingCustomersStatus === APP_STATUS.PENDING)&& <AppSpinner/>}
                  {
                    (currentPage < totalPages )&&
                    <div  className=" flex justify-center mt-16">
                      <button onClick={()=> {
                        setCurrentPage(currentPage+1)
                        dispatch(fetchCustomers())
                      }} type="button" className="btn-light text-gray-600 ">
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" className=" text-gray-600" viewBox="0 0 24 24"><path fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M14 16h5v5M10 8H5V3m14.418 6.003A8 8 0 0 0 5.086 7.976m-.504 7.021a8 8 0 0 0 14.331 1.027"/></svg>
                        <span className="pl-1">Load More</span>
                      </button>
                    </div>
                  }
                 
                 


                </div>
              </div>
              
        </div>
    )
}
export default CustomersPage;

