import Logo from "../../assets/img/ntuma_logo.svg"
import Group_37 from "../../assets/img/Group_37.svg"
import PasswordInput from "../elements/PasswordInput";
import { Link, Navigate, useNavigate } from "react-router-dom";
import { createContext, useContext, useEffect, useRef, useState } from "react";
import AppButton from "../elements/AppButton";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { APP_STATUS, BASE_URL } from "../../helpers/constants";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { validatePassword } from "../../helpers/commons";
import moment from "moment";
import CircleCounter, { CircleCounterFunctions } from "../elements/CircleCounter";
import PinInput from "../elements/PinInput";
import axios, { AxiosError } from "axios";
import { loginVerification } from "../../features/auth/authSlice";

type LoginContextDataType= {
    showOtpForm: boolean,
    setShowOtpForm: React.Dispatch<React.SetStateAction<boolean>>
    email:string,
    setEmail: React.Dispatch<React.SetStateAction<string>>
    password:string
    setPassWord: React.Dispatch<React.SetStateAction<string>>
}
const LoginContext = createContext<LoginContextDataType | null>(null)

const LoginPage =()=>{
    const [showOtpForm, setShowOtpForm] = useState(false);
    const [email, setEmail] = useState("");
    const [password, setPassWord] = useState("");
    const data ={ showOtpForm,setShowOtpForm,email, setEmail,password,setPassWord}
    return(
       <LoginContext.Provider value={data}>
            <div>
                <div className=" flex items-center justify-center min-h-screen">
                    <ToastContainer />
                    <div className=" w-full flex justify-center px-2 py-2">
                        <div className="card w-4/6 sm:w-full md:w-full lg:w-full xl:w-4/6 2xl:w-4/6 3xl:w-4/6 shadow-lg rounded-none">
                            <div className=" card-body grid  sm:grid-cols-1 md:grid-cols-1 lg:grid-cols-1 xl:grid-cols-2 2xl:grid-cols-2 3xl:grid-cols-2 p-0">
                                <div>
                                    <div className=" sm:h-14 md:h-14 lg:h-14 xl:h-28 2xl:h-28 3xl:h-28  flex justify-center">
                                    </div>
                                    <div className="flex justify-center pb-10">
                                        <div className="w-4/5 flex justify-center ">
                                            <div>
                                                <div className=" h-8 flex flex-col justify-center">
                                                    <span className="font-bold text-lg">Sign in to your account</span>
                                                </div>
                                                <div className="mt-4">
                                                    <img src={Group_37} alt="" />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className=" bg-prim-color pb-10">
                                    <div className=" h-28 flex justify-center">
                                        <img width={80} src={Logo} alt="" />
                                    </div>
                                    <div className=" h-8 flex flex-col justify-center">
                                        <div className="flex items-center px-16 ">
                                            <div className=" flex-grow border-t-[1px] border-gray-400 border-opacity-60"></div>
                                            <div className="mx-4 text-white opacity-80 text-xs">Sign in</div>
                                            <div className="flex-grow  border-t-[1px] border-gray-400 border-opacity-60"></div>
                                        </div>
                                    </div>
                                
                                    {(showOtpForm)?<LoginOtpForm/>:<LoginForm/>}
                                
                                    <div className="w-full mt-4 px-5 text-center">
                                        <span className=" text-xs"> <span className="text-white opacity-80">Don't have an account? </span> 
                                            <Link className=" text-prim-yellow hover:text-prim-green" to="/signup">Sign Up</Link>
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
       </LoginContext.Provider>
    );
}

const LoginOtpForm =()=>{
    const searchParams = new URLSearchParams(window.location.search);
    const [counterFinished, setCounterFinished] = useState(false);
    const [isResending, setIsResending] = useState(false)
    const [otpCode, setOtpCode] = useState("");
    const counterRef = useRef<CircleCounterFunctions>(null);
    const context= useContext(LoginContext);
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const isVerifyingLogin = useAppSelector(state=> state.auth.isVerifyingLogin)
    const errorMessages = useAppSelector(state => state.auth.loginErrors);

    const verifyLogin =()=>{
        if (context?.email) {
            dispatch(loginVerification({email_or_phone:context?.email,code:otpCode}))
        }
    }

    useEffect(()=>{
        console.log("3a")
        if (isVerifyingLogin === APP_STATUS.ERROR) {
            for (const errorMessage of errorMessages) {
                toast.error(errorMessage, {
                    position: toast.POSITION.BOTTOM_LEFT
                });
            }
        }else if(isVerifyingLogin === APP_STATUS.SUCCESS){
            console.log("Logged In")
            navigate("/dashboard")
        }

    },[isVerifyingLogin])

    const resendOtp = async()=>{
        if (context?.email ==="") {
            toast.error("Email is Required", {
                position: toast.POSITION.TOP_RIGHT
            });
            return;
        }
        if (context?.password ==="") {
            toast.error("Password is Required", {
                position: toast.POSITION.TOP_RIGHT
            });
            return;
        }
        setIsResending(true);
        try {
            const response = await axios({
                method: 'post',
                url: BASE_URL+"/api/login",
                data:{
                    emailOrPhone: context?.email,
                    password: context?.password,
                }
            })

            if (response.data.status =="success") {
                toast.success("OTP Sent", {
                    position: toast.POSITION.TOP_RIGHT
                });
                localStorage.setItem('lastOtpTime', moment().toISOString());
                counterRef.current?.startProgress(); 
                setCounterFinished(false)
            }else{
                toast.error("Something went wrong", {
                    position: toast.POSITION.TOP_RIGHT
                });
            }
            setIsResending(false);
        } catch (error) {
            const errorResponse = error as AxiosError;
            if (errorResponse.response) {
                const data = errorResponse.response.data as {message:string, status:string};
                toast.error(data.message, {
                    position: toast.POSITION.TOP_RIGHT
                });
            }else{
                toast.error("Something went wrong", {
                    position: toast.POSITION.TOP_RIGHT
                });
            }
            setIsResending(false);
        }

    }

    return (
        <>
            <div className="w-full flex justify-center mt-4 px-5 h-24 ">
                <div className=" w-24">
                    <CircleCounter ref={counterRef} onProgressFinished={()=>setCounterFinished(true)}/>
                </div>
            </div>
            <div className="w-full mt-4 px-5">
                <label className="form-label-1 text-center text-white mb-4">
                    {(!counterFinished)?
                    <span>We emailed you a six-digit code to <span className=" text-prim-green"> {context?.email}</span>.Enter the code below to confirm your identity</span>
                        :
                    (!isResending)? <span>Session timed out. <a onClick={()=>resendOtp()} className=" text-prim-green" href="#!">Resend Code?</a></span> : <span>Sending please wait...</span>
                    
                    }
                    
                </label>
                <PinInput getValue={(otp)=>setOtpCode(otp)} className="form-input-1 text-center"/>
                <div className="mt-2">
                <small className=" text-gray-500 text-xs ">Make sure to keep this window open while you check your inbox</small>
                </div>
            </div>
            <div className="w-full mt-4 px-5">
                <AppButton callBackFun={()=>verifyLogin()} showLoader={isVerifyingLogin ==APP_STATUS.PENDING }  spinnerClass="inline w-3 h-3 mr-2 text-prim-yellow animate-spin fill-black" className="  prim-btn-login" text="Verify" />
            </div>
        </>
    );
}

const LoginForm =  ()=>{
    const context= useContext(LoginContext);
    const [loggingIn, setLoggingIn] = useState(false);
    const hasRestPassword = useAppSelector(state=> state.auth.hasRestPassword)

    const loginUser = async()=>{
        if (context?.email ==="") {
            toast.error("Email is Required", {
                position: toast.POSITION.TOP_RIGHT
            });
            return;
        }
        if (context?.password ==="") {
            toast.error("Password is Required", {
                position: toast.POSITION.TOP_RIGHT
            });
            return;
        }
        setLoggingIn(true);
        try {
            const response = await axios({
                method: 'post',
                url: BASE_URL+"/api/login",
                data:{
                    emailOrPhone: context?.email,
                    password: context?.password,
                }
            })

            if (response.data.status =="success") {
                context?.setShowOtpForm(true);
                localStorage.setItem('lastOtpTime', moment().toISOString());
            }else{
                toast.error("Something went wrong", {
                    position: toast.POSITION.TOP_RIGHT
                });
            }
            setLoggingIn(false);
        } catch (error) {
            const errorResponse = error as AxiosError;
            if (errorResponse.response) {
                const data = errorResponse.response.data as {message:string, status:string};
                toast.error(data.message, {
                    position: toast.POSITION.TOP_RIGHT
                });
            }else{
                toast.error("Something went wrong", {
                    position: toast.POSITION.TOP_RIGHT
                });
            }
            setLoggingIn(false);
        }

    }

    return (
        <>
            {(hasRestPassword)&&
             <div className="flex w-full mt-4 px-5">
                <div className="px-4 bg-prim-green flex flex-col justify-center text-center">
                    <svg xmlns="http://www.w3.org/2000/svg"  className=" text-white " width="24" height="24" viewBox="0 0 24 24"><path fill="currentColor" d="M21 7L9 19l-5.5-5.5l1.41-1.41L9 16.17L19.59 5.59L21 7Z"/></svg>
                </div>
                <div className=" bg-white py-2 flex-grow pl-2">
                    <span className="  text-prim-green">Your password has been reset</span>
                </div>
            </div>
            }
           
            <div className=" w-full mt-4 px-5">
                <label htmlFor="email" className="form-label-1">Email</label>
                <div className="relative">
                    <div className="absolute inset-y-0 left-0 flex items-center  pl-[8px] pointer-events-none">
                        <svg aria-hidden="true" className="form-input-1-icon " fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path d="M2.003 5.884L10 9.882l7.997-3.998A2 2 0 0016 4H4a2 2 0 00-1.997 1.884z"></path><path d="M18 8.118l-8 4-8-4V14a2 2 0 002 2h12a2 2 0 002-2V8.118z"></path></svg>
                    </div>
                    <input value={context?.email} onChange={(e)=>context?.setEmail(e.target.value)}  type="email"  className="form-input-1 pl-10" />
                </div>
            </div>
            <div className=" w-full mt-4 px-5">
                <div className="flex">
                    <label htmlFor="email" className="form-label-1 flex-grow">Password</label>
                    <Link to="/reset-password"  className="form-label-1 text-prim-yellow text-xs hover:text-prim-green">Forgot your Password?</Link>
                </div>
                <PasswordInput getPasswordValue={(password)=>context?.setPassWord(password)}/>
            </div>
            <div className="w-full mt-4 px-5  custom-check">
                <input  type="checkbox" id="checkbox1"/>
                <label htmlFor="checkbox1" className="form-label-1 text-xs ">
                    Remember me
                </label>
            </div>

            <div className="w-full mt-4 px-5">
                <AppButton callBackFun={()=>loginUser()} showLoader={loggingIn}  spinnerClass="inline w-3 h-3 mr-2 text-prim-yellow animate-spin fill-black" className="  prim-btn-login" text="Continue" />
            </div>
        </>
    );
} 

export default LoginPage;