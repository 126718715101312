export enum APP_STATUS {
    IDLE= "idle",
    PENDING= "pending",
    ERROR= "error",
    SUCCESS= "success",
}
export type TypeAppStatus ="idle" | "pending" | "error" | "success";
export const BASE_URL = process.env.REACT_APP_API_URL;

export type TypeCollectionStatus = "pending" |"successful"|"failed"

export enum COLLECTION_STATUS {
    PENDING= "pending",
    SUCCESSFUL= "successful",
    FAILED= "failed",
}

export enum SETTLEMENT_STATUS {
    PENDING="pending",
    SUCCESSFUL ="successful",
    REJECTED ="rejected"
}
export type TypeSettlemetStatus = "pending" |"successful"|"rejected"

export type TypeBusinessDetails ={
    business_name:string,
    business_industry:string,
    business_email:string,
    business_phone:string,
    reg_date:string,
    country:string,
    city:string,
    address_1:string,
    address_2?:string,
    certificate_url?:string,
}
export type TypeBankDetail ={
    bank_name:string,
    account_number:string,
    account_name:string,
}

export type  TypeNtumaAccount ={
   id:number,
   user_id:number,
   account_name:string,
   is_approved: boolean,
   is_blocked: boolean,
   userWallet?:{
    current_balance: number,
    current_balance_sand_box:number
   },
   business_details?:TypeBusinessDetails,
   bank_details:TypeBankDetail[]
   companyBranding?: {
    id: number,
    ntuma_account_id: 1,
    company_logo?: string,
  },
  NtumaAccountFiles:{
    file_name:string,
    file_path:string
  }[]
}

export enum ACTIVE_ENV {
    test= "test",
    production= "production",
}
export type TypeActiveEnviroment = "test"|"production";

export type TypeApiKey ={
    production_key: string,
    test_key: string,
    encryption_key: string,
    active_environment: TypeActiveEnviroment
}

export type TypeCollection ={
    id:number,
    customer_id:number,
    transaction_id:string
    msisdn:string,
    msisdn_carrier:string,
    amount: number,
    currency:string,
    narration?:string
    external_reference?:string
    status:string
    created_at?: string
}

export type TypeDispatchResponse ={
    error?:any
    payload?:any
}

export type TypeWallet ={
    current_balance:number,
    current_balance_sand_box:number
}