
import Logo from "../../assets/img/ntuma_logo.svg"
import ChairImage from "../../assets/img/login_chair_icon.svg"
import { Link, useNavigate } from "react-router-dom";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import CircleCounter, { CircleCounterFunctions } from "../elements/CircleCounter";
import PinInput from "../elements/PinInput";
import AppButton from "../elements/AppButton";
import { useRef, useState } from "react";
import axios, { AxiosError } from "axios";
import { BASE_URL } from "../../helpers/constants";
import moment from "moment";

const VerifyAccount =()=>{
    let email:string|null ="";
    const searchParams = new URLSearchParams(window.location.search);
    email = searchParams.get('email');
    const [counterFinished, setCounterFinished] = useState(false);
    const [isVerifying, setIsVerifying] = useState(false)
    const [isResending, setIsResending] = useState(false)
    const [otpCode, setOtpCode] = useState("");
    const counterRef = useRef<CircleCounterFunctions>(null);
    const navigate = useNavigate();

    const resendOtp= async() =>{
        setIsResending(true);
        try {
            const response = await axios({
                method: 'post',
                url: BASE_URL+"/api/resend-account-verification-code",
                data:{
                    email_or_phone: email,
                }
            })
            setIsResending(false);
            toast.success("OTP Sent", {
                position: toast.POSITION.TOP_RIGHT
            });
            localStorage.setItem('lastOtpTime', moment().toISOString());
            counterRef.current?.startProgress(); 
            setCounterFinished(false)

        } catch (error) {
            setIsResending(false);
            toast.error("Something went wrong", {
                position: toast.POSITION.TOP_RIGHT
            });
        }
    }

    const verifyOtp = async()=>{
        if (otpCode ==="") {
            toast.error("OTP is Required", {
                position: toast.POSITION.TOP_RIGHT
            });
            return;
        }
        setIsVerifying(true);
        try {
            const response = await axios({
                method: 'post',
                url: BASE_URL+"/api/verify-account",
                data:{
                    email_or_phone: email,
                    code: otpCode,
                }
            })
            setIsVerifying(false);
            toast.success("Account Verified", {
                position: toast.POSITION.TOP_RIGHT
            });
            navigate("/")
        } catch (error) {
            const errorResponse = error as AxiosError;
            if (errorResponse.response) {
                const data = errorResponse.response.data as {message:string, status:string};
                toast.error(data.message, {
                    position: toast.POSITION.TOP_RIGHT
                });
            }else{
                toast.error("Something went wrong", {
                    position: toast.POSITION.TOP_RIGHT
                });
            }
            setIsVerifying(false);
          
        }
    }
        
    return (
        <div className="flex items-center justify-center min-h-screen">
            <ToastContainer />
            <div className=" w-full flex justify-center px-2 py-2">
                <div className="card w-4/6 sm:w-full md:w-full lg:w-full xl:w-4/6 2xl:w-4/6 3xl:w-4/6 shadow-lg rounded-none">
                    <div className=" card-body grid  sm:grid-cols-1 md:grid-cols-1 lg:grid-cols-1 xl:grid-cols-2 2xl:grid-cols-2 3xl:grid-cols-2 p-0">
                        <div>
                            <div className="sm:h-14 md:h-14 lg:h-14 xl:h-28 2xl:h-28 3xl:h-28  flex justify-center">
                            </div>
                            <div className="flex justify-center pb-10">
                                <div className="w-4/5 flex justify-center ">
                                   <div>
                                    <div className=" h-8 flex flex-col justify-center">
                                            <span className="font-bold text-lg">Verify your account</span>
                                        </div>
                                        <div className="mt-4">
                                            <img src={ChairImage} alt="" />
                                        </div>
                                   </div>
                                </div>
                            </div>
                        </div>
                        <div className=" bg-prim-color pb-10">
                            <div className=" h-28 flex justify-center">
                                <img width={80} src={Logo} alt="" />
                            </div>
                            <div className=" h-8 flex flex-col justify-center">
                                <div className="flex items-center px-16 ">
                                    <div className=" flex-grow border-t-[1px] border-gray-400 border-opacity-60"></div>
                                    <div className="mx-4 text-white opacity-80 text-xs">Verify</div>
                                    <div className="flex-grow  border-t-[1px] border-gray-400 border-opacity-60"></div>
                                </div>
                            </div>
                           
                            <div className="w-full flex justify-center mt-4 px-5 h-24 ">
                                <div className=" w-24">
                                    <CircleCounter ref={counterRef} onProgressFinished={()=>setCounterFinished(true)}/>
                                </div>
                            </div>
                            <div className="w-full mt-4 px-5">
                                <label className="form-label-1 text-center text-white mb-4">
                                  {(!counterFinished)?
                                    <span>We emailed you a six-digit code to <span className=" text-prim-green"> {email}</span>. Enter the code below to confirm your email address.</span>
                                      :
                                    (!isResending)? <span>Session timed out. <a onClick={()=>resendOtp()} className=" text-prim-green" href="#!">Resend Code?</a></span> : <span>Sending please wait...</span>
                                    
                                  }
                                  
                                </label>
                                <PinInput getValue={(otp)=>setOtpCode(otp)} className="form-input-1 text-center"/>
                            </div>
                            <div className="w-full mt-4 px-5">
                                <AppButton callBackFun={()=>verifyOtp()} showLoader={isVerifying}  spinnerClass="inline w-3 h-3 mr-2 text-prim-yellow animate-spin fill-black" className="  prim-btn-login" text="Continue" />
                            </div>
                        
                            <div className="w-full mt-4 px-5 text-center">
                               <span className=" text-xs"> <span className="text-white opacity-80">Already have an account? </span> 
                               <Link className=" text-prim-yellow hover:text-prim-green" to="/">Sign In</Link>
                               </span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
    
}

export default VerifyAccount;