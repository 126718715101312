import NoData from "../../assets/img/nodata_green.svg"
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { useEffect, useState } from "react";
import { formatDate } from "../../helpers/commons";
import { selectAccessToken, selectNtumaAccount } from "../../features/auth/authSlice";
import AppSpinner from "../elements/AppSpinner";
import { APP_STATUS, BASE_URL, SETTLEMENT_STATUS } from "../../helpers/constants";
import { fetchSettlements, resetCursorSettlements, selectSettlements } from "../../features/transactions/settlementsSlice";
import MoneyInput from "../elements/MoneyInput";
import AppButton from "../elements/AppButton";
import { showToastMessage } from "../../features/generalSlice";
import { toast } from "react-toastify";
import useApiRequest from "../../helpers/api_request";
import { AxiosError } from "axios";
const SettlementsPage = ()=>{
    const dispatch = useAppDispatch()
    const settlements = useAppSelector(selectSettlements)
    const ntumaAccount = useAppSelector(selectNtumaAccount);
    const loadingSettlementsStatus = useAppSelector(state=>state.settlements.loadingSettlementsStatus)
    const [currentPage, setCurrentPage] = useState(1);
    const totalPages = useAppSelector(state=>state.settlements.totalPages)
    const [requestModalOpen, setRequestModalOpen] = useState(false)

    useEffect(()=>{
      dispatch(resetCursorSettlements())
      dispatch(fetchSettlements())
    },[ntumaAccount])

    return (
        <div>
              <div className="card mb-12">
                
                <div className="card-body flex flex-col px-4 py-4 min-h-half-screen pb-16">
                  <div className="flex">
                    <nav className="flex justify-between " aria-label="Breadcrumb">
                      <ol className="inline-flex items-center mb-3 sm:mb-0">
                        <li>
                          <div className="flex items-center">
                            <h5 className=" card-title ">Settlements</h5>
                          </div>
                        </li>
                       
                      </ol>
                    </nav>
                    <div className="flex-grow flex justify-end">
                      <button onClick={()=> setRequestModalOpen(true)} type="button" className="prim-btn-1 mb-2">Request Settlement</button>
                    </div>
                  </div>
                  {
                    (settlements.length===0 && loadingSettlementsStatus != APP_STATUS.PENDING)&&
                    <div  className=" h-fit flex-grow flex flex-col justify-center ">
                      <div className="flex justify-center">
                          <div>
                              <img className=" w-32" src={NoData} alt=""  />
                              <p>No Settlements Yet!</p>
                          </div>
                      </div>
                    </div>
                  }
                  {
                    (settlements.length>0)&&
                    <table className="w-full text-sm text-left text-gray-500 dark:text-gray-400">
                      <thead>
                          <tr>
                              <th scope="col" >
                                Amount
                              </th>
                              <th scope="col" >
                                Date
                              </th>
                              <th scope="col" >
                                Created
                              </th>
                              <th scope="col" >
                                Status
                              </th>
                          </tr>
                      </thead>
                      <tbody>
                        {
                            settlements.map((item,key)=>
                                <tr key={key}>
                                    <td>{item.amount.toLocaleString()}</td>
                                    <td>  { formatDate(item.created_at,"MMM DD, YYYY H:mm")}</td>
                                    <td>{item.creator.firstname} {item.creator.lastname}</td>
                                   
                                    <td>
                                      {item.status === SETTLEMENT_STATUS.SUCCESSFUL && 
                                        <span className="badge-success">{item.status}</span>}
                                      {item.status === SETTLEMENT_STATUS.REJECTED && 
                                        <span className="badge-failed">{item.status}</span>}
                                      {item.status === SETTLEMENT_STATUS.PENDING && 
                                        <span className="badge-pending">{item.status}</span>}    
                                    </td>
                                </tr>
                            )
                        }
                      </tbody>
                    </table>
                  }
                 
                  {(loadingSettlementsStatus === APP_STATUS.PENDING)&& <AppSpinner/>}
                  {
                    (currentPage < totalPages )&&
                    <div  className=" flex justify-center mt-16">
                      <button onClick={()=> {
                        setCurrentPage(currentPage+1)
                        dispatch(fetchSettlements())
                      }} type="button" className="btn-light text-gray-600 ">
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" className=" text-gray-600" viewBox="0 0 24 24"><path fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M14 16h5v5M10 8H5V3m14.418 6.003A8 8 0 0 0 5.086 7.976m-.504 7.021a8 8 0 0 0 14.331 1.027"/></svg>
                        <span className="pl-1">Load More</span>
                      </button>
                    </div>
                  }
                 
                 


                </div>
              </div>
              <SettlementModal settlemtSaved={()=>{
                  dispatch(resetCursorSettlements())
                  dispatch(fetchSettlements())
              }} isOpen={requestModalOpen} onClose={()=>setRequestModalOpen(false)}/>
        </div>
    )
}
export default SettlementsPage;

type TypeSettlementModalProps ={
  isOpen:boolean,
  onClose: ()=>void,
  settlemtSaved:()=>void
}
const SettlementModal =(props:TypeSettlementModalProps)=>{
  const ntumaAccount = useAppSelector(selectNtumaAccount);
  let walletBalance =0;
  if (ntumaAccount?.userWallet) {
    walletBalance= ntumaAccount?.userWallet?.current_balance;
  }
  const [amount,setAmount]= useState(0)
  const [isRequestingSettlement, setIsRequestingSettlement] = useState(false);
  const dispatch = useAppDispatch();
  const apiRequest = useApiRequest()
  const accessToken = useAppSelector(selectAccessToken);
 
  const sendRequest = async()=>{
    if (amount ===0 || amount > walletBalance) {
      dispatch(showToastMessage({
        message:"Invalid Amount",
        type:"error",
        position: toast.POSITION.TOP_CENTER
      }));
      return;
    }
    setIsRequestingSettlement(true)
    await apiRequest({
        method: 'post',
        url: BASE_URL+"/api/settlements/create",
        headers: {
            Authorization: `Bearer ${accessToken}`,
        },
        data:{
            amount:amount,
        }
    }).then((response)=>{
        setIsRequestingSettlement(false)
        dispatch(showToastMessage({
            message:"Request Sent",
            type:"success",
            position: toast.POSITION.TOP_RIGHT
        }));
        props.settlemtSaved()
        props.onClose()
    }).catch((error)=>{
        const errorResponse = error as AxiosError;
        // console.log(errorResponse.response)
        if (errorResponse.response) {
            const data = errorResponse.response.data as {message:string, status:string};
            dispatch(showToastMessage({
                message:data.message,
                type:"error",
                position: toast.POSITION.TOP_RIGHT
            }));
          
        }else{
            dispatch(showToastMessage({
                message:"Something went wrong",
                type:"error",
                position: toast.POSITION.TOP_RIGHT
            }));
        }
        setIsRequestingSettlement(false)
    }); 
  }

  return(
    <>
      {(props.isOpen)&&
        <div  className="modal ">
          <div className=" modal-content modal-md py-4">
            <div className=" flex justify-center">
              <span className="text-center text-lg">Request Withdraw</span>
            </div>

              <div className=" mt-4">
                    <label htmlFor="" className="block text-sm font-medium text-gray-700 ">Wallet</label>
                    <select name="" className="form-input-2" id="">
                        <option value="Fixed Charge">{ntumaAccount?.userWallet?.current_balance.toLocaleString()}</option>
                    </select>
              </div>
              <div className="mt-4">
                  <label htmlFor="amount" className="block text-sm font-medium text-gray-700 ">Amount</label>
                  <MoneyInput value={amount} onChange={(value)=>setAmount(value)} className="form-input-2"/>
              </div>
              {
                (amount>walletBalance)&&
                <span className=" text-red-600">Invalid Amount</span>
              }
              <div className="flex flex-row-reverse mt-10 pt-4 px-0  gap-2">
                    <div>
                        <AppButton callBackFun={()=>sendRequest()} showLoader={isRequestingSettlement}  spinnerClass="inline w-3 h-3 mr-2 text-prim-yellow animate-spin fill-black" className="prim-btn-2  " text="Send Request" />
                    </div>
                    <div>
                        <button onClick={()=>props.onClose()} type="button" className="btn-secondary-1 ">Close</button>
                    </div>
                </div>
          </div>
        </div>
      }
      
    </>
  )
}