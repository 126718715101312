import { forwardRef, useEffect, useImperativeHandle, useMemo, useRef, useState } from "react";
import logo from "../../assets/img/ntuma_logo_3.451cfbbf.svg"
import personIcon from "../../assets/img/profile.ea4f1c2e.svg"
import { Link, NavLink, Outlet, matchPath, useLocation, useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { changeAccountMode, deleteAuthTokens, getUserProfile, newNtumaAccount, selcetUserProfile, selectNtumaAccount, switchNtumaAccount } from "../../features/auth/authSlice";
import { ACTIVE_ENV, APP_STATUS, TypeDispatchResponse, TypeNtumaAccount } from "../../helpers/constants";
import { TypeToastData, showToastMessage } from "../../features/generalSlice";
import { toast } from "react-toastify";
import moment from "moment";
import AppSpinner from "../elements/AppSpinner";
import Swal from "sweetalert2";
import AppButton from "../elements/AppButton";
import { isLinkActive } from "../../helpers/commons";

const MainLayout =()=>{
    const ntumaAccount = useAppSelector(selectNtumaAccount);
    const activeEnvironment = useAppSelector(state=>state.auth.activeEnvironment)
    const dispatch = useAppDispatch()
    const [showLoadingModal, setShowLoadingModal] = useState(false);
    const isSwitchingModeStatus = useAppSelector(state=>state.auth.isSwitchingModeStatus)
    const drawerRef = useRef<DrowerFunctions>(null);

    const changeMode =(value:boolean)=>{
      
      let envMode ="";
      if (value) {
        envMode = ACTIVE_ENV.production
      }else{
        envMode = ACTIVE_ENV.test
      }
      Swal.fire({
        title: 'Confirm?',
        text: "Change mode to "+envMode,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Change mode'
      }).then((result) => {
        if (result.isConfirmed) {
            dispatch(changeAccountMode(envMode))
          .then((response: TypeDispatchResponse)=>{
            if (response.error) {
              dispatch(showToastMessage({
                message:response.payload,
                type:"error",
                position: toast.POSITION.TOP_RIGHT
              }))
            }
          });
        }
      })

      
    }   

    useEffect(() => { 
      if (isSwitchingModeStatus === APP_STATUS.PENDING) {
        setShowLoadingModal(true);
      } else {
        setShowLoadingModal(false);
      }
    }, [isSwitchingModeStatus]);
  
    return (
        <>
            <div className=" h-screen  bg-body-bg sm:min-w-desktop sm:max-w-desktop md:min-w-desktop md:max-w-desktop lg:min-w-desktop lg:max-w-full xl:min-w-full xl:max-w-full  overflow-auto">
              <nav className="bg-white py-4 border border-t-0 border-l-0 border-r-0 border-gray-100">
                <div className=" "> 
                    <div className="flex gap-6">
                      <div className="w-[270px] pl-edge-space">
                        <img className=" w-18" src={logo} alt=""  />
                      </div>
                      <div className="flex-grow grid grid-cols-5 ">
                        <div className=" col-span-3 flex flex-col justify-center ">
                          <h1 className="text-xl font-bold opacity-100">{ntumaAccount?.account_name}</h1>
                        </div>
                        <div className="col-span-2 flex flex-col justify-center pr-edge-space ">
                          <div className="flex flex-row-reverse gap-2 ">
                            <span onClick={()=> drawerRef.current?.openDrawer()} className="hover:bg-prim-green border border-gray-200 cursor-pointer rounded-md flex justify-center h-8">
                              <img className="w-5" src={personIcon} alt=""  />
                            </span>
                            <div className="relative w-2/3 ">
                                <input type="text" className="search-input" />
                                <div className="absolute inset-y-0 right-0 flex items-center px-2">
                                  <span className=" fa fa-search text-gray-400"></span>
                                </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      
                    </div>
                  </div>
              </nav>

              <div className="mt-6 h-max">
                <div className="flex gap-6 h-full ">
                  <div className=" bg-white w-[270px] min-w-[270px] border border-l-0 border-gray-100">
                    <div className="  min-h-screen flex flex-col">
                        <SideItems/>
                        <div className=" flex-grow flex flex-col justify-end pb-44  pt-4 ">
                          <div className="custom-toggle mx-edge-space">
                            <div>
                            <small className=" text-xs text-gray-600">Mode</small>
                            </div>
                            <div className="flex">
                              <label className="switch">
                                  <input onChange={(e)=> changeMode(e.target.checked)}  checked={activeEnvironment!="test"} type="checkbox"/>
                                  <span className="slider round"></span>
                              </label>
                              <div className="flex flex-col justify-start">
                                {activeEnvironment==="test"?
                                  <small className=" text-xs text-red-600 pl-2 opacity-90 font-bold">
                                      Test</small>:
                                <small className=" text-xs text-green-600 pl-2 opacity-90 font-bold">
                                            Live</small>
                                }
                              </div>
                            </div>
                          </div>
                        </div>
                    </div>
                  </div>
                  <div className="flex-grow pr-edge-space ">
                    <LoadingModal showModal={showLoadingModal}/>
                    {(ntumaAccount !==undefined)&&
                     <Outlet/>
                    }
                  </div>
                </div>
              </div>
              
              <Drawer ref={drawerRef} />
              
              <div>
                {
                  (activeEnvironment === ACTIVE_ENV.test)&&
                  <div className="flex left-1/2 transform -translate-x-1/2 p-3 mb-4 text-sm fixed bottom-0 z-[1] text-red-800 rounded-lg bg-red-50 border border-red-300" role="alert">
                    <div>
                      <svg aria-hidden="true" className="flex-shrink-0 inline w-5 h-5 mr-3" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7-4a1 1 0 11-2 0 1 1 0 012 0zM9 9a1 1 0 000 2v3a1 1 0 001 1h1a1 1 0 100-2v-3a1 1 0 00-1-1H9z" clipRule="evenodd"></path></svg>
                      <span className="sr-only">Info</span>
                    </div>
                    <div className="  flex flex-col justify-center">
                      <span className="font-medium">You're currently in test mode. <a  onClick={(e)=> changeMode(true)} className=" underline font-bold" href="#!">change?</a> </span>
                    </div>
                  </div>
                }
               
              </div>
            </div>
        </>
        
    );
}
export default MainLayout;

const SideItems =()=>{
  const location = useLocation();
  useEffect(()=>{

  },[location])
  const isActiveLink = (paths:string[]):string => {

    for (const to of paths) {
      const match = matchPath(location.pathname,to);
      if (match) {
        return "active";
      }
    } 
    return "";
  };
  return (
    <ul className=" ">
      <li> 
        <NavLink className="nav-link" to="/dashboard" >Overview</NavLink>
      </li>
      <li> 
        <NavLink className={"nav-link "+ (isActiveLink(["/new-payment-link"]))} to="/payment-links">Payment Links</NavLink>
      </li>
      <li> 
        <NavLink className={"nav-link "+ (isActiveLink(["/new-donation-link"]))} to="/donation-links">Donation Links</NavLink>
      </li>
      <li> 
        <NavLink className="nav-link" to="/collections" >Collections</NavLink>
      </li>
      <SideLink isActive={(isActiveLink(["/customers","/settlements"]))} title="My Business" subItems={[{text:"Customers",linkRoute:"/customers"}, {text:"Settlements",linkRoute:"/settlements"}]}  />

      <SideLink isActive={(isActiveLink(["/team","/profile","/business-details"]))} title="Account Management" subItems={[{text:"Profile",linkRoute:"/profile"},{text:"Business Details", linkRoute:"/business-details"} ,{text:"Team", linkRoute:"/team"}]}  />
      <li>
      <NavLink className="nav-link" to="/reports" >Reports</NavLink>
      </li>
      <SideLink isActive={(isActiveLink(["/api-keys","/webhooks"]))} title="Developers" subItems={[ {text:"API Keys", linkRoute:"/api-keys"}, {text:"Web Hooks",linkRoute:"/webhooks"}]}  />
    </ul>
  );
}

type AccountModalFunctions ={
  openModal:()=>void
}
const AccountModal =forwardRef<AccountModalFunctions>((_,ref)=>{
  const [isOpen, setIsOpen] = useState(false)
  const [newAccountForm, setNewAccountForm] = useState(false)
  const ntumaAccount = useAppSelector(selectNtumaAccount);

  useImperativeHandle(ref, () => ({
    openModal
  }));
  
  const openModal=()=>{
    setIsOpen(true)
  }

  useEffect(()=>{
    if (ntumaAccount == undefined) {
      setIsOpen(true);
    }    
  },[ntumaAccount])
 
  return(
    <>
      {(isOpen)&&
        <div  className="modal ">
          <div className={" modal-content pt-3 "+((newAccountForm)?'modal-md':'modal-lg')}>
            {(!newAccountForm)?
             <NtumaAccountsTable ntumaAccount={ntumaAccount} oneNewAccountsForm={()=>setNewAccountForm(true)}/>:
             <NewNtumaAccountForm/>
            }
            <div className=" flex justify-end">
              <button onClick={()=> {
                setIsOpen(false);
                setNewAccountForm(false)
              }} type="button" className="py-2.5 px-5 mr-2 mb-2 text-sm font-medium text-gray-900 focus:outline-none bg-white rounded-lg border border-gray-200 hover:bg-gray-100 hover:text-prim-green focus:z-10 focus:ring-4 focus:ring-gray-200 ">Close</button>
            </div>
          </div>
    
      </div>
      }
    </>
    
  );
})

const NewNtumaAccountForm =()=>{
  const [accountName,setAccountName] = useState("");
  const dispatch = useAppDispatch();
  const isSwitchingAccountStatus = useAppSelector(state=>state.auth.isSwitchingAccountStatus)

  const saveAccount =()=>{
 
    if (accountName ==="") {
      dispatch(showToastMessage({
        message:"Account name is missing",
        type:"error",
        position: toast.POSITION.TOP_RIGHT
      }));
      return
    }
    dispatch(newNtumaAccount(accountName))
    .then((response: TypeDispatchResponse)=>{
      if (response.error) {
        dispatch(showToastMessage({
          message:response.payload,
          type:"error",
          position: toast.POSITION.TOP_RIGHT
        }))
      }
    });
  }
  return(
    <div>
      <div>
        <span className=" text-lg">New Account</span>
      </div>
      <div>
        <div className=" mt-6">
          <label htmlFor="email" className="block mb-2 text-sm font-medium text-gray-700 ">Account name</label>
          <input onChange={(e)=> setAccountName(e.target.value)} type="text" id="name" className="form-input-2" placeholder="" required/>
        </div>
        <AppButton callBackFun={()=>saveAccount()} showLoader={isSwitchingAccountStatus == APP_STATUS.PENDING}  spinnerClass="inline w-3 h-3 mr-2 text-prim-yellow animate-spin fill-black" className="prim-btn-2 mt-4 mb-6 w-full" text="Continue" />

      </div>
    </div>
  )
}

type TypeNtumaAccountsTableProps ={
  oneNewAccountsForm: ()=>void,
  ntumaAccount : TypeNtumaAccount | undefined
}
const NtumaAccountsTable = (props:TypeNtumaAccountsTableProps)=>{
  const userProfile = useAppSelector(selcetUserProfile);
  const dispatch = useAppDispatch();
  const isSwitchingAccountStatus = useAppSelector(state=>state.auth.isSwitchingAccountStatus)

  const switchAccount =(ntuma_account_id:number)=>{
    dispatch(switchNtumaAccount(ntuma_account_id))
    .then((response: TypeDispatchResponse)=>{
      if (response.error) {
        dispatch(showToastMessage({
          message:response.payload,
          type:"error",
          position: toast.POSITION.TOP_RIGHT
        }))
      }
    });
  }
  return (
    <>
       <div className="pb-28 ">
            <div className=" flex">
              <div className=" flex-grow flex flex-col justify-center">Choose Account</div>
              <div>
                <button onClick={()=> props.oneNewAccountsForm()} type="button" className=" text-prim-color bg-prim-yellow hover:bg-prim-green focus:ring-4 font-medium rounded-lg text-sm px-5 py-2.5 mr-2 mb-2  focus:outline-none">New Account</button>
              </div>
          </div>
          <div>
            <table className="w-full text-sm text-left text-gray-500 dark:text-gray-400 mt-3">
                <thead>
                    <tr>
                        <th scope="col" >
                            Account name
                        </th>
                        <th scope="col" >
                            Role
                        </th>
                        <th scope="col" >
                            
                        </th>
                    </tr>
                </thead>
                <tbody>
                  {(userProfile?.staff_profiles)&&
                    userProfile?.staff_profiles.map((item,key)=>
                      <tr key={key}>
                          <td>
                              {item.ntuma_account.account_name}
                          </td>
                          <td>
                            <span className="bg-green-100 text-green-800 text-xs font-medium mr-2 px-2.5 py-0.5   rounded ">{item.staff_role}</span>
                          </td>
                          
                          <td>
    
                            { 
                              (isSwitchingAccountStatus !== APP_STATUS.PENDING)?
                                
                                (props.ntumaAccount !== undefined && userProfile.current_account.id===  item.ntuma_account.id  )?
                                <span className="bg-gray-100 text-gray-800 text-xs font-medium mr-2 px-2.5 py-0.5 rounded ">current</span>:
                                <span onClick={()=>switchAccount(item.ntuma_account.id )} className="font-medium text-prim-green cursor-pointer  hover:underline">Switch</span>
                              :
                              <AppSpinner TopClass="w-full flex justify-center" SecondClass="w-4 h-4 mr-2 text-gray-200 animate-spin  fill-gray-700"/>
                            }
                          
                          </td>
                      </tr>
                    )
                  }
                </tbody>
            </table>
          </div>
          
        </div>
    </>
  );
}

type DrowerFunctions ={
  openDrawer: ()=>void
}
const Drawer =forwardRef<DrowerFunctions>((_,ref)=>{
  const [open,setOpen]= useState(false)
  const modalRef = useRef<AccountModalFunctions>(null);
  const landingUrl = process.env.REACT_APP_LANDING_URL;
  const dispatch = useAppDispatch()
  const navigate =useNavigate()

  const deleteTokens =()=>{
    localStorage.removeItem("access_token")
    localStorage.removeItem("refresh_token")
    dispatch(deleteAuthTokens())
    navigate("/")
  }

  useImperativeHandle(ref, () => ({
    openDrawer
  }));
  
  const openDrawer=()=>{
    setOpen(true)
  }
  return (
    <>
      <AccountModal ref={modalRef}/>
      <div className={"fixed h-full top-0 right-0 bg-prim-color py-4 transition-all ease-in-out duration-700 "+ ((open)?" w-80":"w-0") }>
        <div className="h-screen flex flex-col">
          <div className="flex border-b-[1px] border-gray-400 px-4 pb-4">
            <div className=" flex-grow">
                <span className=" font-bold text-white ">Profile</span>
                <div className=" text-prim-yellow opacity-80 text-xs mt-2 flex"> 
                  <svg xmlns="http://www.w3.org/2000/svg" className=" text-prim-yellow" width="14" height="14" viewBox="0 0 24 24"><path fill="currentColor" d="M12 22q-2.075 0-3.9-.788q-1.825-.787-3.175-2.137q-1.35-1.35-2.137-3.175Q2 14.075 2 12t.788-3.9q.787-1.825 2.137-3.175q1.35-1.35 3.175-2.138Q9.925 2 12 2t3.9.787q1.825.788 3.175 2.138q1.35 1.35 2.137 3.175Q22 9.925 22 12t-.788 3.9q-.787 1.825-2.137 3.175q-1.35 1.35-3.175 2.137Q14.075 22 12 22Z"/></svg>
                  <span  >60% complete</span>
                </div>
            </div>
            <div className=" flex flex-col justify-center">
              <svg onClick={()=>setOpen(false)} xmlns="http://www.w3.org/2000/svg" className=" text-white opacity-80 cursor-pointer" width="25" height="25"  viewBox="0 0 15 15"><path fill="currentColor" fillRule="evenodd" d="M11.782 4.032a.575.575 0 1 0-.813-.814L7.5 6.687L4.032 3.218a.575.575 0 0 0-.814.814L6.687 7.5l-3.469 3.468a.575.575 0 0 0 .814.814L7.5 8.313l3.469 3.469a.575.575 0 0 0 .813-.814L8.313 7.5l3.469-3.468Z" clipRule="evenodd"/></svg>
            </div>
          </div>
          <div className=" px-4 flex flex-col flex-grow">
            <div className=" py-5 flex-grow ">
                <ul>
                  <li className=" text-white opacity-80 py-2 text-sm">
                    <Link to="/profile">Profile</Link>
                  </li>
                  <li className="text-white opacity-80 py-2 text-sm">Privacy</li>
                  <li className="text-white opacity-80 py-2 text-sm">
                    <a href="https://ntuma.app/contact" target="_blank">Help & Support</a>
                  </li>
                </ul>
            </div>
            <div className=" mb-12">
              <ul>
                <li onClick={()=> modalRef.current?.openModal()} className="text-white opacity-80 cursor-pointer py-2 text-sm flex">
                  <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 24 24"><path fill="currentColor" d="M21 7.28V5c0-1.1-.9-2-2-2H5a2 2 0 0 0-2 2v14a2 2 0 0 0 2 2h14c1.1 0 2-.9 2-2v-2.28A2 2 0 0 0 22 15V9a2 2 0 0 0-1-1.72zM20 9v6h-7V9h7zM5 19V5h14v2h-6c-1.1 0-2 .9-2 2v6c0 1.1.9 2 2 2h6v2H5z"/><circle cx="16" cy="12" r="1.5" fill="currentColor"/></svg>
                  <span className="pl-1 h-full text-center mt-[3px]"> Switch Account</span>
                </li>
                <li onClick={()=> {
                  deleteTokens()
                }}  className="text-white opacity-80 py-2 text-sm flex cursor-pointer">
                  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path fill="currentColor" d="M12 22C6.477 22 2 17.523 2 12S6.477 2 12 2a9.985 9.985 0 0 1 8 4h-2.71a8 8 0 1 0 .001 12h2.71A9.985 9.985 0 0 1 12 22zm7-6v-3h-8v-2h8V8l5 4l-5 4z"/></svg>
                  <span className="pl-1 h-full text-center mt-[3px]"> Logout</span>
                </li>
              </ul>
            </div>
          </div>
        </div>
        
      </div>
    </>
   
  );
})

type subItem ={
  text:string,
  linkRoute?: string
}

type sideLinkPropsType ={
    title: string
    subItems: subItem[],
    isActive?:string
}
const SideLink =(props:sideLinkPropsType)=>{
  const [isOpen, setIsOpen] = useState(false)
  useEffect(()=>{
    if (props.isActive ==="active") {
      setIsOpen(true)
    }
  },[props.isActive])
  return (
    <li> 
      <a onClick={()=> setIsOpen(!isOpen)} className={'nav-link ' + (isOpen ? 'bg-gray-200' : '')} href="#!"> <span className="flex" > <span className=" flex-grow">{props.title}</span> 
        <span className={isOpen?'hidden':''}>
          <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24"><path fill="currentColor" d="M19 13h-6v6h-2v-6H5v-2h6V5h2v6h6v2Z"/></svg>
        </span>
        <span className={!isOpen?'hidden':''}>
          <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24"><path fill="currentColor" d="M19 12.998H5v-2h14z"/></svg>
        </span>
      </span>  </a>    
      <ul className={' overflow-hidden '+ (!isOpen?'h-0':' h-auto')} >
          {
            props.subItems.map((item,key)=>(
              <li key={key}>
                {
                  (item.linkRoute)?
                  <NavLink className="sub-nav-link" to={item.linkRoute}>{item.text}</NavLink>
                  : <a className="sub-nav-link" href="#!" >{item.text}</a>
                }
              </li>
            ))
          }
      
      </ul>
      
    </li>
  )
}


const LoadingModal =({showModal}:{showModal:boolean})=>{
  return (
    <>
      {(showModal)&&
        <div  className="modal ">
          <div className=" modal-content modal-md py-6 ">
            <div className=" flex justify-center">
              
              <div className="">
                <div className=" flex justify-center w-full">
                  <div role="status">
                    <svg aria-hidden="true" className="w-8 h-8 mr-2 text-gray-200 animate-spin  fill-gray-700" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor"/>
                        <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill"/>
                    </svg>
                    <span className="sr-only">Loading...</span>
                  </div>
                </div>
                <span className=" text-center w-f">Please wait...</span>
              </div>
            </div>
          </div>

      </div>
      }
       
    </>
  )
}