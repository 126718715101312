import { EntityState, createAsyncThunk, createEntityAdapter, createSlice } from "@reduxjs/toolkit";
import { APP_STATUS, BASE_URL, TypeAppStatus, TypeSettlemetStatus } from "../../helpers/constants";
import { selectAccessToken } from "../auth/authSlice";
import { RootState } from "../../app/store";
import { handleSessionExpiration } from "../../helpers/commons";
import axios, { AxiosError } from "axios";



type TypeSettlement ={
    ntuma_account_id:number,
    amount:number,
    status:TypeSettlemetStatus
    creator_id:number,
    created_at:string
    creator:{
        firstname:string,
        lastname:string
    }
}


interface SettlementState extends EntityState<TypeSettlement>{
    loadingSettlementsStatus:TypeAppStatus,
    lastCursor:string|null,
    nextCursor:string|null,
    totalItems:number|null
    totalPages:number,
    cursorReset:boolean
}

const settlementsAdapter = createEntityAdapter<TypeSettlement>()
const initialState:SettlementState = settlementsAdapter.getInitialState({
    loadingSettlementsStatus:"idle",
    lastCursor:null,
    nextCursor:null,
    totalItems:null,
    totalPages:0,
    cursorReset:true
})

export const fetchSettlements = createAsyncThunk("auth/fetchSettlements", async(_,{getState,rejectWithValue,dispatch})=>{
    try {
     const auth_token = selectAccessToken(getState() as RootState);
     const nextCursor = selectNextCursor(getState() as RootState)
     let dataUrl = "";
     if (nextCursor ==null) {
         dataUrl = "/api/settlements"
     }else{
         dataUrl="/api/settlements?cursor="+nextCursor
     }
     const response = await axios.get(BASE_URL+dataUrl,{
         headers: {
             Authorization: 'Bearer '+auth_token,
         }
     })
     return response.data.data
    } catch (error) {
         const errorResponse = error as AxiosError;
         handleSessionExpiration(errorResponse,dispatch);
         if (errorResponse.response) {
             const data = errorResponse.response.data as {message:string, status:string};
             return rejectWithValue(data.message);
         }else{
             return rejectWithValue("Failed. Something went wrong");
         }
    }
 })


const settlementsSlice = createSlice({
    initialState,
    name:"settlements",
    reducers:{
        resetCursorSettlements(state){
            state.nextCursor = null
            state.lastCursor =null
            state.cursorReset = true
        },
    },
    extraReducers:(builder)=>{
        builder
        .addCase(fetchSettlements.pending,(state,action)=>{
            state.loadingSettlementsStatus = APP_STATUS.PENDING
        })
        .addCase(fetchSettlements.fulfilled,(state,action)=>{
            state.loadingSettlementsStatus = APP_STATUS.SUCCESS
            if (state.cursorReset) {
                settlementsAdapter.removeAll(state)
            }
            settlementsAdapter.addMany(state,action.payload.items)
            state.lastCursor = action.payload.lastCursor
            state.nextCursor = action.payload.nextCursor
            state.totalItems = action.payload.totalItems
            state.totalPages = action.payload.totalPages
            state.cursorReset = false
        })
        .addCase(fetchSettlements.rejected,(state,action)=>{
            state.loadingSettlementsStatus = APP_STATUS.ERROR
        })
    }
})
export default settlementsSlice.reducer

export const {
    resetCursorSettlements
} = settlementsSlice.actions

export const {
    selectAll: selectSettlements,
    selectById: selectSettlemtById,
  } = settlementsAdapter.getSelectors((state:RootState) => state.settlements)

export const selectNextCursor = (state: RootState) => state.settlements.nextCursor
