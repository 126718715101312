import NoData from "../../assets/img/nodata_green.svg"
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { useEffect, useState } from "react";
import { formatDate } from "../../helpers/commons";
import { TypeUser, getUserProfile, selectAccessToken, selectNtumaAccount } from "../../features/auth/authSlice";
import AppButton from "../elements/AppButton";
import useApiRequest from "../../helpers/api_request";
import { BASE_URL } from "../../helpers/constants";
import { showToastMessage } from "../../features/generalSlice";
import { toast } from "react-toastify";
import { AxiosError } from "axios";

const WebHooksPage = ()=>{
    const dispatch = useAppDispatch()
    const [savingWebhooks, setSavingWebhooks] = useState(false);
    const apiRequest = useApiRequest();
    const accessToken = useAppSelector(selectAccessToken);
    const [collectionsWebhook, setCollectionsWebhook] = useState("")
    const [paymentsWebhook, setPaymentsWebhook] = useState("")
    const ntumaAccount = useAppSelector(selectNtumaAccount)

    useEffect(()=>{
        getWebhooks()
    },[ntumaAccount])

    const getWebhooks = async()=>{
        await apiRequest({
            method: 'get',
            url: BASE_URL+"/api/webhook",
            headers: {
                Authorization: `Bearer ${accessToken}`,
            },
        }).then((response)=>{
            if (response.data) {
                setCollectionsWebhook( response.data.collection_url)
                setPaymentsWebhook(response.data.payment_url)
            }
        }).catch((error)=>{
            const errorResponse = error as AxiosError;
            // console.log(errorResponse.response)
            if (errorResponse.response) {
                const data = errorResponse.response.data as {message:string, status:string};
                dispatch(showToastMessage({
                    message:data.message,
                    type:"error",
                    position: toast.POSITION.TOP_RIGHT
                }));
              
            }else{
                dispatch(showToastMessage({
                    message:"Something went wrong",
                    type:"error",
                    position: toast.POSITION.TOP_RIGHT
                }));
            }
        }); 
    }

    const saveWebhooks =async ()=>{
        setSavingWebhooks(true)
        await apiRequest({
            method: 'post',
            url: BASE_URL+"/api/webhook/save-url",
            headers: {
                Authorization: `Bearer ${accessToken}`,
            },
            data:{
                payment_url:paymentsWebhook,
                collection_url:collectionsWebhook
            }
        }).then((response)=>{
            setSavingWebhooks(false)
            dispatch(showToastMessage({
                message:"Webhooks Updated",
                type:"success",
                position: toast.POSITION.TOP_RIGHT
            }));
            dispatch(getUserProfile())
       
        }).catch((error)=>{
            const errorResponse = error as AxiosError;
            // console.log(errorResponse.response)
            if (errorResponse.response) {
                const data = errorResponse.response.data as {message:string, status:string};
                dispatch(showToastMessage({
                    message:data.message,
                    type:"error",
                    position: toast.POSITION.TOP_RIGHT
                }));
              
            }else{
                dispatch(showToastMessage({
                    message:"Something went wrong",
                    type:"error",
                    position: toast.POSITION.TOP_RIGHT
                }));
            }
            setSavingWebhooks(false)
        }); 
    }
    return (
        <div>
              <div className="card mb-12">
                
                <div className="card-body flex flex-col px-4 py-4 min-h-half-screen pb-16">
                    <div className="flex">
                        <nav className="flex justify-between " aria-label="Breadcrumb">
                        <ol className="inline-flex items-center mb-3 sm:mb-0">
                            <li>
                            <div className="flex items-center">
                                <h5 className=" card-title ">WebHooks</h5>
                            </div>
                            </li>
                        
                        </ol>
                        </nav>
                  
                    </div>
                   
                    <div className=" w-2/4 mt-2  ">
                        <div className="">
                            <label htmlFor="email" className="block text-sm font-medium text-gray-700 ">Collections web hook</label>
                            <input value={collectionsWebhook} onChange={(e)=> setCollectionsWebhook(e.target.value)} type="text" id="name" className="form-input-2" placeholder=""/>
                        </div>
                    </div>
                    <div className=" w-2/4 mt-4  ">
                        <div className="">
                            <label htmlFor="email" className="block text-sm font-medium text-gray-700 ">Payments web hook</label>
                            <input value={paymentsWebhook} onChange={(e)=> setPaymentsWebhook(e.target.value)} type="text" id="name" className="form-input-2" placeholder=""/>
                        </div>
                    </div>
                    <div className="mt-5">
                        <AppButton callBackFun={()=>saveWebhooks()} showLoader={savingWebhooks}  spinnerClass="inline w-3 h-3 mr-2 text-prim-yellow animate-spin fill-black" className="prim-btn-1  " text="Save Changes" />
                    </div>
                 
                </div>
            </div>
              
        </div>
    )
}
export default WebHooksPage;

