import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import { TypeActiveEnviroment, TypeApiKey, APP_STATUS, BASE_URL, TypeNtumaAccount, TypeAppStatus } from "../../helpers/constants"
import axios, { AxiosError } from "axios"
import { RootState } from "../../app/store"
import { handleSessionExpiration } from "../../helpers/commons"

type TypeStaff ={
    email:string,
    staff_role:string,
    ntuma_account:TypeNtumaAccount
}
export type TypeUser={
    id: number,
    email:string,
    firstname:string,
    lastname:string,
    phone?:string,
    staff_profiles:TypeStaff[]
    current_account:TypeNtumaAccount
}
type AuthInitialStateType ={
    user?:TypeUser,
    ntumaAccount?:TypeNtumaAccount,
    activeEnvironment?: TypeActiveEnviroment,
    accessToken:string,
    ApiKey?:TypeApiKey
    refreshtoken: string,
    isVerifyingLogin:TypeAppStatus,
    isLogingOut:boolean,
    profileLoadingStatus:string,
    authenticated:boolean | undefined
    isSigningUpStatus:TypeAppStatus,
    signUpErrors: string[],
    loginErrors: string[],
    hasRestPassword:boolean,
    isSwitchingModeStatus:TypeAppStatus,
    isSwitchingAccountStatus:TypeAppStatus,
    reloadProfile:boolean,
    userSessionExpired:boolean,

}

type registerBodyType ={
    firstname:string,
    lastname:string,
    emailOrPhone:string,
    password:string
}

type loginBodyType ={
    email:string,
    password:string
}

const authInitialState:AuthInitialStateType ={
    user:undefined,
    ntumaAccount:undefined,
    activeEnvironment:"test",
    ApiKey: undefined,
    accessToken: localStorage.getItem('access_token') || '',
    refreshtoken: localStorage.getItem('refresh_token') || '',
    isVerifyingLogin:"idle",
    isLogingOut:false,
    profileLoadingStatus:"idle",
    authenticated:undefined,
    isSigningUpStatus:"idle",
    signUpErrors:[],
    loginErrors:[],
    hasRestPassword:false,
    isSwitchingModeStatus:"idle",
    isSwitchingAccountStatus:"idle",
    reloadProfile:false,
    userSessionExpired:false
}

export const registerUser = createAsyncThunk("auth/registerUser", async(data:registerBodyType, {rejectWithValue})=>{
    try {
        const response = await axios({
            method: 'post',
            url: BASE_URL+"/api/sign-up",
            data:data
        })
        return response.data.data
    } catch (error) {
        const errorResponse = error as AxiosError;
        if (errorResponse.response) {
            const data = errorResponse.response.data as {message:string, status:string};
            return rejectWithValue(data.message);
        }else{
            return rejectWithValue("Failed. Something went wrong");
        }
    }
});


export const newNtumaAccount = createAsyncThunk("auth/newNtumaAccount", async(accountName:string,{rejectWithValue,getState,dispatch})=>{
    const auth_token = selectAccessToken(getState() as RootState);
    try {
        const response = await axios({
            method: 'post',
            url: BASE_URL+"/api/ntuma-account/create-account",
            headers: {
                Authorization: 'Bearer '+auth_token,
            },
            data:{
                account_name:accountName
            }
        })
        return response.data.data
    } catch (error) {

        const errorResponse = error as AxiosError;
        handleSessionExpiration(errorResponse,dispatch);

        if (errorResponse.response) {
            const data = errorResponse.response.data as {message:string, status:string};
            return rejectWithValue(data.message);
        }else{
            return rejectWithValue("Failed. Something went wrong");
        }
    }
});

export const switchNtumaAccount = createAsyncThunk("auth/switchNtumaAccount", async(ntuma_account_id:number,{rejectWithValue,getState,dispatch})=>{
    const auth_token = selectAccessToken(getState() as RootState);

    try {
        const response = await axios({
            method: 'post',
            url: BASE_URL+"/api/ntuma-account/switch-account",
            headers: {
                Authorization: 'Bearer '+auth_token,
            },
            data:{
                ntuma_account_id:ntuma_account_id
            }
        })
        return response.data.data
    } catch (error) {
        const errorResponse = error as AxiosError;
        handleSessionExpiration(errorResponse,dispatch);
        if (errorResponse.response) {
            const data = errorResponse.response.data as {message:string, status:string};
            return rejectWithValue(data.message);
        }else{
            return rejectWithValue("Failed. Something went wrong");
        }
    }
});

export const changeAccountMode = createAsyncThunk("auth/changeAccountMode", async(mode:string,{rejectWithValue,getState,dispatch})=>{
    const auth_token = selectAccessToken(getState() as RootState);

    try {
        const response = await axios({
            method: 'post',
            url: BASE_URL+"/api/keys/set-environment",
            headers: {
                Authorization: 'Bearer '+auth_token,
            },
            data:{
                environment:mode
            }
        })
        return response.data.data
    } catch (error) {
        const errorResponse = error as AxiosError;
        handleSessionExpiration(errorResponse,dispatch);
        if (errorResponse.response) {
            const data = errorResponse.response.data as {message:string, status:string};
            return rejectWithValue(data.message);
        }else{
            return rejectWithValue("Failed. Something went wrong");
        }
    }
});

export const loginVerification = createAsyncThunk("auth/loginVerification", async(data:{email_or_phone:string,code:string},{rejectWithValue})=>{
    try {
        const response = await axios({
            method: 'post',
            url: BASE_URL+"/api/login-otp",
            data:data
        })
        return response.data.data
    } catch (error) {
        
        const errorResponse = error as AxiosError;
    
        if (errorResponse.response) {
            const data = errorResponse.response.data as {message:string, status:string};
            return rejectWithValue(data.message);
        }else{
            return rejectWithValue("Failed. Something went wrong");
        }
    }
});

export const getUserProfile = createAsyncThunk("auth/getUserProfile", async(_,{getState})=>{
    const auth_token = selectAccessToken(getState() as RootState);
    const response = await axios.get(BASE_URL+"/api/user/profile",{
        headers: {
            Authorization: 'Bearer '+auth_token,
        }
    })
    return response.data.data
})

const authSlice = createSlice({
   name:"auth",
   initialState:authInitialState,
   reducers:{
    sessionExpired(state,actions){
        state.userSessionExpired = true
        console.log("Show Login Dialog")
    },
    unSetSessionExpiry(state){
        state.userSessionExpired = false
    },
    logOutUser(state){
        state.isLogingOut = true;
    },
    deleteAuthTokens(state){
        state.accessToken = "";
        state.refreshtoken =""
    },
    passwordChanged(state,actions){
        state.hasRestPassword =true
    }
   },
   extraReducers:(builder)=>{
    builder.addCase(loginVerification.pending,(state,action)=>{
        state.isVerifyingLogin =APP_STATUS.PENDING
    })
    .addCase(loginVerification.fulfilled,(state,action)=>{
        state.authenticated =true
        state.isVerifyingLogin =APP_STATUS.SUCCESS
        state.user= action.payload['user'];
        state.accessToken= action.payload['accessToken'];
        state.refreshtoken= action.payload['refreshToken'];
        localStorage.setItem("access_token",state.accessToken)
        localStorage.setItem("refresh_token",state.refreshtoken)
    })
    .addCase(loginVerification.rejected,(state,action)=>{
        state.isVerifyingLogin = APP_STATUS.ERROR
        state.loginErrors =[]
        state.loginErrors.push(action.payload as string)
    })
    .addCase(getUserProfile.pending,(state,action)=>{
        state.profileLoadingStatus = APP_STATUS.PENDING
    })
    .addCase(getUserProfile.rejected,(state,action)=>{
        state.profileLoadingStatus = APP_STATUS.ERROR
        state.authenticated =false
        console.log(action)
    })
    .addCase(getUserProfile.fulfilled,(state,action)=>{
        state.authenticated =true
        state.profileLoadingStatus = APP_STATUS.SUCCESS
        // state.isSwitchingModeStatus =APP_STATUS.IDLE
        state.user= action.payload;
        if (action.payload["current_account"]) {
            state.ntumaAccount = action.payload["current_account"]
            state.ApiKey = action.payload["current_account"]["api_keys"]
            state.activeEnvironment = state.ApiKey?.active_environment
        }
        state.reloadProfile = false
    })
    .addCase(registerUser.pending,(state,action)=>{
        state.isSigningUpStatus = APP_STATUS.PENDING
    })
    .addCase(registerUser.fulfilled,(state,action)=>{
        state.isSigningUpStatus = APP_STATUS.SUCCESS
        state.user = action.payload
    })
    .addCase(registerUser.rejected,(state,action)=>{
        state.isSigningUpStatus = APP_STATUS.ERROR
        state.signUpErrors =[]
        state.signUpErrors.push(action.payload as string)
    })
    .addCase(changeAccountMode.pending,(state,action)=>{
        state.isSwitchingModeStatus = APP_STATUS.PENDING
    })
    .addCase(changeAccountMode.fulfilled,(state,action)=>{
        state.isSwitchingModeStatus = APP_STATUS.SUCCESS
        state.reloadProfile = true
    })
    .addCase(changeAccountMode.rejected,(state,action)=>{
        state.isSwitchingModeStatus = APP_STATUS.ERROR
    })
    .addCase(switchNtumaAccount.pending,(state,action)=>{
        state.isSwitchingAccountStatus = APP_STATUS.PENDING
    })
    .addCase(switchNtumaAccount.fulfilled,(state,action)=>{
        state.isSwitchingAccountStatus = APP_STATUS.SUCCESS
        state.reloadProfile = true
    })
    .addCase(switchNtumaAccount.rejected,(state,action)=>{
        state.isSwitchingAccountStatus = APP_STATUS.ERROR
    })
    .addCase(newNtumaAccount.pending,(state,action)=>{
        state.isSwitchingAccountStatus = APP_STATUS.PENDING
    })
    .addCase(newNtumaAccount.fulfilled,(state,action)=>{
        state.isSwitchingAccountStatus = APP_STATUS.SUCCESS
        state.reloadProfile = true
    })
    .addCase(newNtumaAccount.rejected,(state,action)=>{
        state.isSwitchingAccountStatus = APP_STATUS.ERROR
    })
    
   }
});

export const {
    deleteAuthTokens,
    sessionExpired,
    passwordChanged,
    unSetSessionExpiry
}= authSlice.actions
export default authSlice.reducer

export const selectAccessToken = (state: RootState) => state.auth.accessToken;

export const selectAuthentication = (state:RootState)=> state.auth.authenticated

export const selectprofileLoadingStatus = (state:RootState)=> state.auth.profileLoadingStatus

export const selectNtumaAccount = (state:RootState)=> state.auth.ntumaAccount

export const selectActiveEnvironment = (state:RootState)=> state.auth.activeEnvironment

export const selcetUserProfile =(state:RootState)=> state.auth.user