import NoData from "../../assets/img/nodata_green.svg"
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { useEffect, useState } from "react";
import { formatDate, notEmpty } from "../../helpers/commons";
import { selectAccessToken, selectNtumaAccount } from "../../features/auth/authSlice";
import AppSpinner from "../elements/AppSpinner";
import { APP_STATUS, BASE_URL, SETTLEMENT_STATUS } from "../../helpers/constants";
import MoneyInput from "../elements/MoneyInput";
import AppButton from "../elements/AppButton";
import { showToastMessage } from "../../features/generalSlice";
import { toast } from "react-toastify";
import useApiRequest from "../../helpers/api_request";
import { AxiosError } from "axios";
import { fetchStaff, resetCursorStaff, selectStaff } from "../../features/staff/staffSlice";
const TeamPage = ()=>{
    const dispatch = useAppDispatch()
    const staff = useAppSelector(selectStaff)
    const ntumaAccount = useAppSelector(selectNtumaAccount);
    const loadingStaffStatus = useAppSelector(state=>state.staff.loadingStaffStatus)
    const [currentPage, setCurrentPage] = useState(1);
    const totalPages = useAppSelector(state=>state.settlements.totalPages)
    const [inviteModalOpen, setInviteModalOpen] = useState(false)

    useEffect(()=>{
      dispatch(resetCursorStaff())
      dispatch(fetchStaff())
    },[ntumaAccount])

    return (
        <div>
              <div className="card mb-12">
                
                <div className="card-body flex flex-col px-4 py-4 min-h-half-screen pb-16">
                  <div className="flex">
                    <nav className="flex justify-between " aria-label="Breadcrumb">
                      <ol className="inline-flex items-center mb-3 sm:mb-0">
                        <li>
                          <div className="flex items-center">
                            <h5 className=" card-title ">Team members</h5>
                          </div>
                        </li>
                       
                      </ol>
                    </nav>
                    <div className="flex-grow flex justify-end">
                      <button onClick={()=> setInviteModalOpen(true)} type="button" className="prim-btn-1 mb-2">Invite Member</button>
                    </div>
                  </div>
                  {
                    (staff.length===0 && loadingStaffStatus != APP_STATUS.PENDING)&&
                    <div  className=" h-fit flex-grow flex flex-col justify-center ">
                      <div className="flex justify-center">
                          <div>
                              <img className=" w-32" src={NoData} alt=""  />
                              <p>No Staff Yet!</p>
                          </div>
                      </div>
                    </div>
                  }
                  {
                    (staff.length>0)&&
                    <table className="w-full text-sm text-left text-gray-500 dark:text-gray-400">
                      <thead>
                          <tr>
                              <th scope="col" >
                                Email
                              </th>
                              <th scope="col" >
                                Names
                              </th>
                              <th scope="col" >
                                Role
                              </th>
                             
                          </tr>
                      </thead>
                      <tbody>
                          {
                            staff.map((item,key )=>
                              <tr key={key}>
                                <td>{item.email}</td>
                                <td>
                                  {item.user? 
                                      <span>{item.user.firstname+" "+item.user.lastname}</span>
                                    :
                                    <span className="badge-failed">Invite Sent</span>
                                  }
                                </td>
                                <td>{ item.staff_role }</td>
                              </tr>
                            )
                          }
                      </tbody>
                    </table>
                  }
                 
                  {(loadingStaffStatus === APP_STATUS.PENDING)&& <AppSpinner/>}
                  {
                    (currentPage < totalPages )&&
                    <div  className=" flex justify-center mt-16">
                      <button onClick={()=> {
                        setCurrentPage(currentPage+1)
                        dispatch(fetchStaff())
                      }} type="button" className="btn-light text-gray-600 ">
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" className=" text-gray-600" viewBox="0 0 24 24"><path fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M14 16h5v5M10 8H5V3m14.418 6.003A8 8 0 0 0 5.086 7.976m-.504 7.021a8 8 0 0 0 14.331 1.027"/></svg>
                        <span className="pl-1">Load More</span>
                      </button>
                    </div>
                  }
                 
                </div>
              </div>
              <InviteModal inviteSent={()=>{
                  dispatch(resetCursorStaff())
                  dispatch(fetchStaff())
              }} isOpen={inviteModalOpen} onClose={()=>setInviteModalOpen(false)}/>
        </div>
    )
}
export default TeamPage;

type TypeInviteModalProps ={
  isOpen:boolean,
  onClose: ()=>void,
  inviteSent:()=>void
}
const InviteModal =(props:TypeInviteModalProps)=>{
  const [email,setEmail]= useState("")
  const [staff_role, setStaffRole] = useState("")
  const [isInvitingStaff, setIsInvitingStaff] = useState(false);
  const dispatch = useAppDispatch();
  const apiRequest = useApiRequest()
  const accessToken = useAppSelector(selectAccessToken);


  const sendInvite = async()=>{
    if (!notEmpty(email)) {
      dispatch(showToastMessage({
        message:"Email is missing",
        type:"error",
        position: toast.POSITION.TOP_CENTER
      }));
      return;
    }
    if (!notEmpty(staff_role)) {
      dispatch(showToastMessage({
        message:"Role is missing",
        type:"error",
        position: toast.POSITION.TOP_CENTER
      }));
      return;
    }
    setIsInvitingStaff(true)
    await apiRequest({
        method: 'post',
        url: BASE_URL+"/api/staff/register",
        headers: {
            Authorization: `Bearer ${accessToken}`,
        },
        data:{
            staff_role:staff_role,
            email:email,
        }
    }).then((response)=>{
      setIsInvitingStaff(false)
        dispatch(showToastMessage({
            message:"Invite Sent",
            type:"success",
            position: toast.POSITION.TOP_RIGHT
        }));
        props.inviteSent()
        props.onClose()
    }).catch((error)=>{
        const errorResponse = error as AxiosError;
        // console.log(errorResponse.response)
        if (errorResponse.response) {
            const data = errorResponse.response.data as {message:string, status:string};
            dispatch(showToastMessage({
                message:data.message,
                type:"error",
                position: toast.POSITION.TOP_RIGHT
            }));
          
        }else{
            dispatch(showToastMessage({
                message:"Something went wrong",
                type:"error",
                position: toast.POSITION.TOP_RIGHT
            }));
        }
        setIsInvitingStaff(false)
    }); 
  }

  return(
    <>
      {(props.isOpen)&&
        <div  className="modal ">
          <div className=" modal-content modal-md py-4">
            <div className=" flex justify-center">
              <span className="text-center text-lg">Invite Member</span>
            </div>
              
              <div className=" mt-4">
                  <label htmlFor="" className="block text-sm font-medium text-gray-700 ">Email</label>
                  <input value={email} onChange={(e)=>setEmail(e.target.value)} type="email" className="form-input-2" /> 
              </div>
              <div className=" mt-4">
                    <label htmlFor="" className="block text-sm font-medium text-gray-700 ">Role</label>
                    <select onChange={(e)=> setStaffRole(e.target.value)} value={staff_role} name="" className="form-input-2" id="">
                      <option value="">Select</option>
                      <option value="owner">Owner</option>
                      <option value="administrator">Administrator</option>
                      <option value="operations">Operations</option>
                      <option value="developer">Developer / IT</option>
                      <option value="customer_support">Customer Support</option>
                      <option value="user">User</option>
                    </select>
              </div>
              <div className="flex flex-row-reverse mt-10 pt-4 px-0  gap-2">
                    <div>
                        <AppButton callBackFun={()=>sendInvite()} showLoader={isInvitingStaff}  spinnerClass="inline w-3 h-3 mr-2 text-prim-yellow animate-spin fill-black" className="prim-btn-2  " text="Send Invite" />
                    </div>
                    <div>
                        <button onClick={()=>props.onClose()} type="button" className="btn-secondary-1 ">Close</button>
                    </div>
                </div>
          </div>
        </div>
      }
      
    </>
  )
}