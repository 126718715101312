import Image from "../../assets/img/Group_25.svg"
import Logo from "../../assets/img/ntuma_logo.svg"

import { createContext, useContext, useEffect, useRef, useState } from "react"
import { Link, useNavigate } from "react-router-dom"
import { ToastContainer, toast } from "react-toastify"
import CircleCounter, { CircleCounterFunctions } from "../elements/CircleCounter"
import PinInput from "../elements/PinInput"
import AppButton from "../elements/AppButton"
import PasswordInput from "../elements/PasswordInput"
import useApiRequest from "../../helpers/api_request"
import { BASE_URL } from "../../helpers/constants"
import 'react-toastify/dist/ReactToastify.css';
import { AxiosError } from "axios"
import moment from "moment"
import { validatePassword } from "../../helpers/commons"
import { useAppDispatch } from "../../app/hooks"
import { passwordChanged } from "../../features/auth/authSlice"

type ResetPasswordContextDataType= {
    resetStage:number
    otpCode:string
    email:string,
    setEmail: React.Dispatch<React.SetStateAction<string>>
    setOtpCode: React.Dispatch<React.SetStateAction<string>>
    setResetStage:React.Dispatch<React.SetStateAction<number>>
}
const ResetPasswordContext = createContext<ResetPasswordContextDataType | null>(null)

const ResetPassword =()=>{
    const [email, setEmail] = useState("");
    const [otpCode, setOtpCode] = useState("");
    const [resetStage, setResetStage] = useState(1);
    const data ={ email, setEmail,otpCode,setOtpCode,resetStage,setResetStage}

    return(
       <ResetPasswordContext.Provider value={data}>
            <div>
                <div className=" flex items-center justify-center min-h-screen">
                    <ToastContainer />
                    <div className=" w-full flex justify-center px-2 py-2">
                        <div className="card w-4/6 sm:w-full md:w-full lg:w-full xl:w-4/6 2xl:w-4/6 3xl:w-4/6 shadow-lg rounded-none">
                            <div className=" card-body grid  sm:grid-cols-1 md:grid-cols-1 lg:grid-cols-1 xl:grid-cols-2 2xl:grid-cols-2 3xl:grid-cols-2 p-0">
                                <div>
                                    <div className=" sm:h-14 md:h-14 lg:h-14 xl:h-28 2xl:h-28 3xl:h-28  flex justify-center">
                                    </div>
                                    <div className="flex justify-center pb-10">
                                        <div className="w-3/5 flex justify-center ">
                                            <div>
                                                <div className=" h-8 flex flex-col justify-center">
                                                    <span className="font-bold text-lg">Reset Password</span>
                                                </div>
                                                <div className="mt-4">
                                                    <img src={Image} alt="" />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className=" bg-prim-color pb-10">
                                    <div className=" h-28 flex justify-center">
                                        <img width={80} src={Logo} alt="" />
                                    </div>
                                    <div className=" h-8 flex flex-col justify-center">
                                        <div className="flex items-center px-16 ">
                                            <div className=" flex-grow border-t-[1px] border-gray-400 border-opacity-60"></div>
                                            <div className="mx-4 text-white opacity-80 text-xs">Reset</div>
                                            <div className="flex-grow  border-t-[1px] border-gray-400 border-opacity-60"></div>
                                        </div>
                                    </div>
                                    {(resetStage==1)&& <UserEmailForm/>}
                                    {(resetStage==2)&& <ResetOtpForm/>}
                                    {(resetStage==3)&& <PasswordCaptureForm/>}

                                
                                    <div className="w-full mt-4 px-5 text-center">
                                        <span className=" text-xs"> <span className="text-white opacity-80">Don't have an account? </span> 
                                            <Link className=" text-prim-yellow hover:text-prim-green" to="/signup">Sign Up</Link>
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
       </ResetPasswordContext.Provider>
    );
}

const UserEmailForm =()=>{
    const context= useContext(ResetPasswordContext);
    const [isSending, setIsSending] =useState(false)
    const apiRequest = useApiRequest()

    const sendResetOtp = async()=>{
        setIsSending(true);
        await apiRequest({
            method: 'post',
            url: BASE_URL+"/api/password-reset-code",
            data:{
                email_or_phone: context?.email,
            }
          }).then((response)=>{
            setIsSending(false);
            context?.setResetStage(2);
            localStorage.setItem('lastOtpTime', moment().toISOString());
          }).catch((error)=>{
            const errorResponse = error as AxiosError;
            if (errorResponse.response) {
                const data = errorResponse.response.data as {message:string, status:string};
                toast.error(data.message, {
                    position: toast.POSITION.TOP_RIGHT
                });
            }else{
                toast.error("Something went wrong", {
                    position: toast.POSITION.TOP_RIGHT
                });
            }
            setIsSending(false);
          }); 
    }

    return (
        <>
            <div className="w-full mt-4 px-5">
                <span className="text-white opacity-70 text-xs">Enter the email address associated with your account and we'll send you an otp to reset your password</span>
            </div>
            <div className=" w-full mt-4 px-5">
                
                <label htmlFor="email" className="form-label-1">Email</label>
                <div className="relative">
                    <div className="absolute inset-y-0 left-0 flex items-center  pl-[8px] pointer-events-none">
                        <svg aria-hidden="true" className="form-input-1-icon " fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path d="M2.003 5.884L10 9.882l7.997-3.998A2 2 0 0016 4H4a2 2 0 00-1.997 1.884z"></path><path d="M18 8.118l-8 4-8-4V14a2 2 0 002 2h12a2 2 0 002-2V8.118z"></path></svg>
                    </div>
                    <input value={context?.email} onChange={(e)=>context?.setEmail(e.target.value)}  type="email"  className="form-input-1 pl-10" />
                </div>
            </div>
    
            <div className="w-full mt-4 px-5">
                <AppButton callBackFun={()=>sendResetOtp()} showLoader={isSending}  spinnerClass="inline w-3 h-3 mr-2 text-prim-yellow animate-spin fill-black" className="  prim-btn-login" text="Send Otp" />
            </div>
        </>
    );
}

const PasswordCaptureForm =() =>{
    const [password_conformation, setPassWordConfirmation] = useState("");
    const [password, setPassWord] = useState("");
    const [isReseting, setIsReseting] = useState(false);
    const context= useContext(ResetPasswordContext);
    const apiRequest = useApiRequest();
    const navigate = useNavigate();
    const dispatch = useAppDispatch()


    const resetPassword = async()=>{
        if (password != password_conformation || password==="") {
            toast.error("Password confirmation does not match", {
                position: toast.POSITION.TOP_RIGHT
            });
           return
        }
        if (!validatePassword(password)) {
            toast.error("Password must have a number, a special character, and atleast 8 characters long", {
                position: toast.POSITION.TOP_RIGHT
            });
            return
        }
        setIsReseting(true);
        await apiRequest({
            method: 'post',
            url: BASE_URL+"/api/change-password",
            data:{
                email_or_phone: context?.email,
                code:context?.otpCode,
                password: password
            }
          }).then((response)=>{
            dispatch(passwordChanged(true))
            setIsReseting(false);
            navigate("/")
          }).catch((error)=>{
            const errorResponse = error as AxiosError;
            if (errorResponse.response) {
                const data = errorResponse.response.data as {message:string, status:string};
                toast.error(data.message, {
                    position: toast.POSITION.TOP_RIGHT
                });
            }else{
                toast.error("Something went wrong", {
                    position: toast.POSITION.TOP_RIGHT
                });
            }
            setIsReseting(false);
          }); 
    }
    return (
        <>
            <div className=" w-full mt-4 px-5">
                <label htmlFor="email" className="form-label-1 flex-grow">Password</label>
                <PasswordInput getPasswordValue={(password)=>setPassWord(password)}/>
            </div>
            <div className=" w-full mt-4 px-5">
                <label htmlFor="email" className="form-label-1 flex-grow">Confirm Password</label>
                <PasswordInput getPasswordValue={(password)=>setPassWordConfirmation(password)}/>
            </div>
        
            <div className="w-full mt-4 px-5">
                <AppButton callBackFun={()=>resetPassword()} showLoader={isReseting}  spinnerClass="inline w-3 h-3 mr-2 text-prim-yellow animate-spin fill-black" className="  prim-btn-login" text="Continue" />
            </div>
        </>
    );
}

const ResetOtpForm =()=>{
    const counterRef = useRef<CircleCounterFunctions>(null);
    const [counterFinished, setCounterFinished] = useState(false);
    const [isResending, setIsResending] = useState(false)
    const context= useContext(ResetPasswordContext);
    const apiRequest = useApiRequest();
   
    const resendOtp = async()=>{
        setIsResending(true);
        await apiRequest({
            method: 'post',
            url: BASE_URL+"/api/password-reset-code",
            data:{
                email_or_phone: context?.email,
            }
          }).then((response)=>{
            setIsResending(false);
            localStorage.setItem('lastOtpTime', moment().toISOString());
            counterRef.current?.startProgress(); 
            setCounterFinished(false)
          }).catch((error)=>{
            const errorResponse = error as AxiosError;
            if (errorResponse.response) {
                const data = errorResponse.response.data as {message:string, status:string};
                toast.error(data.message, {
                    position: toast.POSITION.TOP_RIGHT
                });
            }else{
                toast.error("Something went wrong", {
                    position: toast.POSITION.TOP_RIGHT
                });
            }
            setIsResending(false);
          }); 
    }

    const proceedWithReset=() =>{
        if (context?.otpCode) {
            if (context?.otpCode.length >=6) {
                context.setResetStage(3)
                return
            }
        }
        toast.error("Invalid OTP", {
            position: toast.POSITION.TOP_RIGHT
        });
    }



  
    return (
        <>
            <div className="w-full flex justify-center mt-4 px-5 h-24 ">
                <div className=" w-24">
                    <CircleCounter ref={counterRef} onProgressFinished={()=>setCounterFinished(true)}/>
                </div>
            </div>
            <div className="w-full mt-4 px-5">
                <label className="form-label-1 text-center text-white mb-4">
                    {(!counterFinished)?
                    <span>We emailed you a six-digit code to <span className=" text-prim-green"> {context?.email}</span>.Enter the code below to verify your identity.</span>
                        :
                    (!isResending)? <span>Session timed out. <a onClick={()=>resendOtp()} className=" text-prim-green" href="#!">Resend Code?</a></span> : <span>Sending please wait...</span>
                    
                    }
                    
                </label>
                <PinInput getValue={(otp)=>context?.setOtpCode(otp)} className="form-input-1 text-center"/>
                <div className="mt-2">
                <small className=" text-gray-500 text-xs ">Make sure to keep this window open while you check your inbox</small>
                </div>
            </div>
            <div className="w-full mt-4 px-5">
                <AppButton callBackFun={()=> proceedWithReset()} showLoader={false}  spinnerClass="inline w-3 h-3 mr-2 text-prim-yellow animate-spin fill-black" className="  prim-btn-login" text="Verify" />
            </div>
        </>
    );
}

export default ResetPassword;