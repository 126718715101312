import { EntityState, createAsyncThunk, createEntityAdapter, createSlice } from "@reduxjs/toolkit";
import { APP_STATUS, BASE_URL, TypeAppStatus, TypeCollectionStatus } from "../../helpers/constants";
import { selectAccessToken } from "../auth/authSlice";
import { RootState } from "../../app/store";
import axios, { AxiosError } from "axios";
import { handleSessionExpiration } from "../../helpers/commons";
import { TypeCustomer } from "../customers/customersSlice";

type TypeCollection ={
    id :number,
    ntuma_account_id:number,
    customer_id:number,
    transaction_id:string,
    msisdn:string,
    msisdn_carrier:string
    amount:number,
    currency:string,
    narration?:string,
    external_reference?:string,
    status:TypeCollectionStatus,
    created_at:string,
    customer:TypeCustomer,
    collection_channel:string,
    ussd_client_code:number
}

interface CollectionsState extends EntityState<TypeCollection>{
    loadingCollectionStatus:TypeAppStatus,
    lastCursor:string|null,
    nextCursor:string|null,
    totalItems:number|null
    totalPages:number,
    cursorReset:boolean
}


const collectionsAdapter = createEntityAdapter<TypeCollection>()
const initialState:CollectionsState = collectionsAdapter.getInitialState({
    loadingCollectionStatus:"idle",
    savingLinkStatus:"idle",
    lastCursor:null,
    nextCursor:null,
    totalItems:null,
    totalPages:0,
    cursorReset:true
})

export const fetchCollections = createAsyncThunk("auth/fetchCollections", async(_,{getState,rejectWithValue,dispatch})=>{
    try {
     const auth_token = selectAccessToken(getState() as RootState);
     const nextCursor = selectNextCursor(getState() as RootState)
     let dataUrl = "";
     if (nextCursor ==null) {
         dataUrl = "/api/collections" 
     }else{
         dataUrl="/api/collections?cursor="+nextCursor
     }
     const response = await axios.get(BASE_URL+dataUrl,{
         headers: {
             Authorization: 'Bearer '+auth_token,
         }
     })
     return response.data.data
    } catch (error) {
         const errorResponse = error as AxiosError;
         handleSessionExpiration(errorResponse,dispatch);
         if (errorResponse.response) {
             const data = errorResponse.response.data as {message:string, status:string};
             return rejectWithValue(data.message);
         }else{
             return rejectWithValue("Failed. Something went wrong");
         }
    }
 })

const collectionsSlice = createSlice({
    initialState,
    name:"collections",
    reducers:{
        resetCursorCollections(state){
            state.nextCursor = null
            state.lastCursor =null
            state.cursorReset = true
        },
    },
    extraReducers:(builder)=>{
        builder
        .addCase(fetchCollections.pending,(state,action)=>{
            state.loadingCollectionStatus = APP_STATUS.PENDING
        })
        .addCase(fetchCollections.fulfilled,(state,action)=>{
            state.loadingCollectionStatus = APP_STATUS.SUCCESS
            if (state.cursorReset) {
                collectionsAdapter.removeAll(state)
            }
            collectionsAdapter.addMany(state,action.payload.items)
            state.lastCursor = action.payload.lastCursor
            state.nextCursor = action.payload.nextCursor
            state.totalItems = action.payload.totalItems
            state.totalPages = action.payload.totalPages
            state.cursorReset = false
        })
        .addCase(fetchCollections.rejected,(state,action)=>{
            state.loadingCollectionStatus = APP_STATUS.ERROR
        })
    }
})

export const {
    resetCursorCollections
}= collectionsSlice.actions

export const {
    selectAll: selectCollections,
    selectById: selectCollectionById,
  } = collectionsAdapter.getSelectors((state:RootState) => state.collections)

export default collectionsSlice.reducer
export const selectNextCursor = (state: RootState) => state.collections.nextCursor
