import NoData from "../../assets/img/nodata_green.svg"
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { useEffect, useRef, useState } from "react";
import { formatDate, notEmpty } from "../../helpers/commons";
import { TypeUser, getUserProfile, selectAccessToken, selectNtumaAccount } from "../../features/auth/authSlice";
import AppButton from "../elements/AppButton";
import useApiRequest from "../../helpers/api_request";
import { BASE_URL, TypeBankDetail, TypeBusinessDetails } from "../../helpers/constants";
import { showToastMessage } from "../../features/generalSlice";
import { toast } from "react-toastify";
import { AxiosError } from "axios";
import DropFile, { DropFileFunctions } from "../elements/DropFile";
import FilesInputSection, { FileInputData } from "./FilesInputSection";
import useFilesRequest from "./useFilesRequest";
import { FiLink } from "react-icons/fi";

const BusinessOptions = [
    'Ecommerce',
    'Education',
    'Utilities',
    'Real Estate',
    'Financial Services',
    'Agriculture',
    'Manufacturing',
    'Construction',
    'Mining',
    'Energy',
    'Transportation',
    'Information Technology',
    'Healthcare',
    'Retail',
    'Service',
    'Hospitality',
    'Non-Profit Organisation',
    'Food and Beverage',
    'Logistics',
    'Media',
    'Entertainment',
    'Technology',
    'Financial services',
    'Telecommunications',
    'Food service',
    'Mining',
    'Construction',
    'Production',
    'Consulting',
    'Marketing'
];

const BusinessDetailsPage = () => {
    const accessToken = useAppSelector(selectAccessToken);
    const [activeTab, setActivetab] = useState("business");
    const userProfile = useAppSelector(state => state.auth.user)
    const storageUrl = process.env.REACT_APP_STORAGE_URL;



    return (
        <div>
            <div className="card mb-12">

                <div className="card-body flex flex-col px-4 py-4 min-h-half-screen pb-16">

                    <div className="text-sm font-medium text-center text-gray-500 border-b border-gray-200 ">
                        <ul className="flex flex-wrap -mb-px">
                            <li className="mr-2">
                                <a onClick={() => setActivetab('business')} href="#!" className={(activeTab === 'business') ? "tab-active" : "tab-inactive"}>Business Details</a>
                            </li>
                            <li className="mr-2">
                                <a onClick={() => setActivetab('bank')} href="#!" className={(activeTab === 'bank') ? "tab-active" : "tab-inactive"} aria-current="page">Bank Details</a>
                            </li>
                        </ul>
                    </div>

                    {
                        (activeTab === 'business') ?
                            <BusinessForms /> :
                            <BankForm />
                    }


                </div>
            </div>


            <div className="card mb-12">

                <div className="card-body flex flex-col px-4 min-h-half-screen pb-16">
                    <h1 className=" text-lg font-semibold">Upload Compliance Documents</h1>
                    {
                        userProfile?.current_account.NtumaAccountFiles.map((item,key) =>
                            <p key={key} className="mt-2 ">
                           <a target="_blank" className=" text-green-600 gap-0.5 flex" href={storageUrl + "/" + item.file_path}>
                             <span className=" text-sm flex flex-col justify-center"> <FiLink /></span>
                                 {item.file_name} </a>
                            </p>
                        )
                    }

                    <FileSections />

                </div>
            </div>

        </div>
    )
}
export default BusinessDetailsPage;


const FileSections = () => {
    const [otherFilesData, setOtherFilesData] = useState<FileInputData[]>([{ fileName: '', file: null }]);
    const { saveFile } = useFilesRequest()
    const [saving, setSaving] = useState(false);
    const dispatch = useAppDispatch()
    const apiRequest = useApiRequest();
    const userProfile = useAppSelector(state => state.auth.user)

    const accessToken = useAppSelector(selectAccessToken);
    const uploadNtumaFiles = async (otherFilesData: FileInputData[]) => {
        const filesData = [...otherFilesData]
        const filePromises = filesData
            .filter(iterator => iterator.file)
            .map(async iterator => {
                try {
                    const res = await saveFile(iterator.file as File);
                    return {
                        file_name: iterator.fileName,
                        file_path: res.data,
                    };
                } catch (error) {
                    throw error;
                }
            });

        const uploadedFiles = await Promise.all(filePromises);
        return uploadedFiles
    };

    const dispatchError = (message: string) => {
        dispatch(showToastMessage({
            message: message,
            type: "error",
            position: toast.POSITION.TOP_RIGHT
        }));
    }

    const saveBusinessProfile = async () => {
        setSaving(true)
        await apiRequest({
            method: 'post',
            url: BASE_URL + "/api/ntuma-account/save-ntuma-files",
            headers: {
                Authorization: `Bearer ${accessToken}`,
            },
            data: await uploadNtumaFiles(otherFilesData)
        }).then((response) => {
            setSaving(false)
            dispatch(showToastMessage({
                message: "Files updated",
                type: "success",
                position: toast.POSITION.TOP_RIGHT
            }));
            dispatch(getUserProfile())
        }).catch((error) => {
            const errorResponse = error as AxiosError;
            if (errorResponse.response) {
                const data = errorResponse.response.data as { message: string, status: string };
                dispatch(showToastMessage({
                    message: data.message,
                    type: "error",
                    position: toast.POSITION.TOP_RIGHT
                }));

            } else {
                dispatch(showToastMessage({
                    message: "Something went wrong",
                    type: "error",
                    position: toast.POSITION.TOP_RIGHT
                }));
            }
            setSaving(false)
        });
    }

    return (
        <>
            <div className="w-3/4 mt-0">
                <FilesInputSection
                    setFilesData={setOtherFilesData}
                    fileNames={[
                     "TIN certificate",
                     "Bank Statements", 
                     "Memorandum & Articles of Association",
                     "Particulars of Directors - Form 7/20 or Form 8",
                     "Proof of address - Form A9 or 18",
                     "Trading license",
                     "Company profile",
                     "Other"]} />
            </div>


            <div className="mt-5">
                <AppButton callBackFun={() => saveBusinessProfile()} showLoader={saving} spinnerClass="inline w-3 h-3 mr-2 text-prim-yellow animate-spin fill-black" className="prim-btn-1  " text="Save Files" />
            </div>
        </>
    )
}

const BankForm = () => {
    const dispatch = useAppDispatch()
    const apiRequest = useApiRequest();
    const [savingBankDetails, setSavingBankDetails] = useState(false);
    const userProfile = useAppSelector(state => state.auth.user)
    const accessToken = useAppSelector(selectAccessToken);
    const [bankDetails, setBankDetails] = useState<TypeBankDetail>({
        bank_name: "",
        account_number: "",
        account_name: "",
    })
    const ntumaAccount = useAppSelector(selectNtumaAccount);

    useEffect(() => {
        if (ntumaAccount) {
            console.log(ntumaAccount?.bank_details)
            if (ntumaAccount?.bank_details.length > 0) {
                setBankDetails(ntumaAccount?.bank_details[0])
            }
        }
    }, [ntumaAccount])

    const dispatchError = (message: string) => {
        dispatch(showToastMessage({
            message: message,
            type: "error",
            position: toast.POSITION.TOP_RIGHT
        }));
    }

    const saveBusinessProfile = async () => {

        if (!bankDetails.bank_name) {
            dispatchError("Bank name is missing")
            return;
        }
        if (!bankDetails.account_number) {
            dispatchError("Account number is missing")
            return;
        }
        if (!bankDetails.account_name) {
            dispatchError("Account name is missing")
            return;
        }
        setSavingBankDetails(true)
        await apiRequest({
            method: 'post',
            url: BASE_URL + "/api/ntuma-account/update-bank-details",
            headers: {
                Authorization: `Bearer ${accessToken}`,
            },
            data: {
                bank_name: bankDetails.bank_name,
                account_number: bankDetails.account_number,
                account_name: bankDetails.account_name,
            }
        }).then((response) => {
            setSavingBankDetails(false)
            dispatch(showToastMessage({
                message: "Bank details updated",
                type: "success",
                position: toast.POSITION.TOP_RIGHT
            }));
            dispatch(getUserProfile())
        }).catch((error) => {
            const errorResponse = error as AxiosError;
            if (errorResponse.response) {
                const data = errorResponse.response.data as { message: string, status: string };
                dispatch(showToastMessage({
                    message: data.message,
                    type: "error",
                    position: toast.POSITION.TOP_RIGHT
                }));

            } else {
                dispatch(showToastMessage({
                    message: "Something went wrong",
                    type: "error",
                    position: toast.POSITION.TOP_RIGHT
                }));
            }
            setSavingBankDetails(false)
        });
    }
    return (
        <>
            <div>
                <div className="mt-5 w-3/4">
                    <label className="block text-sm font-medium text-gray-700 ">Bank name</label>
                    <input onChange={(e) => {
                        if (bankDetails) {
                            setBankDetails({
                                ...bankDetails, bank_name: e.target.value
                            });
                        }
                    }} value={bankDetails?.bank_name} type="text" id="" className="form-input-2" placeholder="" />
                </div>
                <div className=" w-3/4 grid grid-cols-2 mt-5  gap-3">
                    <div className="">
                        <label className="block text-sm font-medium text-gray-700 ">Account number</label>
                        <input onChange={(e) => {
                            if (bankDetails) {
                                setBankDetails({
                                    ...bankDetails, account_number: e.target.value
                                });
                            }
                        }} value={bankDetails?.account_number} type="text" id="" className="form-input-2" placeholder="" />
                    </div>
                    <div className="">
                        <label className="block text-sm font-medium text-gray-700 ">Account name</label>
                        <input onChange={(e) => {
                            if (bankDetails) {
                                setBankDetails({
                                    ...bankDetails, account_name: e.target.value
                                });
                            }
                        }} value={bankDetails?.account_name} type="text" id="" className="form-input-2" placeholder="" />
                    </div>
                </div>
                <div className="mt-5">
                    <AppButton callBackFun={() => saveBusinessProfile()} showLoader={savingBankDetails} spinnerClass="inline w-3 h-3 mr-2 text-prim-yellow animate-spin fill-black" className="prim-btn-1  " text="Save Changes" />
                </div>
            </div>
        </>
    )
}



const BusinessForms = () => {
    const dispatch = useAppDispatch()
    const apiRequest = useApiRequest();
    const [savingProfile, setSavingProfile] = useState(false);
    const ntumaAccount = useAppSelector(selectNtumaAccount);
    const accessToken = useAppSelector(selectAccessToken);
    const storageUrl = process.env.REACT_APP_STORAGE_URL;
    const dropFileRef = useRef<DropFileFunctions>(null);
    const [originalCertificate, setOriginalCertificate] = useState<string | undefined>("")

    const [businessDetails, setBusinessDetails] = useState<TypeBusinessDetails>({
        business_name: "",
        business_industry: "",
        business_email: "",
        business_phone: "",
        reg_date: "",
        country: "",
        city: "",
        address_1: "",
        address_2: "",
        certificate_url: "",
    })
    useEffect(() => {
        if (ntumaAccount) {
            console.log(ntumaAccount?.business_details)
            if (ntumaAccount?.business_details !== undefined && ntumaAccount?.business_details !== null) {
                setBusinessDetails(ntumaAccount?.business_details)
                setOriginalCertificate(ntumaAccount?.business_details.certificate_url)
            }
        }
    }, [ntumaAccount])

    const dispatchError = (message: string) => {
        dispatch(showToastMessage({
            message: message,
            type: "error",
            position: toast.POSITION.TOP_RIGHT
        }));
    }

    const saveBusinessProfile = async () => {

        if (!businessDetails.business_name) {
            dispatchError("Name is missing")
            return;
        }
        if (!businessDetails.business_industry) {
            dispatchError("Industry is missing")
            return;
        }
        if (!businessDetails.business_phone) {
            dispatchError("Phone is missing")
            return;
        }
        if (!businessDetails.business_email) {
            dispatchError("Email is missing")
            return;

        }
        if (!businessDetails.country) {
            dispatchError("Country is missing")
            return;
        }
        if (!businessDetails.city) {
            dispatchError("City is missing")
            return;
        }
        if (!businessDetails.address_1) {
            dispatchError("Address 1 is missing")
            return;
        }
        if (!businessDetails.reg_date) {
            dispatchError("Registration date is missing")
            return;
        }
        setSavingProfile(true)
        await apiRequest({
            method: 'post',
            url: BASE_URL + "/api/user/update-business-details",
            headers: {
                Authorization: `Bearer ${accessToken}`,
            },
            data: {
                business_name: businessDetails.business_name,
                business_industry: businessDetails.business_industry,
                business_email: businessDetails.business_email,
                business_phone: businessDetails.business_phone,
                reg_date: businessDetails.reg_date,
                country: businessDetails.country,
                city: businessDetails.city,
                address_1: businessDetails.address_1,
                address_2: businessDetails.address_2,
                certificate_url: businessDetails.certificate_url ? businessDetails.certificate_url : originalCertificate,
            }
        }).then((response) => {
            setSavingProfile(false)
            dispatch(showToastMessage({
                message: "Details Updated",
                type: "success",
                position: toast.POSITION.TOP_RIGHT
            }));
            dispatch(getUserProfile())

        }).catch((error) => {
            const errorResponse = error as AxiosError;
            // console.log(errorResponse.response)
            if (errorResponse.response) {
                const data = errorResponse.response.data as { message: string, status: string };
                dispatch(showToastMessage({
                    message: data.message,
                    type: "error",
                    position: toast.POSITION.TOP_RIGHT
                }));

            } else {
                dispatch(showToastMessage({
                    message: "Something went wrong",
                    type: "error",
                    position: toast.POSITION.TOP_RIGHT
                }));
            }
            setSavingProfile(false)
        });
    }

    const getCertifactName = (companyCertificate: any) => {
        let string_array = companyCertificate.split("/")
        return string_array[string_array.length - 1];
    }


    return (
        <>
            <div>
                <div className=" w-3/4 grid grid-cols-2 mt-5  gap-3">
                    <div className="">
                        <label className="block text-sm font-medium text-gray-700 ">Business name</label>
                        <input onChange={(e) => {
                            if (businessDetails) {
                                setBusinessDetails({
                                    ...businessDetails, business_name: e.target.value
                                });
                            }
                        }} value={businessDetails?.business_name} type="text" id="" className="form-input-2" placeholder="" />
                    </div>
                    <div className="">
                        <label htmlFor="lastname" className="block text-sm font-medium text-gray-700 ">Industry</label>
                        <select
                            onChange={(e) => {
                                if (businessDetails) {
                                    setBusinessDetails({
                                        ...businessDetails, business_industry: e.target.value
                                    });
                                }
                            }} value={businessDetails?.business_industry}
                            name="" className="form-input-2" id="">
                            <option value="">Select</option>
                            {BusinessOptions.map((option, index) => (
                                <option key={index} value={option}>
                                    {option}
                                </option>
                            ))}
                        </select>
                    </div>
                </div>

                <div className=" w-3/4 grid grid-cols-2 mt-5  gap-3">
                    <div className="">
                        <label className="block text-sm font-medium text-gray-700 ">Business email</label>
                        <input onChange={(e) => {
                            if (businessDetails) {
                                setBusinessDetails({
                                    ...businessDetails, business_email: e.target.value
                                });
                            }
                        }} value={businessDetails?.business_email} type="text" id="" className="form-input-2" placeholder="" />
                    </div>
                    <div className="">
                        <label className="block text-sm font-medium text-gray-700 ">Business phone</label>
                        <input onChange={(e) => {
                            if (businessDetails) {
                                setBusinessDetails({
                                    ...businessDetails, business_phone: e.target.value
                                });
                            }
                        }} value={businessDetails?.business_phone} type="text" id="" className="form-input-2" placeholder="" />
                    </div>
                </div>
                <div className=" w-3/4 mt-5">
                    <label className="block text-sm font-medium text-gray-700 ">Registration date</label>
                    <input
                        onChange={(e) => {
                            if (businessDetails) {
                                setBusinessDetails({
                                    ...businessDetails, reg_date: e.target.value
                                });
                            }
                        }}
                        value={businessDetails?.reg_date} className="form-input-2" type="date" />
                </div>
                <div className=" w-3/4 grid grid-cols-2 mt-5  gap-3">
                    <div className="">
                        <label className="block text-sm font-medium text-gray-700 ">Country</label>
                        <select
                            onChange={(e) => {
                                if (businessDetails) {
                                    setBusinessDetails({
                                        ...businessDetails, country: e.target.value
                                    });
                                }
                            }}
                            name="" value={businessDetails?.country} className="form-input-2" id="">
                            <option value="">Select</option>
                            <option value="Uganda">Uganda</option>
                        </select>
                    </div>
                    <div className="">
                        <label className="block text-sm font-medium text-gray-700 ">City</label>
                        <input onChange={(e) => {
                            if (businessDetails) {
                                setBusinessDetails({
                                    ...businessDetails, city: e.target.value
                                });
                            }
                        }} value={businessDetails?.city} type="text" id="" className="form-input-2" placeholder="" />
                    </div>
                </div>
                <div className=" w-3/4 grid grid-cols-2 mt-5  gap-3">
                    <div className="">
                        <label className="block text-sm font-medium text-gray-700 ">Address 1</label>
                        <input onChange={(e) => {
                            if (businessDetails) {
                                setBusinessDetails({
                                    ...businessDetails, address_1: e.target.value
                                });
                            }
                        }} value={businessDetails?.address_1} type="text" id="" className="form-input-2" placeholder="" />
                    </div>
                    <div className="">
                        <label className="block text-sm font-medium text-gray-700 ">Address 2</label>
                        <input onChange={(e) => {
                            if (businessDetails) {
                                setBusinessDetails({
                                    ...businessDetails, address_2: e.target.value
                                });
                            }
                        }} value={businessDetails?.address_2 ? businessDetails?.address_2 : ""} type="text" id="" className="form-input-2" placeholder="" />
                    </div>
                </div>
                <div className="w-3/4 mt-5">
                    <label className="block text-sm font-medium text-gray-700 ">Certificate of Incorporation</label>
                    {
                        notEmpty(businessDetails.certificate_url) ?
                            <p className="mt-2">
                                <a target="_blank" className=" text-green-600" href={storageUrl + "/" + businessDetails.certificate_url}> {getCertifactName(businessDetails.certificate_url)} </a> <small
                                    onClick={() => {
                                        setBusinessDetails({
                                            ...businessDetails, certificate_url: ""
                                        })
                                    }
                                    }

                                    className="text-red-600  cursor-pointer">(change)</small>

                            </p> :
                            <DropFile ref={dropFileRef} getFileUrl={(fileUrl) => {
                                if (businessDetails) {
                                    setBusinessDetails({
                                        ...businessDetails, certificate_url: fileUrl
                                    })
                                }
                            }} StorageFolder="certificates" SubText="Soft Copy of your company Certificate" />
                    }


                </div>



                <div className="mt-5">
                    <AppButton callBackFun={() => saveBusinessProfile()} showLoader={savingProfile} spinnerClass="inline w-3 h-3 mr-2 text-prim-yellow animate-spin fill-black" className="prim-btn-1  " text="Save Changes" />
                </div>
            </div>
        </>
    )
}

