import { forwardRef, useEffect, useImperativeHandle, useRef } from "react";
import ProgressBar from "progressbar.js";
import moment from "moment";
import Shape from "progressbar.js/shape";

interface Props {
  onProgressFinished: () => void;
}

export interface CircleCounterFunctions {
  startProgress: () => void;
}

const CircleCounter = forwardRef<CircleCounterFunctions,Props>(({ onProgressFinished }:Props,ref) => {
  const circleRef = useRef<HTMLDivElement>(null);
  const barRef = useRef<Shape| null>(null);
  useEffect(() => {
    console.log("6a")
    startProgress();
  }, []);

  useImperativeHandle(ref, () => ({
    startProgress
  }));

  const startProgress = () => {
    const otpPeriod = 50000;
    const lastOtpTime = moment(localStorage.getItem("lastOtpTime"));
    // const lastOtpTime = moment("Thur Mar 09 2023 07:51:35 GMT+0300");
    const difference = moment().diff(lastOtpTime);
    let duration = otpPeriod - difference;
    let progress = 1;
    if (duration < 0) {
      duration = 0;
      progress = 0;
    } else {
      progress = duration / otpPeriod;
    }
    if (!barRef.current) {
      barRef.current = new ProgressBar.Circle(circleRef.current, {
        color: "#FFFFFF",
        strokeWidth: 6,
        trailWidth: 1,
        easing: "linear",
        text: {
          autoStyleContainer: false,
          style:{fontSize:"1.4rem",opacity:0.8},
        },
        from: { color: "#FFFFFF"},
        to: { color: "#FFFFFF"},
        // Set default step function for all animate calls
        step:  (state, circle)=> {
          circle.path?.setAttribute("stroke", state.color);
          circle.path?.setAttribute("stroke-width", "6");
          var value = Math.round(circle.value() * 100);
          barRef.current?.setText(""+value)
        },
      });
    }
    barRef.current.set(progress);
    barRef.current.animate( 0,{duration}, () =>onProgressFinished() );
  };

  return (
    <div>
      <div className=" relative w-full h-full" ref={circleRef}></div>
    </div>
  );
});

export default CircleCounter;
