import NoData from "../../assets/img/nodata_green.svg"
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { useEffect, useState } from "react";
import { formatDate } from "../../helpers/commons";
import { TypeUser, getUserProfile, selectAccessToken, selectNtumaAccount } from "../../features/auth/authSlice";
import AppButton from "../elements/AppButton";
import useApiRequest from "../../helpers/api_request";
import { BASE_URL } from "../../helpers/constants";
import { showToastMessage } from "../../features/generalSlice";
import { toast } from "react-toastify";
import { AxiosError } from "axios";

const ProfilePage = ()=>{
    const dispatch = useAppDispatch()
    const ntumaAccount = useAppSelector(selectNtumaAccount);
    const [savingProfile, setSavingProfile] = useState(false);
    const userProfile = useAppSelector(state=>state.auth.user)
    const [user, setUser] = useState<TypeUser | undefined>(userProfile);
    const apiRequest = useApiRequest();
    const accessToken = useAppSelector(selectAccessToken);

    const saveProfile =async ()=>{
        await apiRequest({
            method: 'post',
            url: BASE_URL+"/api/user/update-profile",
            headers: {
                Authorization: `Bearer ${accessToken}`,
            },
            data:{
                "firstname":user?.firstname,
                "lastname": user?.lastname,
                "phone": user?.phone,
            }
        }).then((response)=>{
            setSavingProfile(false)
            dispatch(showToastMessage({
                message:"Profile Updated",
                type:"success",
                position: toast.POSITION.TOP_RIGHT
            }));
            dispatch(getUserProfile())
       
        }).catch((error)=>{
            const errorResponse = error as AxiosError;
            // console.log(errorResponse.response)
            if (errorResponse.response) {
                const data = errorResponse.response.data as {message:string, status:string};
                dispatch(showToastMessage({
                    message:data.message,
                    type:"error",
                    position: toast.POSITION.TOP_RIGHT
                }));
              
            }else{
                dispatch(showToastMessage({
                    message:"Something went wrong",
                    type:"error",
                    position: toast.POSITION.TOP_RIGHT
                }));
            }
            setSavingProfile(false)
        }); 
    }
    return (
        <div>
              <div className="card mb-12">
                
                <div className="card-body flex flex-col px-4 py-4 min-h-half-screen pb-16">
                    <div className="flex">
                        <nav className="flex justify-between " aria-label="Breadcrumb">
                        <ol className="inline-flex items-center mb-3 sm:mb-0">
                            <li>
                            <div className="flex items-center">
                                <h5 className=" card-title ">Personal Information</h5>
                            </div>
                            </li>
                        
                        </ol>
                        </nav>
                  
                    </div>
                    <div className=" w-3/4 grid grid-cols-2 mt-4  gap-3">
                        <div className="">
                            <label htmlFor="firstname" className="block text-sm font-medium text-gray-700 ">First name</label>
                            <input onChange={(e)=>{
                                if (user) {
                                    setUser({
                                        ...user,firstname:e.target.value
                                    });
                                }
                            }} value={user?.firstname} type="text" id="firstname" className="form-input-2" placeholder=""/>
                        </div>
                        <div className="">
                            <label  htmlFor="lastname" className="block text-sm font-medium text-gray-700 ">Last name</label>
                            <input
                             onChange={(e)=>{
                                if (user) {
                                    setUser({
                                        ...user,lastname:e.target.value
                                    });
                                }
                            }} 
                            value={user?.lastname} type="text" id="lastname" className="form-input-2" placeholder=""/>
                        </div>
                    </div>
                    <div className=" w-3/4 grid grid-cols-2 mt-4  gap-3">
                        <div className="">
                            <label htmlFor="email" className="block text-sm font-medium text-gray-700 ">Email</label>
                            <input value={user?.email} type="email" disabled id="name" className="form-input-2" placeholder=""/>
                        </div>
                        <div className="">
                            <label htmlFor="phone" className="block text-sm font-medium text-gray-700 ">Phone number</label>
                            <input
                               onChange={(e)=>{
                                if (user) {
                                    setUser({
                                        ...user,phone:e.target.value
                                    });
                                }
                            }} 
                            value={user?.phone}  type="text" id="phone" className="form-input-2" placeholder="+256"/>
                        </div>
                    </div>
                    <div className="mt-5">
                        <AppButton callBackFun={()=>saveProfile()} showLoader={savingProfile}  spinnerClass="inline w-3 h-3 mr-2 text-prim-yellow animate-spin fill-black" className="prim-btn-1  " text="Save Changes" />
                    </div>
                 
                </div>
            </div>
              
        </div>
    )
}
export default ProfilePage;

